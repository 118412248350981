import axios from "../../axiosConfig";

export async function searchDarkDocuments(docNumber, type) {
	const url = 'client/searchDarkDocuments';
	const data = new FormData();
	data.append('type', type);
	data.append('number', docNumber);

	try {
		const response = await axios.post(url, data);

		if (response.status === 200) {
			return response.data; // Document trouvé
		}

		if (response.status === 204) {
			return null; // Aucun contenu, document non trouvé
		}

		return null;

	} catch (error) {
		if (error.response) {
			// Gestion des erreurs côté serveur
			if (error.response.status === 400) {
				throw new Error("Requête invalide : veuillez vérifier les champs.");
			} else if (error.response.status === 401) {
				throw new Error("Vous devez être connecté pour effectuer cette recherche.");
			} else if (error.response.status === 403) {
				throw new Error("Accès interdit.");
			} else if (error.response.status === 500) {
				throw new Error("Une erreur technique est survenue.");
			}
		}
		throw new Error("Impossible de contacter le serveur.");
	}
}
