import './style.scss';

import React, {useEffect, useState} from 'react';

type TabProps = {
	label: string;
	notification?: boolean;
	content?: React.ReactNode;
};

const Tab: React.FC<TabProps> = ({ label, notification }) => (
	<>
		{notification && <span className="notificationDot" />}
		{label}
	</>
);

type TabsProps = {
	defaultActiveTab?: string;
	tabs: TabProps[];
	onChangeTab?: (label: string) => void;
};

const Tabs: React.FC<TabsProps> = ({
	defaultActiveTab,
	tabs,
	onChangeTab = (label)=> {}

}) => {
	const [activeTab, setActiveTab] = useState(defaultActiveTab || tabs[0].label);

	const handleTabClick = (label: string) => {
		setActiveTab(label);
		onChangeTab(label);
	};



	useEffect(() => {
		//
	}, []);

	return (
		<div className="tabs">
			<div className="tab__list">
				{tabs.map((tab) => (
					<div
						key={tab.label}
						className={`tab__list__item ${tab.label === activeTab ? 'active' : ''}`}
						onClick={() => handleTabClick(tab.label)}
					>
						<Tab label={tab.label} notification={tab.notification} />
					</div>
				))}
			</div>
			<div className="tab__content">
				{tabs.map((tab) => (
					<div key={tab.label} className={`tab__pane ${tab.label === activeTab ? 'active' : ''}`}>
						{tab.content}
					</div>
				))}
			</div>

		</div>
	);
};

export default Tabs;
