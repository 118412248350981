import { Button_Style, Button_Type } from "../components/Button/Button";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Alert from "../components/Alert";
import Button from "../components/Button";
import Input from "../components/Input";
import Loader from "../components/Loader";
import { resend } from "../api/user";
import svgImage from "../assets/img/Illustration_documents.svg";
import { userConfirmation } from "../api/auth";

const CommonsConfirmation = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const token = searchParams.get("v");
    const [email, setEmail] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    useEffect(() => {
        confirmUser();
    }, [token]);
    const confirmUser = async () => {
        if (!token) {
            setErrorMessage("Veuillez renseigner votre adresse email.");
        }
        else {
            setIsLoading(true);
            try {
                const response = await userConfirmation(token);
                setIsLoading(false);
            } catch (error: any) {
                setErrorMessage(error.response.data.error);
                setIsLoading(false);
            }
        }
    };

    const resendMail = async () => {
        setIsLoading(true);
        try {
            const response = await resend(email, "v");
            setIsLoading(false);
            setSuccessMessage("Un nouveau lien de confirmation vous a été envoyé par email.");
        } catch (error: any) {
            setErrorMessage(error.response.data.error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <main className="login">
                <div className="login__container">
                    <div className="login__form">
                        <h5>Espace Particulier</h5>
                        <h2>Confirmation de mail</h2>
                        {errorMessage === "" ? (
                            <Alert type="success">Votre mail est valide, vous pouvez vous connecter à votre compte ID Protect</Alert>
                        ) : (
                            <Alert type="error">{errorMessage}</Alert>
                        )}
                        {!errorMessage ? (
                            <Button
                                label="connexion"
                                type={Button_Type.PRIMARY}
                                onClick={() => navigate("/particuliers/login")}
                                className="m-auto"
                            />
                        ) : (
                            <>
                                {successMessage && <Alert type="success">{successMessage}</Alert>}
                                <Input
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {isLoading && <Loader />}
                                <Button
                                    label="Renvoyer le mail"
                                    type={Button_Type.PRIMARY}
                                    style={Button_Style.OUTLINED}
                                    onClick={() => resendMail()}
                                    className="m-auto"
                                />
                            </>
                        )}
                    </div>
                    <div className="login__img">
                        <img
                            src={svgImage}
                            className="illustration-document"
                            alt="Login"
                        />
                    </div>
                </div>
            </main>
        </>
    );
};

export default CommonsConfirmation;
