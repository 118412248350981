import "./style.scss";

import { FC, useContext, useEffect, useState } from "react";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { createPaymentIntent, getStripeConfig } from "../../api/payment";

import { Elements } from "@stripe/react-stripe-js";
import PaymentStripForm from "../PaymentStripForm";
import { UserContext } from "../../contexts/UserContext";

interface StripePaymentCardProps {
    title: any;
    steps?: any;
    link: any;
    setDatas: (data: any) => void;
    datas: any;
    isMonth?: boolean;
    handlePreviousStep: () => void;
    handlePaymentResult: (title: string, message: string, paymentSucceeded: boolean) => void;
}

const StripePaymentCard: FC<StripePaymentCardProps> = ({
    title,
    setDatas,
    datas,
    steps,
    link,
    isMonth,
    handlePreviousStep,
    handlePaymentResult,
}) => {
    const [stripPromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
    const [clientSecret, setClientSecret] = useState(null);
    const { userType } = useContext(UserContext);
    const body = new FormData();
    body.append("type", userType || "individual");
    useEffect(() => {
        getStripeConfig(body).then((value) => {
            const publishableKey = value.data;
            setStripePromise(loadStripe(publishableKey, { locale: "fr" }));
        });
    }, []);

    useEffect(() => {
        const body = new FormData();
        body.append("type", userType || "individual");

        body.append("amount", isMonth != false ? datas.chosenPlan?.price : datas.chosenPlan?.priceYear);
        body.append("currency", "eur");
        if (isMonth == true || isMonth == false) {
            body.append("description", `${title}. Paiement  ${isMonth ? "mensuel" : "annuel"}`);
        } else {
            // is null
            body.append("description", `${title}`);
        }
        body.append("statement_descriptor", isMonth !== false ? datas.chosenPlan?.description : "/an");
        body.append("payment_method", "card");

        if (datas?.chosenPlan?.isRecurringPayment) {
        } else {
        }

        createPaymentIntent(body).then((value) => {
            if (value !== null) {
                const { clientSecret } = value.data;
                setClientSecret(clientSecret);
            }
        });
    }, []);

    return (
        <div className="StripePaymentCard h-100 d-flex flex-column align-items-center justify-content-center">
            {stripPromise && clientSecret && (
                <Elements
                    stripe={stripPromise}
                    options={{ clientSecret }}>
                    <PaymentStripForm
                        link={link}
                        title={title}
                        setDatas={setDatas}
                        datas={datas}
                        steps={steps}
                        handlePaymentResult={handlePaymentResult}
                        handlePreviousStep={handlePreviousStep}
                        isMonth={isMonth}
                    />
                </Elements>
            )}

            {!stripPromise || (!clientSecret && <div>Un instant...</div>)}
        </div>
    );
};

export default StripePaymentCard;
