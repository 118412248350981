import "./style.scss";

import Button from "../Button";
import { Button_Style } from "../Button/Button";
import Checkbox from "../Checkbox";
import { FC } from "react";
import TextArea from "../TextArea";

interface IDAssistantCommentProps {
    steps: any;
    setDatas: (data: any) => void;
    datas: any;
    handlePreviousStep: () => void;
    handleNextStep: () => void;
}
const IDAssistantComment: FC<IDAssistantCommentProps> = ({ steps, setDatas, handleNextStep, handlePreviousStep, datas }) => {
    return (
        <div className="IDAssistantComment  w-100 h-100 d-flex flex-column align-items-center justify-content-between">
            <div>
                <h3>Commentaire</h3>
                <Checkbox label="Je confirme être le titulaire des documents d’identité présentés dans le cadre de l’accompagnement ID Protect" />
                <TextArea
                    placeholder="commentaire"
                    onChange={(e) => setDatas({ ...datas, comment: e.target.value })}
                />
            </div>
            <div className={`d-flex justify-content-${!steps[0].isCurrent ? "between" : "end"} w-100`}>
                {!steps[0].isCurrent && (
                    <Button
                        label={"Retour"}
                        onClick={() => handlePreviousStep()}
                        style={Button_Style.OUTLINED}
                    />
                )}
                <Button
                    label={"Suivant"}
                    onClick={() => handleNextStep()}
                />
            </div>
        </div>
    );
};

export default IDAssistantComment;
