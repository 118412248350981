import {DocumentBasylic, NOTIFICATION_ACTIONS} from "../types/types";

export const extractNumber = (str: string): number | null => {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
};

export const formattedStringToDate = (str: string): string => {

   return new Date(str).toLocaleDateString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    });
};

export const formatShareIDVideoResultToDocument = (document: any) => {
    const { doc_num, surname, name, sex, expiration_date, issuance_date, mrz_1, mrz_2, birth_date, birth_place } =
        document.document.ocr;
    const documentFormatted: DocumentBasylic = {
        id: document.id,
        // TODO: vérifier la validité de ces valeurs
        conformite: true,
        darkWeb: false,
        country_code: document.document.type.country_code,
        dateDelivrance: issuance_date,
        dateExpiration: expiration_date,
        dateNaissance: birth_date,
        nom: name,
        nomUsage: surname,
        numeroDocument: doc_num,
        paysNaissance: birth_place,
        prenom: surname,
        sexe: sex,
        typeDocument: document.document.type.doc_type,
        mrz: mrz_1,
        viz: mrz_2,
    };
    return documentFormatted;
};

export const formatShareIDPhotoResultToDocument = (externalApiResponse: any) => {
    const { doc_num, surname, name, sex, expiration_date, issuance_date, mrz_1, mrz_2, birth_date, birth_place } =
        externalApiResponse.ocr;
    const documentFormatted: DocumentBasylic = {
        id: externalApiResponse.id,
        // TODO: vérifier la validité de ces valeurs
        conformite: externalApiResponse.verdict,
        darkWeb: false,
        country_code: externalApiResponse.country_code,
        dateDelivrance: issuance_date ?  formattedStringToDate(issuance_date ?? "") : "",
        dateExpiration: expiration_date ? formattedStringToDate(expiration_date ?? "") : "",
        dateNaissance: birth_date ? formattedStringToDate(birth_date ?? "") : "",
        nom: name,
        nomUsage: surname,
        numeroDocument: doc_num,
        paysNaissance: birth_place,
        prenom: surname,
        sexe: sex,
        typeDocument: externalApiResponse.doc_type,
        mrz: mrz_1,
        viz: mrz_2,
    };
    return documentFormatted;
};



// Mapping of tabs to notification actions
export const tabNotificationActions: Record<string, NOTIFICATION_ACTIONS[]> = {
    "identity-documents": [
        NOTIFICATION_ACTIONS.Document_View_Request,
        NOTIFICATION_ACTIONS.Document_View_Request_New_Status,
        NOTIFICATION_ACTIONS.Document_Lost_Declaration,
        NOTIFICATION_ACTIONS.Document_Delete,
        NOTIFICATION_ACTIONS.Document_Delete_Followed,
        NOTIFICATION_ACTIONS.Document_New_Followed,
        NOTIFICATION_ACTIONS.Document_Change_Followed_State,
        NOTIFICATION_ACTIONS.Folder_Notification,
    ],
    "id-locker": [
        NOTIFICATION_ACTIONS.Folder_Creation,
        NOTIFICATION_ACTIONS.Folder_Delete,
        NOTIFICATION_ACTIONS.File_Added,
        NOTIFICATION_ACTIONS.File_Delete,
        NOTIFICATION_ACTIONS.Invitation_Send,
        NOTIFICATION_ACTIONS.Permission_Deleted,
        NOTIFICATION_ACTIONS.Permission_Changed,
        NOTIFICATION_ACTIONS.Visibility_Changed,
        NOTIFICATION_ACTIONS.Expired_Date_Changed,
    ],
    "id-assistant": [
        NOTIFICATION_ACTIONS.Accompaniment_New_Status,
        NOTIFICATION_ACTIONS.Accompaniment_New,
        NOTIFICATION_ACTIONS.Accompaniment_Delete,
    ],
};