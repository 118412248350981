import React, {FC, useContext, useState} from "react";

import { UserContext } from "../contexts/UserContext";
import Button from "../components/Button";
import {Button_Style, Button_Type} from "../components/Button/Button";
import { ReactComponent as IconBack } from "../assets/img/icons/icon_arrow-Right.svg";
import VideoFrame from "../components/VideoFrame";
import Checkbox from "../components/Checkbox";


type FilterOverViewProps = {
    showOverview: boolean;
    setShowOverview: any;
};


const FilterOverview: FC<FilterOverViewProps> = ({
      showOverview = true,
      setShowOverview,
}) => {
    const { userType } = useContext(UserContext);

    const [selectedNeverShowAgain, setSelectedNeverShowAgain] = useState(false);

    const handleStartFilter = () => {
        setShowOverview(false);
        localStorage.setItem("FilterOverview", JSON.stringify(!selectedNeverShowAgain));
    };

    const checkSelect = () => {
        const newSelect = !selectedNeverShowAgain;
        setSelectedNeverShowAgain(newSelect);
    };

    return (
        <>
            <div className="FilterOverview  h-100">
                <div className="content__body  h-100">
                    <div className="row flex-wrap">
                        <div className={`col-sm-5`}>
                            <div className="content--card-2 relative mb-2">
                                <div className="mb-2 mb-5 text-secondary">
                                    <div>
                                        Protéger ses documents contre l’usurpation d’identité est essentiel.
                                        Avec <b>Filigrane</b>, sécurisez vos fichiers grâce à un marquage unique et
                                        personnalisé
                                    </div>

                                    <ul className="mt-1">
                                        <li><b>Filigrane</b> : Apposez un marquage sur vos documents pour
                                            empêcher toute copie ou utilisation frauduleuse.
                                        </li>
                                    </ul>
                                    <div className="mt-1">
                                        <b>Cas d’usage</b>
                                        <ul>
                                            <li>Sécurisation des pièces d’identité avant tout envoi en ligne</li>
                                            <li>Protection des justificatifs et documents administratifs</li>
                                            <li>Indication de confidentialité sur des documents sensibles</li>
                                        </ul>
                                    </div>
                                    Avec <b>Filigrane</b>, gardez le contrôle et protégez votre identité en toute
                                    simplicité.

                                    {/*<img src={FiligraneImage} alt="filigrane" width="50%"/>*/}
                                </div>
                                <div className="h-50 d-flex flex-column justify-content-start align-items-center">
                                    <Checkbox
                                        label="Ne plus afficher cette page"
                                        onChange={checkSelect}
                                        checked={selectedNeverShowAgain}
                                    />
                                    <Button
                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                        label="APPOSER LE FILIGRANE"
                                        style={Button_Style.SOLID}
                                        onClick={() => handleStartFilter()}
                                        icon={<IconBack
                                            className="PageHeader__icon"
                                            fill={"#9FC1EF"}
                                        />}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="mt-3">
                                <VideoFrame
                                    url="https://www.youtube.com/watch?v=bYk4QPlCi5g&t=14s"
                                    //prevImg={ImagePrev}
                                >
                                    <span className="text-primary bold">Filigrane : </span>
                                    <span className="text-danger">Découvrez le fonctionnement de notre outil</span>
                                </VideoFrame>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default FilterOverview;