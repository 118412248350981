import React, {FC, useContext, useState} from "react";

import { UserContext } from "../contexts/UserContext";
import Button from "../components/Button";
import {Button_Style, Button_Type} from "../components/Button/Button";
import { ReactComponent as IconBack } from "../assets/img/icons/icon_arrow-Right.svg";
import VideoFrame from "../components/VideoFrame";
import Checkbox from "../components/Checkbox";

type DashboardOverViewProps = {
    showOverview: boolean;
    setShowOverview: any;
};


const DashboardOverview: FC<DashboardOverViewProps> = ({
      showOverview = true,
      setShowOverview,
}) => {
    const { userType } = useContext(UserContext);

    const [selectedNeverShowAgain, setSelectedNeverShowAgain] = useState(false);

    const handleStartDashBoard = () => {
        setShowOverview(false);
        localStorage.setItem("DashboardOverview", JSON.stringify(!selectedNeverShowAgain));
    };

    const checkSelect = () => {
        const newSelect = !selectedNeverShowAgain;
        setSelectedNeverShowAgain(newSelect);
    };

    return (
        <>
            <div className="DashboardOverview">
                <div className="content__body">
                    <div className="row flex-wrap">
                        <div className={`col-sm-5`}>
                            <div className="content--card-2 relative mb-2">
                                <div className="mb-2 text-secondary">
                                    Pour combattre le vol d’identité, il est primordiale de se protéger. Sur la
                                    plateforme ID Protect, vous retrouverez tous les outils nécessaires à votre
                                    protection :
                                    <ul className="mt-1">
                                        <li><b>ID Tracker</b> : Soyez immédiatement alerté en cas d’utilisation
                                            suspecte de vos documents et reprenez le contrôle de votre
                                            identité.
                                        </li>
                                        <li><b>ID Filigrane</b> : Ajoutez un filigrane personnalisé à vos documents
                                            pour empêcher toute utilisation frauduleuse.
                                        </li>
                                        <li><b>ID Locker</b> : Stockez et partagez vos documents en toute sécurité
                                            grâce à notre coffre-fort numérique chiffré.</li>
                                    </ul>

                                </div>
                                <div className="d-flex flex-column justify-content-between align-items-center">
                                    <Checkbox
                                        label="Ne plus afficher cette page"
                                        onChange={checkSelect}
                                        checked={selectedNeverShowAgain}
                                    />
                                    <Button
                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                        label="tableau de bord"
                                        style={Button_Style.SOLID}
                                        onClick={() => handleStartDashBoard()}
                                        icon={<IconBack
                                            className="PageHeader__icon"
                                            fill={"#9FC1EF"}
                                        />}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="mt-3">
                                <VideoFrame
                                    url="https://www.youtube.com/watch?v=XvoHvSI-Huo&t=7s"
                                >
                                    <span className="text-primary bold">La plateforme ID Protect</span>
                                </VideoFrame>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default DashboardOverview;