import React, { FC, useContext, useEffect, useState } from "react";
import {useLocation} from "react-router-dom";
import { DocumentBasylic, ErrorType } from "../types/types";
import { UserContext } from "../contexts/UserContext";
import { ReactComponent as IDIcon } from "../assets/img/icons/icon_ID Tracker_2.svg";
import { ReactComponent as IconPlay } from "../assets/img/icons/icon_Play.svg";
import { ReactComponent as IconUpload } from "../assets/img/icons/icon_Upload.svg";
import AddDocumentForm from "../components/AddDocumentForm";
import Alert from "../components/Alert";
import BuyCreditsCard from "../components/BuyCreditsCard";
import DocumentIllustration from "../components/DocumentIllustration";
import ErrorScan from "../components/ErrorScan";
import Loader from "../components/Loader";
import VerifyResult from "../components/VerifyResult/VerifyResult";
import PageHeader from "../components/PageHeader";
import VerifyOverview from "./VerifyOverview";
import VerifyOverviewDarkWeb from "./VerifyOverviewDarkWeb";
import Modal from "../components/Modal";
import Button from "../components/Button";
import { Button_Style, Button_Type } from "../components/Button/Button";
import { ReactComponent as IconFaq } from "../assets/img/icons/icon_Faq.svg";

const iconProps = {
    fill: "#2f62ae",
    width: "20px",
};

interface VerifyProps {
    isDarkWeb?: boolean;
}

const Verify: FC<VerifyProps> = ({ isDarkWeb }) => {
    const location = useLocation();


    const [isByVideo, setIsByVideo] = useState<boolean>(true);
    {/* TODO: A commenter pour faire marcher Presentation Video*/}
    // const [showModal, setShowModal] = useState<boolean>(true);
    {/* TODO: A decomenter pour faire marcher Presentation Video*/}
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedNeverShowAgain, setSelectedNeverShowAgain] = useState(()=> {
        /*TODO: A decomenter pour faire marcher Presentation Video*/
        return localStorage.getItem("VerifyOverview") == "true";
        // return true;
    });
    const [showOverview, setShowOverview] = useState<boolean>(() => {
        // TODO : A décomenter pour faire marcher Video Prsentation
        return localStorage.getItem("VerifyOverview") !== "false";
        // return true
    });
    const [showOverviewDarkWeb, setShowOverviewDarkWeb] = useState<boolean>(() => {
        // TODO : A décomenter pour faire marcher Video Prsentation
        return localStorage.getItem("VerifyOverviewDarkWeb") !== "false";
        // return false
    });
    const { user, userType, getMe } = useContext(UserContext);
    const [credits, setCredits] = useState(user?.credits ?? 1);

    useEffect(() => {
        getMe();
    }, [location.pathname, user?.email]);

    const [type, setType] = useState<string>("");
    const [isScanning, setIsScanning] = useState<boolean>(false);
    const [isScanned, setIsScanned] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorType, setErrorType] = useState<ErrorType>({ status: 0, message: "" });
    const [verifyReportURL, setVerifyReportURL] = useState<string>("");
    const [document, setDocument] = useState<DocumentBasylic>();
    const [showResult, setShowResult] = useState<boolean>(false);
    const [applicant_id, setApplicant_id] = useState<string>("");


    useEffect(() => {
        if (!isDarkWeb && localStorage.getItem("VerifyOverview") === "false") {
            setShowOverview(true);
        }
    }, [isDarkWeb]);

    const refreshPage = () => {
        /* setIsScanned(false);
        setShowResult(false)    ;
        setErrorType({ status: 0, message: "" });
        */
        window.location.href = window.location.href; // Cela permet de rafraichir la page
    };

    const handleStartVerification = (video: boolean) => {
        setSelectedNeverShowAgain(false);
        localStorage.setItem("VerifyOverview", JSON.stringify(selectedNeverShowAgain));
        setShowModal(false);
        setShowOverview(false);
        setIsByVideo(video);
    };

    return (
        <div className="h-100">
            <PageHeader>
                <h5>{isDarkWeb ? "Dark Web" : "ID Verify"}</h5>
                <IDIcon className="PageHeader__icon" fill={"#ffeaaa"} />
            </PageHeader>

            {((showOverview && !isDarkWeb) || (showOverviewDarkWeb && isDarkWeb)) && credits > 0 ? (
                isDarkWeb ? (
                    <VerifyOverviewDarkWeb showOverview={showOverviewDarkWeb} setShowOverview={setShowOverviewDarkWeb} setShowModal={setShowModal} />
                ) : (
                    <>
                        <VerifyOverview
                            showOverview={showOverview}
                            setShowOverview={setShowOverview}
                            showModal={showModal}
                            setShowModal={setShowModal}
                            selectedNeverShowAgain={selectedNeverShowAgain}
                            setSelectedNeverShowAgain={setSelectedNeverShowAgain}
                            handleStartVerification={handleStartVerification}
                        />
                    </>
                )
            ) : (
                <div className="VerifyPage content__body h-100">
                    <div className="VerifyPage__row row h-100">
                        <div className="VerifyPage__content col-sm-6">
                            {credits <= 0 ? (
                                <>
                                    <Alert type="error">Vous n'avez pas de crédits pour vérifier un document. Veuillez acheter des crédits.</Alert>
                                    <BuyCreditsCard />
                                </>
                            ) : !isScanning && !isScanned && errorType?.status !== 400 && !showResult ? (
                                <>
                                    <AddDocumentForm
                                        onChangeType={setType}
                                        type={type}
                                        setIsScanning={setIsScanning}
                                        setIsScanned={setIsScanned}
                                        setErrorMessage={setErrorMessage}
                                        setDocument={setDocument}
                                        isVerification
                                        errorType={errorType}
                                        setErrorType={setErrorType}
                                        setVerifyReportURL={setVerifyReportURL}
                                        isDarkWeb={isDarkWeb}
                                        isByVideo={isByVideo}
                                        setApplicant_id={setApplicant_id}
                                        applicant_id={applicant_id}
                                    />
                                </>
                            ) : errorType?.status !== 400 ? (
                                <VerifyResult
                                    isScanning={isScanning}
                                    documentVerified={document}
                                    refresh={refreshPage}
                                    verifyReportURL={verifyReportURL}
                                    errorMessage={errorMessage}
                                    isDarkWeb={isDarkWeb}
                                    setApplicant_id={setApplicant_id}
                                    applicant_id={applicant_id}
                                />
                            ) : (
                                <ErrorScan error={errorType?.message} verifyReportURL={verifyReportURL} resetState={refreshPage} />
                            )}
                        </div>
                        <div className="VerifyPage__illu col-sm-6 center">
                            <h1 className="VerifyPage__title text-center mb-4">
                                {isDarkWeb ? "Votre document d'identité est-il sur le dark web ?" : "Vérifiez l'authenticité d'un document"}
                            </h1>
                            <DocumentIllustration type={type} />
                            {isScanning && <Loader text="Analyse en cours" />}
                        </div>
                    </div>
                    {/* TODO: A decomenter pour faire marcher Presentation Video*/}
                    <div className="mt-2 mb-1">
                        <div
                            className="d-flex justify-content-center align-items-center gap-05">
                            <u className="pointer"
                               onClick={() => {
                                   if (isDarkWeb) {
                                       setShowOverviewDarkWeb(true);
                                   } else {
                                       setShowOverview(true)
                                   }
                               }}>Découvrez-vous le fonctionnement {isDarkWeb ? "notre Dark Web" : "d'ID Verify"}
                            </u>
                            <IconFaq {...iconProps} />
                        </div>
                    </div>
                </div>
            )}
            {/* TODO: A Commenter pour faire marcher Presentation Video*/}
            {((showModal && !isDarkWeb)) && credits > 0 && (
                <Modal onClose={() => setShowModal(false)} isOpen={showModal} title="Vérifier un document" infoModal type="info">
                    <div className="w-100">
                        <p>J’effectue la vérification par :</p>
                        <div className="d-flex gap-05 flex-wrap">
                            <Button label="Vidéo" style={Button_Style.OUTLINED} onClick={() => handleStartVerification(true)} icon={<IconPlay />} type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY} />
                            <Button label="Photo" style={Button_Style.OUTLINED} onClick={() => handleStartVerification(false)} icon={<IconUpload />} type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY} />
                        </div>
                    </div>
                </Modal>
            )}
            {/* TODO: A decomenter pour faire marcher Presentation Video*/}
            {/*{((showModal && !isDarkWeb) || (selectedNeverShowAgain && !isDarkWeb)) && credits > 0 && (
                <Modal onClose={() => selectedNeverShowAgain && setShowModal(false)} isOpen={showModal} title="Vérifier un document" infoModal type="info">
                    <div className="w-100">
                        <p>J’effectue la vérification par :</p>
                        <div className="d-flex gap-05 flex-wrap">
                            <Button label="Vidéo" style={Button_Style.OUTLINED} onClick={() => handleStartVerification(true)} icon={<IconPlay />} type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY} />
                            <Button label="Photo" style={Button_Style.OUTLINED} onClick={() => handleStartVerification(false)} icon={<IconUpload />} type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY} />
                        </div>
                    </div>
                </Modal>
            )}*/}
        </div>
    );
};

export default Verify;
