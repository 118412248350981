import './style.scss';

import React, { FC, useState } from 'react';

import { ReactComponent as Empty } from "../../assets/img/icons/icon_empty.svg";

type EmptyContentProps = {
  text: string,
    showIcon?: boolean,
}
const EmptyContent: FC<EmptyContentProps> = ({ text, showIcon = true }) => {
  return (
    <div className="EmptyContent">
        {showIcon && (<Empty />)}
      <p>{text ? text : "Aucun résultat trouvé"}</p>
    </div>
  );
}

export default EmptyContent;
