import "./style.scss";

import Button from "../Button";
import { Button_Style } from "../Button/Button";
import { FC } from "react";
import FileUploader from "../FileUploader";

interface IDAssistantUploadPVProps {
    steps: any;
    setDatas: (data: any) => void;
    datas: any;
    handlePreviousStep: () => void;
    handleNextStep: () => void;
}
const IDAssistantUploadPV: FC<IDAssistantUploadPVProps> = ({ steps, setDatas, datas, handleNextStep, handlePreviousStep }) => {
    return (
        <div className="IDAssistantUploadPV  w-100 h-100 d-flex flex-column align-items-center justify-content-between">
            <div>
                <h3>Votre pv déclaration de perte et / ou dépôt de plainte</h3>
                <p className="mb-2">
                    Ce document est obligatoire pour lancer les démarches mais vous pouvez l'ajouter à votre dossier ultérieurement.
                </p>
                <FileUploader
                    onChange={(file) => setDatas({ ...datas, filesToUpload: file })}
                    onDelete={() => console.log("delete")}
                />
            </div>
            <div className={`d-flex justify-content-${!steps[0].isCurrent ? "between" : "end"} w-100`}>
                {!steps[0].isCurrent && (
                    <Button
                        label={"Retour"}
                        onClick={() => handlePreviousStep()}
                        style={Button_Style.OUTLINED}
                    />
                )}
                <Button
                    label={"Suivant"}
                    onClick={() => handleNextStep()}
                />
            </div>
        </div>
    );
};

export default IDAssistantUploadPV;
