import { DocumentBasylic, ErrorType } from "../types/types";
import React, {FC, useContext, useEffect, useState} from "react";

import AddDocumentForm from "../components/AddDocumentForm";
import AddDocumentInfos from "../components/AddDocumentInfos";
import DocumentIllustration from "../components/DocumentIllustration/DocumentIllustration";
import ErrorScan from "../components/ErrorScan";
import Loader from "../components/Loader/Loader";
import Toast from "../components/Toast/Toast";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import Button from "../components/Button";
import {Button_Style, Button_Type} from "../components/Button/Button";

import { ReactComponent as IDIcon } from "../assets/img/icons/icon_ID Tracker_2.svg";
import { ReactComponent as IconPlay } from "../assets/img/icons/icon_Play.svg";
import { ReactComponent as IconUpload } from "../assets/img/icons/icon_Upload.svg";
import {UserContext} from "../contexts/UserContext";
import PageHeader from "../components/PageHeader";
import Alert from "../components/Alert";
import BuyCreditsCard from "../components/BuyCreditsCard";
import IDTrackerOverView from "./IDTrackerOverview";

const IDTracker: FC = () => {
    const { user, userType, getMe } = useContext(UserContext);
    const [credits, setCredits] = useState(user?.credits ?? 1);

    const [type, setType] = useState<string>("");
    const [isScanning, setIsScanning] = useState<boolean>(false);
    const [isScanned, setIsScanned] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [document, setDocument] = useState<DocumentBasylic>();
    const [errorType, setErrorType] = useState<ErrorType>({ status: 0, message: "" });
    const navigate = useNavigate();
    const [applicant_id, setApplicant_id] = useState<string>("");

    const [isByVideo, setIsByVideo] = useState<boolean>(true);
    {/* TODO: A commenter pour faire marcher Presentation Video*/}
    const [showModal, setShowModal] = useState<boolean>(true);
    {/* TODO: A decomenter pour faire marcher Presentation Video*/}
    // const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedNeverShowAgain, setSelectedNeverShowAgain] = useState(()=> {
        /*TODO: A decomenter pour faire marcher Presentation Video*/
        // return localStorage.getItem("IDTrackerOverview") !== "true";
        return true;
    });
    const [showOverview, setShowOverview] = useState<boolean>(() => {
        /* TODO : A décomenter pour faire marcher Video Prsentation
        return localStorage.getItem("VerifyOverview") !== "false";*/
        return true
    });

    const handleTypeChange = (type: string) => {
        setType(type);
    };

    // reset component state
    const resetState = () => {
        setType("");
        setIsScanning(false);
        setIsScanned(false);
        setErrorMessage("");
        setDocument(undefined);
        setErrorType({ status: 0, message: "" });
    };

    useEffect(() => {
        resetState();
    }, [navigate]);

    const handleStartVerification = (video: boolean) => {
        setSelectedNeverShowAgain(false);
        localStorage.setItem("IDTrackerOverview", JSON.stringify(!selectedNeverShowAgain));
        setShowModal(false);
        setShowOverview(false);
        setIsByVideo(video);
    };

    return (
        <div className="h-100">
            <PageHeader>
                <h5> Piece d'identité </h5>
                <IDIcon className="PageHeader__icon" fill={"#ffeaaa"} />
            </PageHeader>
            {((showOverview)) && credits > 0 ? (
                <>
                    <IDTrackerOverView
                        showOverview={showOverview}
                        setShowOverview={setShowOverview}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        selectedNeverShowAgain={selectedNeverShowAgain}
                        setSelectedNeverShowAgain={setSelectedNeverShowAgain}
                        handleStartVerification={handleStartVerification}
                    />
                </>
            ) : (
                <div className="VerifyPage content__body">
                    <div className="VerifyPage__row row">
                        <div className="VerifyPage__content col-sm-6">
                            {credits <= 0 ? (
                                <>
                                    <Alert type="error">Vous n'avez pas de crédits pour Ajouter un document. Veuillez acheter des crédits.</Alert>
                                    <BuyCreditsCard />
                                </>
                            ) : !isScanning && !isScanned && errorType?.status !== 400 ? (
                                <AddDocumentForm
                                    onChangeType={handleTypeChange}
                                    type={type}
                                    isScanning={isScanning}
                                    setIsScanning={setIsScanning}
                                    setIsScanned={setIsScanned}
                                    setErrorMessage={setErrorMessage}
                                    setDocument={setDocument}
                                    errorType={errorType}
                                    setErrorType={setErrorType}
                                    setApplicant_id={setApplicant_id}
                                    applicant_id={applicant_id}
                                    isByVideo={isByVideo}
                                />
                            ) : errorType?.status !== 400 ? (
                                <>
                                    <AddDocumentInfos
                                        isScanning={isScanning}
                                        document={document}
                                    />
                                </>
                            ) : (
                                <ErrorScan
                                    error={errorType?.message}
                                    resetState={resetState}
                                />
                            )}
                        </div>
                        <div className="VerifyPage__illu col-sm-6 center">
                            <div>
                                <h1 className="VerifyPage__title text-center mb-4">Ajouter une pièce d'identité</h1>
                                <DocumentIllustration type={type}/>
                                {isScanning &&
                                    <Loader text="Analyse en cours. L'analyse peut prendre jusqu'à 30secondes."/>}
                            </div>
                        </div>
                    </div>
                    {errorMessage && (
                        <Toast
                            type="error"
                            message={errorMessage}
                        />
                    )}
                </div>
            )}
            {((showModal)) && credits > 0 && (
                <Modal onClose={() => setShowModal(false)} isOpen={showModal} title="Vérifier un document"
                       infoModal
                       type="info">
                    <div className="w-100">
                        <p>J’effectue l'ajout du document par :</p>
                        <div className="d-flex gap-05 flex-wrap">
                            <Button label="Vidéo" style={Button_Style.OUTLINED}
                                    onClick={() => handleStartVerification(true)} icon={<IconPlay/>}
                                    type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}/>
                            <Button label="Photo" style={Button_Style.OUTLINED}
                                    onClick={() => handleStartVerification(false)} icon={<IconUpload/>}
                                    type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}/>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};
export default IDTracker;
