import "./style.scss";

import {FC, useContext, useEffect} from "react";
import { updateNotification, updateNotificationSingle } from "../../api/notifications";

import { DocumentsContext } from "../../contexts/DocumentsContext";
import { ReactComponent as IconNotification } from "../../assets/img/icons/icon_bell.svg";
import Notification from "../Notification/Notification";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router";
import {NotificationContext} from "../../contexts/NotificationContext";
import {useLocation} from "react-router-dom";


interface NotificationsListProps {
    title?: string;
    fromDashBoard?: boolean;
    filter: 'id-assistant' | 'id-locker' | 'identity-documents' | 'all'; // tabNotificationActions recordID
    showSetAllAsSeenText?: boolean;
    notificationTab?: string;
    limit?: number;
}

const NotificationsList: FC<NotificationsListProps> = ({
    title,
    filter,
    fromDashBoard = true,
    showSetAllAsSeenText = false,
    notificationTab,
    limit
}) => {

    const location = useLocation(); // Hook pour récupérer l'URL actuelle
    const {
        notificationsUnseen,
        fetchNotificationsByActions,
        notifications,
    } = useContext(DocumentsContext);

    const { currentNotificationTab, setCurrentNotificationTab} = useContext(NotificationContext);

    let navigate = useNavigate();

    const { userType } = useContext(UserContext);


    useEffect(() => {
        fetchNotificationsByActions(currentNotificationTab, limit);
    }, [notificationTab, currentNotificationTab]);

    useEffect(() => {
        if (location.pathname.includes('notifications')) {
            fetchNotificationsByActions('all', limit);
        } else {
            fetchNotificationsByActions(filter, limit);
        }
    }, [location.pathname]);

    const tryUpdateNotificationSingle = async (idNotif: number) => {
        try {
            await updateNotificationSingle(userType, idNotif);
        } catch (error) {
            console.log(error);
        } finally {
            console.log("NotificationsList - RefreshDocuments")
            fetchNotificationsByActions(filter, limit);
        }
    };

    const updateNotificationsList = async () => {
        try {
            await updateNotification(userType);
        } catch (error) {
            console.log(error);
        } finally {
            fetchNotificationsByActions(filter, limit);
        }
    };


    return (
        <>
            {fromDashBoard && (
                <div className="notifications__header">
                    <p className="notifications__header__title">{title ? title : "Notifications"}</p>
                    <div className="notifications__header__count">
                        <IconNotification
                            fill="#1956A8"
                            width={24}
                            height={24}
                        />
                        <span className="notificationDot-L">{notificationsUnseen}</span>
                    </div>
                </div>
            )}

            <ul className="notifications">
                {/* {notifications.length > 0 && notifications?.map((notification) => (
          <Notification
            key={notification.id}
            notification={notification}
            updateNotification={tryUpdateNotificationSingle}
          />
        ))} */}
                <div>
                    {notifications &&
                        notifications?.length > 0 &&
                        notifications
                            .map((notification) => (
                                <Notification
                                    key={notification.id}
                                    notification={notification}
                                    updateNotification={tryUpdateNotificationSingle}
                                />
                            ))}
                </div>
            </ul>
            {showSetAllAsSeenText && (
                <p
                    className="notifications__button"
                    onClick={() => updateNotificationsList()}>
                    Marquer tous comme lus
                </p>
            )}
            {!showSetAllAsSeenText && (
                <p
                    className="notifications__button"
                    onClick={() => navigate("/notifications")}
                >
                    Afficher toutes les notifications
                </p>
            )}

        </>
    );
};

export default NotificationsList;
