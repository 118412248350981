import AccompaniementsList from "../components/AccompaniementsList/AccompaniementsList";
import React, {FC, useState} from "react";
import HeaderSection from "../components/HeaderSection";
import IDAssistantCard from "../components/IDAssistantCard";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";
import IDAssistantOverview from "./IDAssistantOverview";
import { ReactComponent as IconFaq } from "../assets/img/icons/icon_Faq.svg";

const iconProps = {
    fill: "#2f62ae",
    width: "20px",
};

const Accompagnements: FC = () => {
    // Initialisation avec la valeur du localStorage (par défaut: true si non défini)
    const [showOverview, setShowOverview] = useState<boolean>(() => {
        // TODO : A décomenter pour faire marcher Video Prsentation
        const storedValue = localStorage.getItem("IDAssistantOverview");
        return storedValue !== null ? JSON.parse(storedValue) : true;
        // return false;
    });
    // const handleAddAccompaniment = async () => {
    //     try {
    //         await createAccompaniment(type);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };
    return (
        <>
            <PageHeader>
                <h5>ID Assistant</h5>
            </PageHeader>
            {showOverview ?
                (
                    <IDAssistantOverview
                        setShowOverview={setShowOverview}
                        showOverview={showOverview}
                    />
                )
                :
                (
                    <div className="content__body">
                        <div className="row">
                            <div className="col-lg-9">
                                <HeaderSection title="Accompagnements en cours"/>
                                <AccompaniementsList/>
                                <div>
                                    <IDAssistantCard/>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <NotificationsList filter='id-assistant' limit={10}/>
                            </div>
                        </div>
                        {/* TODO : A décomenter pour faire marcher Video Prsentation*/}
                        <div className="mt-2 mb-1">
                            <div
                                className="d-flex justify-content-center align-items-center gap-05">
                                <u className="pointer"
                                   onClick={() => {
                                       setShowOverview(true);
                                   }}>Découvrez-vous le fonctionnement d'ID Assistant</u>
                                <IconFaq {...iconProps} />
                            </div>
                        </div>
                    </div>

                )
            }
        </>
    );
};
export default Accompagnements;