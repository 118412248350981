import { Route, Routes } from "react-router-dom";

import CommonsConfirmation from "./pages/CommonsConfirmation";
import ConfirmPhone from "./components/ConfirmPhone";
import CreditsPricing from "./pages/CreditsPricing";
import Dashboard from "./pages/Dashboard";
import DashboardPro from "./pages/DashboardPro";
import DatabaseDocuments from "./pages/DatabaseDocuments";
import Document from "./pages/DocumentDetail";
import Faq from "./pages/Faq";
import FilterPage from "./pages/FilterPage";
import Folder from "./pages/IDLockerDetail";
import ForgetPassword from "./pages/ForgetPassword";
import Home from "./components/Home";
import IDAssistantAdd from "./pages/IDAssistantAdd";
import IDAssistantDetail from "./pages/IDAssistantDetail";
import IDAssistantList from "./pages/IDAssistantList";
import IDLockerList from "./pages/IDLockerList";
import IDTracker from "./pages/IDTracker";
import IDTrackerPricing from "./pages/IDTrackerPricing";
import IDTrackerRequest from "./pages/IDTrackerRequest";
import Layout from "./components/Layout";
import ListIDs from "./pages/DocumentsList";
import Login from "./pages/Login";
import NotFoundPage from "./pages/NotFoundPage";
import Profil from "./pages/Profil";
import Register from "./pages/Register";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import StripePayment from "./pages/StripePayment";
import { UserContext } from "./contexts/UserContext";
import Verify from "./pages/Verify";
import { useContext } from "react";
import NotificationAll from "./pages/NotificationAll";

const ConditionalRoutes = () => {
    const { userType } = useContext(UserContext);

    const individualRoutes = (
        <>
            <Route
                path="/particuliers/dashboard"
                element={
                    <Layout>
                        <Dashboard />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/pieces-identite"
                element={
                    <Layout>
                        <ListIDs />
                    </Layout>
                }
            />

            <Route
                path="/mes-documents/:id"
                element={
                    <Layout>
                        <Document />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/id-assistant"
                element={
                    <Layout>
                        <IDAssistantList />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/id-assistant/add/:offer"
                element={
                    <Layout>
                        <IDAssistantAdd />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/id-assistant/:id"
                element={
                    <Layout>
                        <IDAssistantDetail />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/id-locker/:id"
                element={
                    <Layout>
                        <Folder />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/id-locker/"
                element={
                    <Layout>
                        <IDLockerList />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/id-assistant/add/"
                element={
                    <Layout>
                        <IDAssistantAdd />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/idtracker"
                element={
                    <Layout>
                        <IDTracker />
                    </Layout>
                }
            />

            <Route
                path="/particuliers/profil"
                element={
                    <Layout>
                        <Profil />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/verifier-document"
                element={
                    <Layout>
                        <Verify />
                    </Layout>
                }
            />
            {/*<Route
                path="/particuliers/dark-web"
                element={
                    <Layout>
                        <Verify isDarkWeb />
                    </Layout>
                }
            />*/}
            <Route
                path="/particuliers/faq"
                element={
                    <Layout>
                        <Faq />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/filter"
                element={
                    <Layout>
                        <FilterPage />
                    </Layout>
                }
            />
            

        </>
    );

    const partnerRoutes = (
        <>
            <Route
                path="/professionnels/dashboard"
                element={
                    <Layout>
                        <DashboardPro />
                    </Layout>
                }
            />
            <Route
                path="/professionnels/verifier-document"
                element={
                    <Layout>
                        <Verify />
                    </Layout>
                }
            />
            <Route
                path="/professionnels/base-de-donnees"
                element={
                    <Layout>
                        <DatabaseDocuments />
                    </Layout>
                }
            />
            <Route
                path="/professionnels/profil"
                element={
                    <Layout>
                        <Profil />
                    </Layout>
                }
            />
            <Route
                path="/professionnels/faq"
                element={
                    <Layout>
                        <Faq />
                    </Layout>
                }
            />
            <Route
                path="/professionnels/filter"
                element={
                    <Layout>
                        <FilterPage />
                    </Layout>
                }
            />
            <Route
                path="/professionnels/dark-web"
                element={
                    <Layout>
                        <Verify isDarkWeb />
                    </Layout>
                }
            />

            <Route
                path="/professionnels/id-locker/:id"
                element={
                    <Layout>
                        <Folder />
                    </Layout>
                }
            />
            <Route
                path="/professionnels/id-locker/"
                element={
                    <Layout>
                        <IDLockerList />
                    </Layout>
                }
            />
        </>
    );

    const mainRoutes = (
        <>
            <Route
                path="/"
                element={<Home />}
            />
            <Route
                path="/particuliers/register"
                element={<Register />}
            />
            <Route
                path="/particuliers/login"
                element={<Login />}
            />
            <Route
                path="/professionnels/login"
                element={<Login />}
            />
            <Route
                path="/professionnels/register"
                element={<Register />}
            />
            <Route
                path={`/commons/confirmation`}
                element={<CommonsConfirmation />}
            />
            <Route
                path={`/commons/password`}
                element={<ResetPasswordPage />}
            />
            <Route
                path="/particuliers/request"
                element={<IDTrackerRequest />}
            />
            <Route
                path="/filter"
                element={
                    <main>
                        <div className="content">
                            <FilterPage />
                        </div>
                    </main>
                }
            />
            <Route
                path="/confirmPhone"
                element={<ConfirmPhone />}
            />
            <Route
                path="/resend"
                element={<ForgetPassword />}
            />
            <Route
                path="*"
                element={<NotFoundPage />}
            />

            <Route
                path="/particuliers/credits-pricing"
                element={
                    <Layout>
                        <CreditsPricing />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/id-tracker-pricing"
                element={
                    <Layout>
                        <IDTrackerPricing />
                    </Layout>
                }
            />
            <Route
                path="/particuliers/payment/:offer/:planIndex/:isMonth"
                element={
                    <Layout>
                        <StripePayment />
                    </Layout>
                }
            />
            <Route
                path="/notifications"
                element={
                    <Layout>
                        <NotificationAll />
                    </Layout>
                }
            />
            <Route
                path="/dark-web"
                element={
                    <main>
                        <div className="content">
                            <DatabaseDocuments />
                        </div>
                    </main>
                }
            />
        </>
    );

    return (
        <Routes>
            {mainRoutes}
            {userType === "individual" ? individualRoutes : partnerRoutes}
        </Routes>
    );
};

export default ConditionalRoutes;
