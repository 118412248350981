import React, { useState } from "react";
import Button, {Button_Style, Button_Type} from "../Button/Button";
import Input from "../Input";

function WatermarkTextSet({ placeholder = "Type something...", buttonText = "Submit", onClick }) {
  const [inputValue, setInputValue] = useState("");
  const [currentText, setCurrentText] = useState("Document Protégé Par IDProtect");
  

  const handleClick = () => {
    if (onClick) {
      onClick(inputValue);
    }
    setCurrentText(inputValue);
    setInputValue(""); // Optional: clear input after action
  };

  return (

    <div style={{ display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" }}>
      <div><p style={{ margin: "5px" }}>Texte Actuel: <span className="text-warning">{currentText}</span></p></div>
      {/*<input
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        style={{ padding: "8px", flex: "1" }}
      />*/}
        <div className="d-flex flex-row flex-wrap gap-05">
            <Input placeholder={placeholder} value={inputValue} type="textarea" onChange={(e) => setInputValue(e.target.value)} />
            <Button
                style={Button_Style.SOLID}
                type={Button_Type.PRIMARY}
                size="S"
                label={buttonText}
                onClick={handleClick}
                disabled={inputValue.trim() === ""}
                className='m-auto my-button'
            />
        </div>
    </div>
  );
}

export default WatermarkTextSet;