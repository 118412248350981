import React, {FC, useContext, useEffect, useState} from "react";

import DocumentCard from "../components/DocumentCard";
import { DocumentUnion } from "../types/types";
import { DocumentsContext } from "../contexts/DocumentsContext";
import EmptyContent from "../components/EmptyContent";
import { FolderContext } from "../contexts/FoldersContext";
import FoldersList from "../components/FoldersList";
import HeaderSection from "../components/HeaderSection";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";
import Table from "../components/Table";
import {useLocation} from "react-router-dom";
import DashboardOverview from "./DashboardOverview";
import { UserContext } from "../contexts/UserContext";
import { getSubscription } from "../api/user";
import { ReactComponent as IconVerified } from "../assets/img/icons/icon_status-Verified.svg";
import { ReactComponent as IconWarning } from "../assets/img/icons/icon_status-Warning.svg";


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
const columns = [
    { key: "nationality", label: "" },
    { key: "type", label: "Type de document" },
    { key: "number", label: "Numéro" },
    { key: "createdAt", label: "Date de l'ajout" },
    { key: "status", label: "Statut" },
    { key: "isBlocked", label: "" },
];

const Dashboard: FC = () => {

    // Initialisation avec la valeur du localStorage (par défaut: true si non défini)
    const [showOverview, setShowOverview] = useState<boolean>(() => {
        // TODO : A décomenter pour faire marcher Video Prsentation
        const storedValue = localStorage.getItem("DashboardOverview");
        return storedValue !== null ? JSON.parse(storedValue) : true;
        // return false;
    });

    const [selectedRows, setSelectedRows] = useState<DocumentUnion[]>([]);
    const { allDocuments, refreshOnlyTheDocuments } = useContext(DocumentsContext);
    const { folders, sharedFolders } = useContext(FolderContext);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation(); // Hook pour récupérer l'URL actuelle
    const { user, userType, isVerified, getMe } = useContext(UserContext);

    const [hasOffer, sethasOffer] = useState<any>(null);
    
        const type = user?.type;
    
        useEffect(() => {
            const checkOffer = async () => {
                try {
                    const response = await getSubscription(type, user?.email);
                    if (response.data.offrePurchase && response.data.offrePurchase.id > 1) {
                        sethasOffer(true);
                    }
                    else{
                        sethasOffer(false);
                    }

                } catch (error) {
                    console.log(error);
                }
            };
    
            if (user) {
                checkOffer();
            }
        }, [user, type]);

    useEffect(() => {
        if (allDocuments) {
            setIsLoading(false); // Dès que le doc est chargé, on désactive le loader
        }
        refreshOnlyTheDocuments();
    }, [location.pathname, allDocuments?.length]);


    const handleSelectionChange = (rows: DocumentUnion[]) => {
        setSelectedRows(rows);
    };

    return (
        <>
            <PageHeader>
                <h5>Tableau de bord</h5>
            </PageHeader>
            {showOverview ?
                (
                    <DashboardOverview
                        setShowOverview={setShowOverview}
                        showOverview={showOverview}
                    />
                )
                :
                (
                    <div className="Dashboard content__body">
                        <div className="row">
                            <div className="col-xl-9">
                                <div className="d-flex flex-column h-100">
                                    <HeaderSection
                                        title="Pièces d'identité"
                                        number={allDocuments.length}
                                    />
                                    {isLoading && (
                                        <div>
                                            <EmptyContent
                                                text="Chargement, veuillez patienter, un instant..."
                                                showIcon={false}
                                            />
                                            <br/>
                                        </div>
                                    )}
                                    {!isLoading && (
                                        <>
                                            {isMobile ? (
                                                <>
                                                    {allDocuments.map((document: DocumentUnion) => (
                                                        <React.Fragment key={document.id}>
                                                            <DocumentCard document={document}/>
                                                        </React.Fragment>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    {hasOffer == true && (
                                                        <span className={`isVerified tag tag-icon tag-success ml-2`}>
                                                            <IconVerified
                                                                fill="#17EB79"
                                                                height={16}
                                                                width={16}
                                                            />{" "}
                                                            Protection Dark Web Active
                                                        </span>
                                                    )}

                                                    {hasOffer == false && (
                                                        <span className={`isVerified tag tag-icon tag-warning ml-2`}>
                                                            <IconWarning
                                                                fill="#FF5326"
                                                                height={16}
                                                                width={16}
                                                            />{" "}
                                                            Protection Dark Web Inactive
                                                        </span>
                                                    )}

                                                    {hasOffer == null && (
                                                        <span className={`isVerified tag tag-icon ml-2`}>
                                                            
                                                        </span>
                                                    )}

                                                    <Table
                                                        columns={columns}
                                                        rows={allDocuments}
                                                        onSelectionChange={handleSelectionChange}
                                                        selectedRows={selectedRows}
                                                        editable={false}
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}

                                    <HeaderSection
                                        title="Mes dossiers"
                                        number={folders.length}
                                    />
                                    <FoldersList folders={folders}/>
                                    <div className="mb-2">
                                        <HeaderSection
                                            title="Les dossiers partagés avec moi"
                                            number={sharedFolders.length}
                                        />
                                        {sharedFolders.length > 0 ? (
                                            <FoldersList folders={sharedFolders}/>
                                        ) : (
                                            <EmptyContent text="Aucun dossier partagé avec vous"/>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3">
                                <NotificationsList filter='all' limit={10}/>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
};

export default Dashboard;
