import './style.scss'; 
 
import React, { FC } from 'react';
import EmptyContent from "../EmptyContent";
import Alert from "../Alert";
import DocumentIllustration from "../DocumentIllustration";

type UnauthorizedPageProps = {
    message?: string,
    alertText?: string,
}

const UnauthorizedPage: FC<UnauthorizedPageProps> = ({
     message = "Accès restreint",
     alertText = "Veuillez contacter l'administrateur pour débloquer l'accès."
}) => {
    return (
        <div className="UnauthorizedPage">
            <div className="content__body">
                <div className="FilterOverview h-100">
                    <div className="content__body h-100">
                        <div className="row flex-wrap">
                            <div className="col-sm-4">
                                <div className="content--card relative h-100">
                                    <EmptyContent text={message} showIcon={false} />
                                    <br />
                                    <Alert type="info">{alertText}</Alert>
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <DocumentIllustration />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default UnauthorizedPage;