import "./style.scss";

import { Button_Style, Button_Type } from '../Button/Button';
import { useContext, useEffect, useState } from "react";

import Button from "../Button";
import DocumentIllustration from "../DocumentIllustration/DocumentIllustration";
import DocumentStatus from "../DocumentStatus";
import { DocumentsContext } from "../../contexts/DocumentsContext";
import LockDocumentModal from "../LockDocumentModal";
import LostDocumentModal from "../LostDocumentModal";
import ToggleIdNumber from "../ToggleIdNumber";
import { formatTypeDocument } from "../../assets/functions/index.js";

const DocumentDetails = ({ document }: any) => {
  // const [localDocument, setLocalDocument] = useState<DocumentUnion>(document);
  const [showModal, setShowModal] = useState(false);
  const [showModalLost, setShowModalLost] = useState(false);
  const { updateDocument } = useContext(DocumentsContext) as any;

  useEffect(() => {
    updateDocument()

  }, [])

  return (
    <div className="DocumentDetails">
      <div className="DocumentDetails__header">
        <div className="DocumentDetails__header__left">
          <h4>{formatTypeDocument(document.type)}</h4>
          <span className={`tag  tag-success`}>
            ID Tracker activé
          </span>
        </div>

        <div className="DocumentDetails__header__right">
          <span className="font-S">Numéro de document</span>

          <ToggleIdNumber number={document.number || document.numero} />
        </div>
      </div>
      <div className="DocumentDetails__body">
        <div className="row">
          <div className="col-sm-7">
            <DocumentIllustration type={document.type} />
            <div className="DocumentDetails__body__buttons">
              <Button
                label={
                  (document.locked)
                    ? "Déverrouiller"
                    : "Verrouiller"
                }
                style={Button_Style.OUTLINED}
                onClick={() => setShowModal(true)}
                disabled={document.lost}
              />
              <Button
                label="Faire opposition"
                type={Button_Type.Error}
                onClick={() => setShowModalLost(true)} disabled={document.lost} />
              {showModalLost && (
                <LostDocumentModal
                  isOpen={showModalLost}
                  onClose={() => setShowModalLost(false)}
                  documents={[document]}
                  isInitialLost
                />
              )}
              {showModal && (
                <LockDocumentModal
                  isOpen={showModal}
                  onClose={() => setShowModal(false)}
                  documents={[document]}
                  documentsLocked={document.locked}
                />
              )}
            </div>
          </div>
          <div className="col-sm-5">
            <DocumentStatus
              status={{
                conformite: document.conformite ?? true,
                lost: document.lost,
                locked: document.lost ? document.lost : document.locked,
                darkWeb: document.darkWeb ?? false
              }}
              documentType={document.type} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentDetails;
