import axios from "../../axiosConfig";
import {number} from "yup";
import {tabNotificationActions} from "../../utils/utils";

// eslint-disable-next-line react-hooks/rules-of-hooks

export async function getNotifications(type, limit) {
  try {
	  const response = await axios.post('/client/notifications',{ type, limit });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getNotificationsByActions(type, limit, notificationActions) {
    try {
        const response = await axios.post('/client/notifications/by-notification-actions',{ type, limit, notificationActions });
        return response.data;
    } catch (error) {
        return error;
    }
}

// Function to fetch notifications based on the current tab
export async function fetchNotificationsForTab(tab, limit) {
    if (tab === 'all') {
        return await getNotifications("individual", limit);
    } else if (!tabNotificationActions[tab]) {
        console.error("Invalid tab specified:", tab);
        return [];
    }

    return await getNotificationsByActions("individual", limit, tabNotificationActions[tab]);
}

export async function sendNotification (type, notification) {
    const body = new FormData()
    body.append('notification', JSON.stringify(notification))
    body.append('type', type)
    const response = await axios.post('/client/notification', body)
    if (response) {
      this.getNotifcations(type);
      return response
    }
}

export async function updateNotification(type) {
	const response = await axios.put('/client/notification/updateState',{ type })
	return response
 }
export async function updateNotificationSingle(type, idNotif) {
	const response = await axios.put(`/client/notification/updateStateById`,{ type: type, notificationId: idNotif })
	return response
 }

export async function unseenNotifications(type) {
	const response = await axios.post('/client/notification/notseen',{ type })
	return response
}
