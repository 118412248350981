import React, {FC, useContext, useState} from "react";

import { UserContext } from "../contexts/UserContext";
import Alert from "../components/Alert";
import BuyCreditsCard from "../components/BuyCreditsCard";
import IconSmile from "../assets/img/icons/icon_smile.png";
import Button from "../components/Button";
import { Button_Style, Button_Type } from "../components/Button/Button";
import { ReactComponent as IconBack } from "../assets/img/icons/icon_arrow-Right.svg";
import VideoFrame from "../components/VideoFrame";
import Checkbox from "../components/Checkbox";
import DocumentIllustration from "../components/DocumentIllustration";
import { ReactComponent as IconPlay } from "../assets/img/icons/icon_Play.svg";
import { ReactComponent as IconUpload } from "../assets/img/icons/icon_Upload.svg";

type VerifyOverViewProps = {
    showOverview: boolean;
    setShowOverview: React.Dispatch<React.SetStateAction<boolean>>;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedNeverShowAgain: boolean;
    setSelectedNeverShowAgain: React.Dispatch<React.SetStateAction<boolean>>;
    handleStartVerification: any;
};

const VerifyOverview: FC<VerifyOverViewProps> = ({
    showModal,
    setShowModal,
    selectedNeverShowAgain,
    setSelectedNeverShowAgain,
    handleStartVerification
}) => {
    const { user, userType } = useContext(UserContext);
    const credits = user?.credits ?? 0;
    const [selectedCheckbox, setSelectedCheckbox] = useState(selectedNeverShowAgain);


    const handleStartVerify = () => {
        setShowModal(true);
        //setSelectedNeverShowAgain(true);
    };

    const checkSelect = () => {
        const newSelect = !selectedNeverShowAgain;
        setSelectedNeverShowAgain(newSelect);
    };

    return (
        <div className="VerifyOverview h-100">
            <div className="content__body h-100">
                <div className="row flex-wrap">
                    {/* Section Gauche */}
                    <div className="col-sm-5">
                        <div className="content--card-2 relative mb-2">

                            <div className="mb-2 text-secondary">
                                Vérifier l’authenticité d’un document d’identité est une étape clé pour
                                prévenir la fraude. Avec <b>ID Verify</b>, assurez-vous en quelques secondes de
                                la validité des documents grâce à l’IA
                                <ul className="mt-1">
                                    <li><b>ID Verify</b> : Analysez et authentifiez cartes d’identité, passeports,
                                        permis de conduire et titres de séjour via photo ou vidéo
                                    </li>
                                </ul>
                                {!selectedCheckbox && (
                                    <>
                                        <div className="mt-1">
                                            <b>Cas d’usage</b>
                                            <ul>
                                                {userType != "partner" && (
                                                    <>
                                                        <li>Sécurisation des pièces d’identité avant tout envoi en
                                                            ligne
                                                        </li>
                                                        <li>Protection des justificatifs et documents administratifs
                                                        </li>
                                                        <li>Indication de confidentialité sur des documents sensibles
                                                        </li>
                                                    </>
                                                )}
                                                {userType == "partner" && (
                                                    <>
                                                        <li>Vérification d’identité lors d’une inscription en ligne
                                                        </li>
                                                        <li>Contrôle des documents pour sécuriser des transactions
                                                            sensibles
                                                        </li>
                                                        <li>Lutte contre l’usurpation d’identité dans les démarches
                                                            administratives
                                                        </li>
                                                    </>
                                                )
                                                }
                                            </ul>
                                        </div>
                                        Avec <b>ID Verify</b>, simplifiez la vérification de l’identité de vos
                                        interlocuteurs.
                                    </>
                                )}
                            </div>

                            {credits <= 0 ? (
                                <div className="VerifyResult card">
                                    <Alert type="error">
                                        Vous n'avez pas de crédits pour vérifier un document. Veuillez acheter des
                                        crédits.
                                    </Alert>
                                    <BuyCreditsCard/>
                                </div>
                            ) : (
                                <div className="h-55 d-flex flex-column justify-content-between align-items-center">
                                    {selectedCheckbox && (
                                        <Alert type="info" showDefaultIcon={false}>
                                            <div className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="text-primary">
                                                    <img src={IconSmile} alt="smile" width={30}/>
                                                </div>
                                                <div className="text-center">
                                                    Vous
                                                    avez <b>{credits}</b> crédit{credits > 1 ? "s" : ""} disponible{credits > 1 ? "s" : ""} pour
                                                    vos vérifications.
                                                </div>
                                            </div>
                                        </Alert>
                                    )}

                                    {/* TODO : A commenter pour faire marcher Video Prsentation */}
                                    {!showModal && selectedCheckbox && (
                                        <div className="w-100 center">
                                            <p>J’effectue la vérification par :</p>
                                            <div className="d-flex gap-05 flex-wrap">
                                                <Button label="Vidéo" style={Button_Style.OUTLINED}
                                                        onClick={() => handleStartVerification(true)} icon={<IconPlay/>}
                                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}/>
                                                <Button label="Photo" style={Button_Style.OUTLINED}
                                                        onClick={() => handleStartVerification(false)}
                                                        icon={<IconUpload/>}
                                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}/>
                                            </div>
                                        </div>
                                    )}

                                    {/* TODO : A décomenter pour faire marcher Video Prsentation */}
                                    {!selectedCheckbox && (
                                        <div>
                                            <Checkbox
                                                label="Ne plus afficher cette page"
                                                onChange={() => checkSelect()}
                                                checked={selectedNeverShowAgain}
                                            />

                                            <Button
                                                type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                                label="Vérifier un document"
                                                style={Button_Style.SOLID}
                                                disabled={credits <= 0}
                                                onClick={handleStartVerify}
                                                icon={<IconBack className="PageHeader__icon" fill={"#9FC1EF"} />}
                                            /></div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* TODO : A décomenter pour faire marcher Video Prsentation */}
                    <div className="col-sm-7">
                        {selectedCheckbox && (
                            <DocumentIllustration/>
                        )}
                        {!selectedCheckbox && (
                            <div className="mt-3">
                                <VideoFrame url="https://www.youtube.com/watch?v=CVafwMMggwI">
                                    <span className="text-primary bold">ID VERIFY :</span>
                                    <span className="text-danger"> Découvrez le fonctionnement de notre outil</span>
                                </VideoFrame>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyOverview;
