import "./style.scss";

import { Button_Style, Button_Type } from "../Button/Button";
import React, { FC } from "react";

import Button from "../Button";
import { ReactComponent as IconDelete } from "../../assets/img/icons/icon_Trash.svg";
import { ReactComponent as IconFolder } from "../../assets/img/icons/icon_folder.svg";
import { ReactComponent as IconShare } from "../../assets/img/icons/icon_share.svg";
import ToggleButton from "../ToggleButton";

type SubHeaderProps = {
    folderName: string;
    folderId: number;
    folderVisibility: boolean;
    handleReturn: React.MouseEventHandler<SVGSVGElement>;
    handleDeleteFolder: React.MouseEventHandler<HTMLButtonElement>;
    handleShareFolder: React.MouseEventHandler<HTMLButtonElement>;
} & Record<string, any>;

const SubHeader: FC<SubHeaderProps> = ({
    folderName,
    folderId,
    folderVisibility,
    handleReturn,
    handleDeleteFolder,
    handleSecret,
    handleShareFolder,
}) => {
    return (
        <div className="tooltip w-100">
            <div className="tooltip_container_tools  w-100">
                <IconFolder
                    fill="#1956A8"
                    width="16px"
                />
                <h4 className="mb-0"> {folderName}</h4>

                <div className="tooltip_container_tools_divider first"></div>
                <div>Rendre secret</div>
                <ToggleButton
                    checked={!folderVisibility}
                    icons={false}
                    onChange={() => handleSecret()}
                />
                <div className="tooltip_container_tools_divider"></div>
                <Button
                    label="Partager"
                    size="S"
                    style={Button_Style.SOLID}
                    type={Button_Type.BASE}
                    icon={<IconShare />}
                    onClick={handleShareFolder}
                />
                <div className="tooltip_container_tools_divider"></div>
                <Button
                    label="Supprimer"
                    size="S"
                    style={Button_Style.SOLID}
                    type={Button_Type.REVERSE_ERROR}
                    icon={<IconDelete />}
                    onClick={handleDeleteFolder}
                />
            </div>
        </div>
    );
};

export default SubHeader;
