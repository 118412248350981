import React, {FC, useContext, useEffect, useState} from "react";

import { DocumentUnion } from "../types/types";
import { DocumentsContext } from "../contexts/DocumentsContext";
import PageHeader from "../components/PageHeader";
import TabContent from "../components/TabContent";
import Tabs from "../components/Tabs";
import DocumentsOverview from "./DocumentsOverview";
import { ReactComponent as IconFaq } from "../assets/img/icons/icon_Faq.svg";

const iconProps = {
	fill: "#2f62ae",
	width: "20px",
};

const ListIDs: FC = () => {
	const [selectedRows, setSelectedRows] = useState<DocumentUnion[]>([]);
	const { followed, lostDocuments, allDocuments, refreshDocuments } = useContext(
		DocumentsContext
	);

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (followed) {
			setIsLoading(false); // Dès que le doc est chargé, on désactive le loader
		}
		else {
			refreshDocuments();
		}
	}, [allDocuments]);

	// Initialisation avec la valeur du localStorage (par défaut: true si non défini)
	const [showOverview, setShowOverview] = useState<boolean>(() => {
		// TODO : A décomenter pour faire marcher Video Prsentation
		const storedValue = localStorage.getItem("DashboardOverview");
		return storedValue !== null ? JSON.parse(storedValue) : true;
		// return false;
	});


	const handleSelectionChange = (rows: DocumentUnion[]) => {
		setSelectedRows(rows);
	};

	const tabs = [
		{
			label: "Tous",
			notification: false,
			content: (
				<TabContent
					documents={allDocuments}
					selectedRows={selectedRows}
					onSelectionChange={handleSelectionChange}

				/>
			),
		},
		{
			label: "Actifs",
			notification: false,
			content: (
				<TabContent
					documents={followed}
					selectedRows={selectedRows}
					onSelectionChange={handleSelectionChange}

				/>
			),
		},
		{
			label: "Inactifs",
			notification: false,
			content: (
				<TabContent
					documents={lostDocuments}
					selectedRows={selectedRows}
					onSelectionChange={handleSelectionChange}

				/>
			),
		},
	];

	return (
		<>
			<PageHeader>
				<h5>Pièces d'identité</h5>
			</PageHeader>
			{showOverview ?
				(
					<DocumentsOverview
						setShowOverview={setShowOverview}
						showOverview={showOverview}
					/>
				)
				:
				(
					<div className="content__body">
						<Tabs tabs={tabs}/>
						{/* TODO : A décomenter pour faire marcher Video Prsentation*/}
						<div className="mt-2 mb-1">
							<div
								className="d-flex justify-content-center align-items-center gap-05">
								<u className="pointer"
								   onClick={() => {
									   setShowOverview(true);
								   }}>Découvrez-vous le fonctionnement de Pièces d'identité</u>
								<IconFaq {...iconProps} />
							</div>
						</div>
					</div>
				)
			}
		</>
	);
};


export default ListIDs;
