// eslint-disable-next-line react-hooks/rules-of-hooks
import axios from '../../axiosConfig';


export async function getUserCurrentPayments (userType, userId) {
    try {
        // const userType = 'individual';
        const url = `client/payment_logs`

        const body = new FormData()
        body.append('type', userType)
        body.append('userId', userId)

        const response = await axios.post(url, new FormData(), {
            // withCredentials: true,
        });

        if (response) {
            console.log(response)
        }
    } catch (error) {
        console.error(error);
    }
};


export async function getUserCurrentCredits  (userType, userId) {
    try {

        const url = `client/credits`

        const body = new FormData()
        body.append('type', userType)
        body.append('userId', userId)

        const response = await axios.post(url, new FormData(), {
            withCredentials: true,
        });

        if (response) {
            console.log(response)
        }
    } catch (error) {
        console.error(error);
    }
};

export async function decrementCredits(type, decrementValue) {
    const url = 'client/decrement_credits';
    const data = new FormData();
    data.append('type',type);
    data.append('decrementValue',decrementValue);

    return axios.post(url,data);
}
