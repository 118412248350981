import "./style.scss";
import axios from '../../axiosConfig';

import { Button_Style, Button_Type } from "../Button/Button";
import { FC, useContext, useEffect, useRef, useState } from "react";

import Alert from "../Alert";
import Button from "../Button";
import { ReactComponent as IconDowload } from "../../assets/img/icons/icon_Download.svg";
import { ReactComponent as IconSend } from "../../assets/img/icons/icon_Send.svg";
import ModalSendFile from "../ModalSendFile";
import { degrees, PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { UserContext } from "../../contexts/UserContext";

type ImageFilterProps = {
    watermarkText?: string;
    imageSrc: File;
    watermarkSrc: string;
    watermarkPDF: string;
};

const ImageFilter: FC<ImageFilterProps> = ({ imageSrc, watermarkText= "Document Protégé par ID-Protect", watermarkSrc, watermarkPDF }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [filteredPDFURL, setFilteredPDFURL] = useState<string | null>(null);
    const [filteredFile, setFilteredFile] = useState<File | null>(null);
    const { userType } = useContext(UserContext);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const urlToFile = async (url: string, filename: string, mimeType: any) => {
        const res = await fetch(url);
        const blob = await res.blob();
        return new File([blob], filename, { type: mimeType });
    };

    const loadImage = async (file: File | string): Promise<HTMLImageElement> => {
        const image = new Image();
        await new Promise((resolve, reject) => {
            image.onload = resolve;
            image.onerror = reject;

            if (typeof file === "string") {
                image.src = file;
            } else {
                const reader = new FileReader();

                reader.onload = (event) => {
                    image.src = event.target?.result as string;
                };
                reader.onerror = (error) => reject(error);
                reader.readAsDataURL(file);
            }
        });
        return image;
    };


    const downloadImage = () => {
        const downloadLink = document.createElement("a");
        let urlToDownload: string;
        let extension: string;
        if (imageSrc.type === "application/pdf") {
            urlToDownload = filteredPDFURL ? filteredPDFURL : URL.createObjectURL(imageSrc);
            extension = "pdf";
        } else {
            const canvas = canvasRef.current;
            urlToDownload = canvas?.toDataURL("image/png") as string;
            extension = "png";
            if (!canvas) {
                return;
            }
        }

        downloadLink.href = urlToDownload;
        downloadLink.download = `${imageSrc.name}-ID_Protect.${extension}`;
        downloadLink.click();
    };

    const applyWatermarkToPDF = async (
        pdfFile: File,
        watermarkImageSrc: string,
        text = watermarkText
      ) => {
        const pdfBuffer = await pdfFile.arrayBuffer();
        let pdfDoc: PDFDocument;
      
        try {
          pdfDoc = await PDFDocument.load(pdfBuffer);
        } catch (error) {
          const fileName = pdfFile.name;
          console.error("Error loading PDF:", error);
          setErrorMessage(`Le fichier ${fileName} est encrypté et ne peut être modifié.`);
          return;
        }
      
        const watermarkImageBytes = await fetch(watermarkImageSrc).then((res) => res.arrayBuffer());
        const watermarkImage = await pdfDoc.embedPng(watermarkImageBytes);
        const watermarkFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
      
        const now = new Date();
        const formattedDate = now.toISOString().split("T").join(" ").split(".")[0];
        const watermarkText = `• ${text} - ${formattedDate}`;
      
        const baseWidth = 1204;
        const baseHeight = 1704;
      
        const pages = pdfDoc.getPages();
      
        for (const page of pages) {
          const { width, height } = page.getSize();
      
          // 1. Draw watermark image (proportional & centered)
          const scaleFactor = 0.6;
          const maxWatermarkWidth = width * scaleFactor;
          const maxWatermarkHeight = height * scaleFactor;
      
          const originalDims = watermarkImage.scale(1);
          const imgAspectRatio = originalDims.width / originalDims.height;
      
          let displayWidth = maxWatermarkWidth;
          let displayHeight = maxWatermarkWidth / imgAspectRatio;
      
          if (displayHeight > maxWatermarkHeight) {
            displayHeight = maxWatermarkHeight;
            displayWidth = maxWatermarkHeight * imgAspectRatio;
          }
      
          const centerX = (width - displayWidth) / 2;
          const centerY = (height - displayHeight) / 2;
      
          page.drawImage(watermarkImage, {
            x: centerX,
            y: centerY,
            width: displayWidth,
            height: displayHeight,
            opacity: 1,
          });
      
          // 2. Draw repeated diagonal watermark text
          const fontSize = Math.round(Math.min(width, height) / 32);
          const textWidth = watermarkFont.widthOfTextAtSize(watermarkText, fontSize);
          const textHeight = watermarkFont.heightAtSize(fontSize);
      
          const spacingX = textWidth + (125 * width) / baseWidth;
          const spacingY = textHeight + (350 * height) / baseHeight;
      
          for (let y = -height; y < height * 2; y += spacingY) {
            for (let x = -width; x < width * 2; x += spacingX) {
              page.drawText(watermarkText, {
                x,
                y,
                size: fontSize,
                font: watermarkFont,
                color: rgb(25 / 255, 86 / 255, 168 / 255),
                rotate: degrees(30),
                opacity: 1,
              });
            }
          }
        }
      
        const pdfBytes = await pdfDoc.save();
        const objectURL = URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" }));
        setFilteredPDFURL(objectURL);
      
        return objectURL;
      };
      
      

      const applyWatermark = async (text = watermarkText) => {
        try {
          const [image, watermark] = await Promise.all([loadImage(imageSrc), loadImage(watermarkSrc)]);
          const canvas = canvasRef.current;
      
          if (!canvas) return;
      
          const context = canvas.getContext("2d");
          if (!context) return;
      
          canvas.width = image.width;
          canvas.height = image.height;
      
          // === 1. Draw original image ===
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
      
          // === 2. Draw center watermark image proportionally ===
          const scaleFactor = 0.6;
          const maxWatermarkWidth = canvas.width * scaleFactor;
          const maxWatermarkHeight = canvas.height * scaleFactor;
      
          const aspectRatio = watermark.width / watermark.height;
      
          let displayWidth = maxWatermarkWidth;
          let displayHeight = maxWatermarkWidth / aspectRatio;
      
          if (displayHeight > maxWatermarkHeight) {
            displayHeight = maxWatermarkHeight;
            displayWidth = maxWatermarkHeight * aspectRatio;
          }
      
          const centerX = (canvas.width - displayWidth) / 2;
          const centerY = (canvas.height - displayHeight) / 2;
      
          context.drawImage(watermark, centerX, centerY, displayWidth, displayHeight);
      
          // === 3. Draw diagonal repeating text ===
          const now = new Date();
          const formattedDate = now.toISOString().split("T").join(" ").split(".")[0];
          const watermarkText = `• ${text} - ${formattedDate}`;
      
          const fontSize = Math.round(Math.min(canvas.width, canvas.height) / 32);
          context.font = `${fontSize}px DejaVu Sans, Arial, sans-serif`;
          context.fillStyle = "rgba(25, 86, 168, 1)";
          context.textAlign = "left";
          context.textBaseline = "top";
      
          const textWidth = context.measureText(watermarkText).width;
      
          // 🔁 Proportional spacing (based on A4 dimensions)
          const baseWidth = 1240;
          const baseHeight = 1704;
          const spacingX = textWidth + (125 * canvas.width) / baseWidth;
          const spacingY = fontSize + (350 * canvas.height) / baseHeight;
      
          for (let y = -canvas.height; y < canvas.height * 2; y += spacingY) {
            for (let x = -canvas.width; x < canvas.width * 2; x += spacingX) {
              context.save();
              context.translate(x, y);
              context.rotate((-30 * Math.PI) / 180);
              context.fillText(watermarkText, 0, 0);
              context.restore();
            }
          }
      
          // === 4. Export result ===
          canvas.toBlob((blob) => {
            if (blob) {
              const file = new File([blob], `${imageSrc.name || "output"}-ID_Protect.png`, {
                type: "image/png",
              });
              setFilteredFile(file);
            }
          }, "image/png");
        } catch (error) {
          console.error("Error applying watermark:", error);
        }
      };
      
    useEffect(() => {
        let extension = imageSrc.type === "application/pdf" ? "pdf" : "png";

        if (filteredPDFURL) {
            // If it's a PDF, use the existing method
            urlToFile(filteredPDFURL, `${imageSrc.name}-ID_Protect.${extension}`, imageSrc.type).then((file) => {
                setFilteredFile(file);
            });
        }
    }, [filteredPDFURL, imageSrc.name, imageSrc.type, canvasRef]);

    useEffect(() => {
        if (imageSrc.type === "application/pdf") {
            applyWatermarkToPDF(imageSrc, watermarkPDF).then((pdfURL) => {});
        } else {
            applyWatermark();
        }
    }, [imageSrc, watermarkPDF]);

    return (
        <>
            {errorMessage && <Alert type="error">{errorMessage}</Alert>}
            {!errorMessage && (
                <div className="ImageFilter">
                    {imageSrc.type === "application/pdf" ? (
                        <div className="thumbnail-pdf">
                            <iframe
                                src={`${filteredPDFURL}#toolbar=0`}
                                width="100%"
                                height="100%"
                                title="Filtered PDF"
                            />
                        </div>
                    ) : (
                        <div className="thumbnail">
                            <canvas ref={canvasRef} />
                        </div>
                    )}
                    <div className="d-flex gap-1">
                        <Button
                            onClick={downloadImage}
                            style={Button_Style.OUTLINED}
                            type={Button_Type.PRIMARY}
                            isIconButton
                            icon={<IconDowload width={16} />}
                        />
                        <Button
                            onClick={() => setShowModal(true)}
                            style={Button_Style.OUTLINED}
                            type={Button_Type.PRIMARY}
                            isIconButton
                            disabled={!userType}
                            icon={<IconSend width={16} />}
                        />
                    </div>
                    {showModal && (
                        <ModalSendFile
                            onClose={() => setShowModal(false)}
                            isOpen={showModal}
                            file={filteredFile}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default ImageFilter;
