import "./style.scss";

import { FC, useEffect, useRef, useState } from "react";

import { ReactComponent as IconCarret } from "../../assets/img/icons/icon_carret-Down.svg";
import {NOTIFICATION_ACTIONS, NotificationType} from "../../types/types";

type NotificationProps = {
  notification: NotificationType;

  updateNotification: (id: number) => void;
};

const Notification: FC<NotificationProps> = ({
  notification,
  updateNotification
}) => {

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [textOpen, setTextOpen] = useState<boolean>(false);
  const [textOveflow, setTextOverFlow] = useState<boolean>(false);
  const detailRef = useRef<HTMLParagraphElement>(null);
  const formattedDate = new Date(notification.createdAt).toLocaleDateString("fr-FR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  });
  const actionTitle = (action: string) => {
    switch (action) {
      case NOTIFICATION_ACTIONS.Document_Change_Followed_State:
        return "Modification";
      case NOTIFICATION_ACTIONS.Document_Delete:
        return "Suppression";
      case NOTIFICATION_ACTIONS.Accompaniment_New_Status:
        return "Evolution du dossier";
      case NOTIFICATION_ACTIONS.Accompaniment_New:
        return "Nouveau dossier";
      case NOTIFICATION_ACTIONS.Accompaniment_Delete:
        return "Fin du dossier";
      case NOTIFICATION_ACTIONS.Document_View_Request:
        return "Demande de consultation de document";
      case NOTIFICATION_ACTIONS.Document_View_Request_New_Status:
        return "Statut de la demande de consultation mis à jour";
      case NOTIFICATION_ACTIONS.Document_Lost_Declaration:
        return "Modification";
      case NOTIFICATION_ACTIONS.Folder_Notification:
        return "Notification sur un dossier";
      case NOTIFICATION_ACTIONS.Document_Delete_Followed:
        return "Suppression";
      case NOTIFICATION_ACTIONS.Document_New_Followed:
        return "Nouveau document";
      case NOTIFICATION_ACTIONS.Folder_Creation:
        return "Nouveau dossier";
      case NOTIFICATION_ACTIONS.Folder_Delete:
        return "Suppression dossier";
      case NOTIFICATION_ACTIONS.File_Added:
        return "Fichier ajouté";
      case NOTIFICATION_ACTIONS.File_Delete:
        return "Fichier supprimé";
      case NOTIFICATION_ACTIONS.Invitation_Send:
        return "Partage dossier";
      case NOTIFICATION_ACTIONS.Permission_Deleted:
        return "Suppression Accès";
      case NOTIFICATION_ACTIONS.Permission_Changed:
        return "Permission modifiée";
      case NOTIFICATION_ACTIONS.Visibility_Changed:
        return "Visibilité modifiée";
      case NOTIFICATION_ACTIONS.Expired_Date_Changed:
        return "Date d'expiration modifiée";
      default:
        return "Nouvelle notification";
    }
  };


  const toggleText = (e: React.MouseEvent) => {
    e.stopPropagation();
    setTextOpen(!textOpen);
    if (!notification.seen) {
        updateNotification(notification?.id)
    }
  }


  useEffect(() => {
    // To put carret down if text overflowing
    if (detailRef.current) {
        const originalHeight = detailRef.current.clientHeight;

        detailRef.current.className = 'notification__initial';
        const fullHeight = detailRef.current.scrollHeight;

        detailRef.current.className = 'notification__detail';

        if (fullHeight > originalHeight) {
          setTextOverFlow(true);
        }
      }
  }, [notification.detail]);

  return (
    <li
      className={`notification ${notification.seen ? "isRead" : "notRead"
        }`}
      onClick={() => { !notification.seen && updateNotification(notification?.id) }}
      key={notification.id}
    >
      {!notification.seen &&
        <span
          className="notificationDot"
        ></span>
      }
      <div className="notification__header">
        <div>
          <h5 className="notification__title">{actionTitle(notification.action)}</h5>
          <p className="notification__date">{formattedDate}</p>
        </div>
      </div>
      <p ref={detailRef} className={`notification__detail ${textOpen ? "opened" : ""}`}>{notification.detail}</p>
      {textOveflow && (
            <div className="notification__caret">
                <div className="notification__caret_container" onClick={(e) => toggleText(e)}>
                    <IconCarret  fill="#1956A8" width={16} height={16} className={`icon ${textOpen ? 'rotate' : ''}`} />
                </div>
            </div>
        )}
    </li>
  );
};

export default Notification;
