import { FolderContext } from "../contexts/FoldersContext";
import FolderList from "../components/FolderList";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";
import Tabs from "../components/Tabs";
import React, {useContext, useEffect, useState} from "react";
import IDLockerOverview from "./IDLockerOverview";
import {useLocation} from "react-router-dom";
import { ReactComponent as IconFaq } from "../assets/img/icons/icon_Faq.svg";
import UnauthorizedPage from "../components/UnauthorizedPage";
import {UserContext} from "../contexts/UserContext";

const iconProps = {
    fill: "#2f62ae",
    width: "20px",
};

const IDLockerList = () => {
    const { isVerified } = useContext(UserContext);
    const { folders, sharedFolders, allFolders  } = useContext(FolderContext);
    const location = useLocation(); // Hook pour récupérer l'URL actuelle


    useEffect(() => {
        allFolders();
    }, [location.pathname, folders?.length, sharedFolders?.length]);

    // Initialisation avec la valeur du localStorage (par défaut: true si non défini)
    const [showOverview, setShowOverview] = useState<boolean>(() => {
        // TODO : A décomenter pour faire marcher Video Prsentation
        const storedValue = localStorage.getItem("IDLockerOverview");
        return storedValue !== null ? JSON.parse(storedValue) : true;
        // return false
    });

    const tabs = [
        {
            label: "Tous",
            notification: false,
            content: <FolderList folders={[...folders, ...sharedFolders]} />,
        },
        {
            label: "Mes dossiers",
            notification: false,
            content: <FolderList folders={folders} />,
        },
        {
            label: "Dossiers partagés avec moi",
            notification: false,
            content: (
                <FolderList
                    folders={sharedFolders}
                    canCreateFolder={false}
                />
            ),
        },
    ];

    if (isVerified == false) {
        return (
            <UnauthorizedPage
                message="Votre compte n'est pas vérifié!"
                alertText=" Pour vérifier votre compte veuillez ajouter au moins un document d'identité en suivi."
            />
        );
    }

    return (
        <div>
            <PageHeader>
                <h5>ID Locker</h5>
            </PageHeader>
            {showOverview ?
                (
                    <IDLockerOverview
                        setShowOverview={setShowOverview}
                        showOverview={showOverview}
                    />
                )
                :
                (
                    <div className="content__body">
                        <div className="row">
                            <div className="col-lg-9 ">
                                <Tabs tabs={tabs}/>
                            </div>
                            <div className="col-lg-3">
                                <NotificationsList filter='id-locker' limit={10}/>
                            </div>
                        </div>
                        {/*TODO : A décomenter pour faire marcher Video Prsentation*/}
                        <div className="mt-4 mb-1">
                            <div
                                className="d-flex justify-content-center align-items-center gap-05">
                                <u className="pointer"
                                   onClick={() => {
                                       setShowOverview(true);
                                   }}>Découvrez-vous le fonctionnement d'ID Locker</u>
                                <IconFaq {...iconProps} />
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    );
};

export default IDLockerList;