export interface Pricing {
    title: string;
    head: string;
    offreId?: number;
    price: number;
    description?: string;
    text?: string;
    featuresRange?: number[];
    features?: string[];
    priceYear?: number;
    isRecurringPayment: boolean;
}


export enum DefaultOffres {
    GRATUIT = "Gratuit", // id = 1
    SERENITE = "Sérénité", // id = 2
    FAMILLE = "Famille", // id = 3
    SERENITE_PROMO = "Sérénité Promo", // id = 4
    FAMILLE_PROMO = "Famille Promo", // // id = 5
}

export enum PaymentTypes {
    CREDIT = "Credit de Verification",
    ID_TRACKER = "ID Tracker",
    ACCOMAPGNEMENT = "Accompagnement",
}

export const FEATURES = [
    "Nous vous protégeons chez les 5 acteurs les plus fraudés",
    "Mise en opposition de vos documents chez ID Protect",
    "Echange téléphonique avec un expert ID Protect",
    "Consultation des fichiers d’usurpation d’identité",
    "Rédaction et envoie des courriers par ID Protect",
    "Etude et accompagnement sur-mesure par nos juristes",
];

export const ASSISTANCE_PRICING: Pricing[] = [
    {
        head: PaymentTypes.ACCOMAPGNEMENT,
        title: "BASIC",
        price: 79,
        description: "1 dossier/pers",
        featuresRange: [0, 1, 2],
        isRecurringPayment: false,
    },
    {
        head: PaymentTypes.ACCOMAPGNEMENT,
        title: "STANDARD",
        price: 129,
        description: "1 dossier/pers",
        featuresRange: [0, 1, 2, 3, 4],
        isRecurringPayment: false,
    },
    {
        head: PaymentTypes.ACCOMAPGNEMENT,
        title: "PREMIUM",
        price: 489,
        description: "1 dossier/pers",
        featuresRange: [0, 1, 2, 3, 4, 5],
        isRecurringPayment: false,
    },
];

export const ID_TRACKER_PRICING: Pricing[] = [
    {
        head: PaymentTypes.ID_TRACKER,
        offreId: 1,
        title: DefaultOffres.GRATUIT,
        price: 0,
        priceYear: 0,
        description: "/mois",
        text: "La prévention contre l’usurpation d’identité",
        features: [
            "Découvrez si vos documents sont sur le Darkweb",
            "Protégez vos documents avant envoi, grâce à nos filtres",
            "Activez des alertes sur tous vos documents d’identité",
            "Mettez en opposition vos documents chez ID Protect",
        ],
        isRecurringPayment: true,
    },
    {
        head: PaymentTypes.ID_TRACKER,
        offreId: 4,
        title: DefaultOffres.SERENITE_PROMO,
        price: 2.9,
        priceYear: 29.9,
        description: "/mois",
        text: "Fonctionnalités de l’offre gratuite incluses",
        features: [
            "Scan mensuel du Darkweb pour vos documents",
            "Confiez à nos experts vos démarches administratives",
            "Vérifiez l’identité d’un tiers (3 vérifications/mois)",
            "30% de réduction sur nos accompagnements",
        ],
        isRecurringPayment: true,
    },
    {
        head: PaymentTypes.ID_TRACKER,
        offreId: 5,
        title: DefaultOffres.FAMILLE_PROMO,
        price: 4.9,
        priceYear: 50.9,
        description: "/mois",
        text: "",
        features: [
            "Créez gratuitement 4 comptes Premium pour vos proches",
            "Confiez à nos experts vos démarches administratives",
            "Vérifiez l’identité d’un tiers (3 vérifications/mois/compte)",
            "30% de réduction sur nos accompagnements",
        ],
        isRecurringPayment: true,
    },
    {
        head: PaymentTypes.ID_TRACKER,
        offreId: 2,
        title: DefaultOffres.SERENITE,
        price: 7.9,
        description: "/mois",
        text: "",
        features: [
            "Scan mensuel du Darkweb pour vos documents",
            "Confiez à nos experts vos démarches administratives",
            "Vérifiez l’identité d’un tiers (3 vérifications/mois)",
            "30% de réduction sur nos accompagnements",
        ],
        isRecurringPayment: true,
    },
    {
        head: PaymentTypes.ID_TRACKER,
        offreId: 3,
        title: DefaultOffres.FAMILLE,
        price: 9.9,
        description: "/mois",
        text: "",
        features: [
            "Créez gratuitement 4 comptes Premium pour vos proches",
            "Confiez à nos experts vos démarches administratives",
            "Vérifiez l’identité d’un tiers (3 vérifications/mois/compte)",
            "30% de réduction sur nos accompagnements",
        ],
        isRecurringPayment: true,
    },
];

export const CREDITS_PRICING: Pricing[] = [
    { head: PaymentTypes.CREDIT, title: "10 credits", price: 9.99, isRecurringPayment: false },
    { head: PaymentTypes.CREDIT, title: "30 credits", price: 25, isRecurringPayment: false },
    { head: PaymentTypes.CREDIT, title: "100 credits", price: 74.99, isRecurringPayment: false },
];
