import  { ReactNode, createContext, useContext, useEffect, useState } from "react";

const NotificationContext = createContext<{
    currentNotificationTab: string;
    setCurrentNotificationTab: React.Dispatch<React.SetStateAction<string>>;
}>({
    currentNotificationTab: "all",
    setCurrentNotificationTab:  (value)=> {},
});

const NotificationContextProvider = ({ children }: { children: ReactNode }) => {
    const [currentNotificationTab, setCurrentNotificationTab] = useState("");

    return <NotificationContext.Provider value={{ currentNotificationTab, setCurrentNotificationTab }}>{children}</NotificationContext.Provider>;
};

export { NotificationContext, NotificationContextProvider };
