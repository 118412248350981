import "./style.scss";

import Button from "../Button";
import { Button_Style } from "../Button/Button";
import { FC } from "react";
import HeaderSection from "../HeaderSection";
import PriceCard from "../PriceCard";
import logoIdAssistant from "../../assets/img/Accompagnement.svg";
import { useNavigate } from "react-router-dom";

const IDAssistantCard: FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <HeaderSection title="Demande d'accompagnement" />
            <div className="d-flex gap-2 flex-column ">
                <PriceCard
                    title="Usurpation : basic / standard / premium "
                    logo={logoIdAssistant}
                >
                    <ul>
                        <li>Nous vous protégeons chez les 5 acteurs les plus fraudés et nous mettons en opposition vos
                            documents d'identité</li>
                        <li>Vous avez répondu à une fausse annonce de location ? Demandez l’aide de nos experts !</li>
                        <li>Nos experts sont à votre service pour vous accompagner dans vos démarches</li>
                    </ul>

                    <div className="d-flex gap-1">
                        <Button
                            label="Souscrire à un accompagnement"
                            size="L"
                            onClick={() => navigate("/particuliers/id-assistant/add/standard")}
                        />
                    </div>
                </PriceCard>
                {/*<PriceCard
                    title="Usurpation standard"
                    logo={logoIdAssistant}
                    price="129€"
                    subtitle="Évitez l'utilisation frauduleuse de vos documents d'identité">
                    <div className="d-flex gap-1 ">
                        <Button
                            label="En savoir plus"
                            size="L"
                            style={Button_Style.OUTLINED}
                        />
                        <Button
                            label="Souscrire"
                            size="L"
                            onClick={() => navigate("/particuliers/id-assistant/add/standard")}
                        />
                    </div>
                </PriceCard>
                <PriceCard
                    title="Usurpation premium"
                    logo={logoIdAssistant}
                    price="489€"
                    subtitle="Menez l'enquête et empêchez l'usurpation de recommencer">
                    <div className="d-flex gap-1">
                        <Button
                            label="En savoir plus"
                            size="L"
                            style={Button_Style.OUTLINED}
                        />
                        <Button
                            label="Souscrire"
                            size="L"
                            onClick={() => navigate("/particuliers/id-assistant/add/premium")}
                        />
                    </div>
                </PriceCard>*/}
            </div>
        </>
    );
};

export default IDAssistantCard;
