export function formatTypeDocument(typeDocument){
	const types = {
		'id': 'Carte d\'identité',
		'id_card': 'Carte d\'identité',
		'passeport': 'Passeport',
		'passport': 'Passeport',
		'permis': 'Permis de conduire',
		'driver_permit': 'Permis de conduire',
		'residency_permit': 'Titre de séjour',
		'sejour': 'Titre de séjour',
		'ID': 'Carte d\'identité',
		'Carte nationale d\'identité française': 'Carte d\'identité',
		'P': 'Passeport',
		'p': 'Passeport',
		'Passeport français': 'Passeport',
		'Permis de conduire': 'Permis',
		'S': 'Carte de séjour',
		'Titre de séjour français': 'Carte de séjour'
	}
	return types[typeDocument]
}