import React, {FC, useContext, useState} from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../contexts/UserContext";
import Button from "../components/Button";
import {Button_Style, Button_Type} from "../components/Button/Button";
import { ReactComponent as IconBack } from "../assets/img/icons/icon_arrow-Right.svg";
import VideoFrame from "../components/VideoFrame";
import Checkbox from "../components/Checkbox";



type IDAssistantOverViewProps = {
    showOverview: boolean;
    setShowOverview: any;
};


const IDAssistantOverview: FC<IDAssistantOverViewProps> = ({
      showOverview = true,
      setShowOverview,
}) => {
    const navigate = useNavigate();
    const { user, userType } = useContext(UserContext);

    const [selectedNeverShowAgain, setSelectedNeverShowAgain] = useState(false);


    const handleStartIDAssistant = () => {
        setShowOverview(false);
        localStorage.setItem("IDAssistantOverview", JSON.stringify(!selectedNeverShowAgain));
    };

    const checkSelect = () => {
        const newSelect = !selectedNeverShowAgain;
        setSelectedNeverShowAgain(newSelect);
    };

    return (
        <>
            <div className="IDAssistantOverview">
                <div className="content__body">
                    <div className="row flex-wrap">
                        <div className={`col-sm-6`}>
                            <div className="content--card-2 relative mb-2">
                                <div className="mb-2 text-secondary">
                                    <div>
                                        Faire face à une arnaque en ligne ou une usurpation d’identité peut être
                                        complexe et stressant. Avec <b>ID Assistant</b>, bénéficiez d’un
                                        accompagnement personnalisé pour réagir efficacement et protéger votre
                                        identité
                                    </div>

                                    <ul className="mt-1">
                                        <li>
                                            <b>ID Assistant</b> : Obtenez un soutien dédié et un suivi des démarches
                                            pour rétablir votre situation après une fraude ou une usurpation
                                            d’identité
                                        </li>
                                    </ul>
                                    <div className="mt-1">
                                        <b>Cas d’usage</b>
                                        <ul>
                                            <li>Accompagnement des victimes d’arnaques en ligne pour limiter les
                                                conséquences.
                                            </li>
                                            <li>Assistance dans les démarches administratives après une
                                                usurpation d’identité.
                                            </li>
                                            <li>Suivi personnalisé pour s’assurer de la bonne prise en compte des
                                                signalements.
                                            </li>
                                            <li>Conseils et actions préventives pour éviter toute récidive.
                                            </li>
                                        </ul>
                                    </div>
                                    Avec <b>ID Assistant</b>, ne restez pas seul face à la fraude : nous vous aidons
                                    à reprendre le contrôle de votre identité
                                    <br/>
                                </div>
                                <div className="d-flex flex-column justify-content-between align-items-center">
                                    <Checkbox
                                        label="Ne plus afficher cette page"
                                        onChange={checkSelect}
                                        checked={selectedNeverShowAgain}
                                    />
                                    <Button
                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                        label="ETRE ACCOMPAGNÉ"
                                        style={Button_Style.SOLID}
                                        onClick={() => handleStartIDAssistant()}
                                        icon={<IconBack
                                            className="PageHeader__icon"
                                            fill={"#9FC1EF"}
                                        />}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mt-3">
                                <VideoFrame
                                    url="https://www.youtube.com/watch?v=BcjhkoQ4s7Y"
                                >
                                    <span className="text-primary bold">ID ASSISTANT :</span>
                                    <span className="text-danger"> Découvrez le fonctionnement de notre outil</span>
                                </VideoFrame>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default IDAssistantOverview;