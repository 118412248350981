import "./style.scss";

import React, {FC, useContext, useEffect, useState} from "react";

import { AccompaniementsContext } from "../../contexts/AccompaniementsContext";
import { AccompanimentType } from "../../types/types";
import EmptyContent from "../EmptyContent";
import FolderCard from "../FolderCard";
import { useLocation } from "react-router-dom";

const AccompaniementsList: FC = () => {
    const location = useLocation();
    const pathname = location.pathname.split("/").slice(2).join("/");
    const { accompaniments, refreshAccompaniments } = useContext(AccompaniementsContext);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        if (accompaniments) {
            setIsLoading(false); // Dès que le doc est chargé, on désactive le loader
        }
        refreshAccompaniments();
    }, [location.pathname, accompaniments?.length]);

    return (
        <div className="AccompaniementsList">
            {isLoading && (
                <div>
                    <EmptyContent
                        text="Chargement, veuillez patienter, un instant..."
                        showIcon={false}
                    />
                    <br/>
                </div>
            )}
            {!isLoading && (
                <>
                    {accompaniments && accompaniments.length > 0 ? (
                        accompaniments?.map((accompaniment: AccompanimentType) => {
                            if (!accompaniment) return null;
                            const formatDate = (date: string) => {
                                const d = new Date(date);
                                return d.toLocaleDateString("fr-FR");
                            };
                            return (
                                <FolderCard
                                    key={accompaniment.id}
                                    name={formatDate(accompaniment.createdAt)}
                                    id={accompaniment.id}
                                    pathname={pathname}
                                    hideActions
                                />
                            );
                        })
                    ) : (
                        <EmptyContent text="Aucun dossiers" />
                    )}
                </>
            )}
        </div>
    );
};

export default AccompaniementsList;
