/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import EmptyContent from "../components/EmptyContent";
import File from "../components/File";
import FileUploader from "../components/FileUploader";
import { FolderContext } from "../contexts/FoldersContext";
import { ReactComponent as IconFolder } from "../assets/img/icons/icon_folder.svg";
import Modal from "../components/Modal";
import ModalShare from "../components/ModalShare";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";
import SubHead from "../components/SubHead";
import { UserContext } from "../contexts/UserContext";
import { createLinkInvitation } from "../api/folder";
import { toast } from "react-toastify";
import DocumentIllustration from "../components/DocumentIllustration";
import FiligraneImage from "../assets/img/watermark_2.png";
import Checkbox from "../components/Checkbox";
import Button from "../components/Button";
import {Button_Style, Button_Type} from "../components/Button/Button";
import VideoFrame from "../components/VideoFrame";
import Alert from "../components/Alert";
import UnauthorizedPage from "../components/UnauthorizedPage";

const IDLockerDetail = () => {
    const { user, isVerified } = useContext(UserContext);
    const { folder, tryRemoveFolder, tryGetFolder, tryChangeVisibility, tryAddFileToFolder, partners } = useContext(FolderContext);
    const type = user?.type;
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [modalShareIsOpen, setModalShareIsOpen] = useState(false);
    const [shareLink, setShareLink] = useState("");
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        const fetchFolder = async () => {
            await tryGetFolder(type, id);
        };
        fetchFolder();
        const tryCreateShareLink = async () => {
            const response = await createLinkInvitation(type, id);
            setShareLink(response?.data.link);
        };
        tryCreateShareLink();
    }, [id, type]);


    const handleUploadFile = async (files: any, fileType: string) => {
        files.map(async (file: any) => {
            try {
                await tryAddFileToFolder(type, id, file, fileType);
                // Fetch folder when add folder because reactivity doesn't work on the response addFolder
                await tryGetFolder(type, folder?.id.toString());
            } catch (error) {
                console.log(error);
            }
        });
    };
    const handleReturn = () => {
        navigate(-1);
    };

    const fileUploaderProps = {
        className: "mb-0",
        title: "Ajouter",
        allowedFormats:
            "application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        onDelete: () => console.log("delete"),
        isList: false,
    };

    const handleChangeVisibility = async () => {
        await tryChangeVisibility(folder?.id);
    };

    const handleShareFolder = async () => {
        setModalShareIsOpen(true);
    };

    if (isVerified == false) {
        return (
            <UnauthorizedPage
                message="Votre compte n'est pas vérifié!"
                alertText=" Pour vérifier votre compte veuillez ajouter au moins un document d'identité en suivi."
            />
        );
    }

    return (
        <>
            <PageHeader>
                <h5>
                    ID Locker /
                    <IconFolder
                        fill="#1956A8"
                        width="16px"
                    />
                    {folder?.name}
                </h5>
            </PageHeader>
            <div className="content__body">
                <div className="row">
                    <div className="col-lg-8">
                        {folder && (
                            <SubHead
                                folderName={folder.name}
                                folderId={folder.id}
                                folderVisibility={folder.visibility}
                                handleReturn={handleReturn}
                                handleSecret={handleChangeVisibility}
                                handleShareFolder={handleShareFolder}
                                handleDeleteFolder={() => setConfirmModalIsOpen(true)}
                            />
                        )}
                        <div>
                            <div
                                className="d-flex gap-1 mb-2 "
                                style={{flexWrap: "wrap"}}>
                                {folder?.ownerId.toString() === user?.id?.toString() && (
                                    <FileUploader
                                        {...fileUploaderProps}
                                        onChange={(files) => {
                                            handleUploadFile(files, "VERBAL");
                                        }}
                                        isList={false}
                                    />
                                )}
                                {folder?.documents && folder?.documents?.length > 0 ? (
                                    folder?.documents.map((file: any, index: any) => (
                                        <File
                                            key={index}
                                            file={file}
                                            isEditable={folder?.ownerId.toString() === user?.id.toString()}
                                        />
                                    ))
                                ) : (
                                    <EmptyContent text="Aucun document dans ce dossier"/>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <NotificationsList filter="id-locker" limit={10}/>
                    </div>
                </div>
                {confirmModalIsOpen && (
                    <Modal
                        title="Supprimer un dossier"
                        type="error"
                        labelOnConfirm="Supprimer"
                        isOpen={confirmModalIsOpen}
                        onClose={() => setConfirmModalIsOpen(false)}
                        onConfirm={async () => {
                            const response = await tryRemoveFolder(Number(id));
                            if (response) {
                                navigate(-1);
                                toast.info("Votre dossier a bien été supprimé", {
                                    position: "top-center",
                                });
                            } else {
                                toast.error("Il y a eu un problème...", {
                                    position: "top-center",
                                });
                            }
                        }}>
                        <p>Êtes vous sûr de vouloir supprimer ce dossier ? La suppression est définitive.</p>
                    </Modal>
                )}
                {modalShareIsOpen && (
                    <ModalShare
                        shareLink={shareLink}
                        isOpen={modalShareIsOpen}
                        onClose={() => setModalShareIsOpen(false)}
                        folder={folder}
                        partners={partners}
                    />
                )}
            </div>
        </>
    );
};

export default IDLockerDetail;
