import "./style.scss";

import { FC, useContext } from "react";

import { FileType } from "../../types/types";
import { FolderContext } from "../../contexts/FoldersContext";
import Modal from "../Modal";
import { UserContext } from "../../contexts/UserContext";

interface ModalApplyFilterProps {
    isOpen: boolean;
    onClose: () => void;
    file?: FileType;
    canvas: HTMLCanvasElement | null;
    folderId: string;
}
const ModalApplyfilter: FC<ModalApplyFilterProps> = ({ isOpen, onClose, file, canvas, folderId }) => {
    const { user } = useContext(UserContext);
    const { tryAddFilterToFile, tryGetFolder, tryAddFilterToFolder } = useContext(FolderContext);

    const type = user?.type;
    const handleAddWatermark = async () => {
        try {
            if (file) {
                await tryAddFilterToFile(Number(folderId), type, file, canvas);
            } else {
                await tryAddFilterToFolder(Number(folderId), type, canvas);
            }
            await tryGetFolder(type, folderId);
        } catch (e) {
            console.log(e);
        }
        onClose();
    };
    return (
        <>
            {isOpen && (
                <Modal
                    isOpen={isOpen}
                    onClose={onClose}
                    title="Appliquer un filtre"
                    type="info"
                    labelOnConfirm="Appliquer"
                    onConfirm={handleAddWatermark}>
                    <p>
                        Votre document n’est pas protégé. En ajoutant le filtre ID Protect, il ne pourra pas être utilisé à des fins
                        frauduleuse.
                    </p>
                    <p>Attention cette action est irréversible, veuillez conserver l’original du document.</p>
                </Modal>
            )}
        </>
    );
};

export default ModalApplyfilter;
