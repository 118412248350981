import "./style.scss";

import React, { FC, useContext, useEffect } from "react";

type ProfilCardProps = {
    title: string;
    logo: string;
    name?: string;
    children: React.ReactNode;
};
const ProfilCard: FC<ProfilCardProps> = ({ title, logo, name, children }) => {
    return (
        <div className="ProfilCard">
            <div className="ProfilCard__header">
                <div className="ProfilCard__header__title">
                    <img
                        src={logo}
                        alt=""
                    />
                    <h4>{title}</h4>
                </div>
                {name && <h3>{name}</h3>}
            </div>
            <div className="ProfilCard__body">{children}</div>
        </div>
    );
};

export default ProfilCard;
