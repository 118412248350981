import "./style.scss";

import Button, { Button_Style, Button_Type } from "../Button/Button";
import { FC, useContext, useEffect } from "react";

import { DocumentBasylic } from "../../types/types";
import Loader from "../Loader";
import ShareID from "@shareid/onboarding_desktop";
import { UserContext } from "../../contexts/UserContext";
import {getVerificationResult} from "../../api/analysis";
import {formatShareIDVideoResultToDocument} from "../../utils/utils";
import {decrementCredits} from "../../api/credits";

type ShareIDVerificationProps = {
    accessToken: string;
    applicantId?: string;
    setIsScanning?: any;
    setIsScanned?: any;
    setErrorType?: any;
    setDocument?: any;
    errorType?: any;
    setIsShareID: any;
    generateURLWithShareIdVideo: any;
};

const ShareIDVerification: FC<ShareIDVerificationProps> = ({
    accessToken,
    applicantId,
    setIsScanning,
    setIsScanned,
    setErrorType,
    setDocument,
    errorType,
    setIsShareID,
   generateURLWithShareIdVideo
}) => {
    const { user } = useContext(UserContext);
    const userType = user?.type;
    // add the class IDVerify__container to first div in the component ShareID

    const addClassToShareID = () => {
        const shareID = document.querySelector(".IDVerify");
        const container = shareID?.children[0];
        const footer = shareID?.children[1];
        const header = container?.children[0]?.children[0];

        header?.classList.add("IDVerify__header");
        header?.classList.remove("sdk-flex");

        container?.classList.add("IDVerify__container");
        container?.classList.remove("sdk-h-dvh", "md:sdk-pt-14", "sdk-pt-3", "sdk-px-3");
        container?.children[0]?.classList.remove("md:sdk-px-6", "sdk-px-3");

        footer?.classList.add("IDVerify__footer");
    };

    useEffect(() => {
        addClassToShareID();
    }, [accessToken]);

    const getDataFromUID = async () => {
        setIsScanning && setIsScanning(true);
        if (!userType) return;
        try {
            const response = await getVerificationResult(userType, applicantId);

            if (response.data.documentAnalyse.document.ocr != null) {
                await decrementCredits(userType, 1); //On enlève 1 à son crédit
            }

            if (response.data.documentAnalyse.reasons.length > 0 || response.data.documentAnalyse.reasons === null) {
                setIsScanned && setIsScanned(true);
                setIsScanning && setIsScanning(false);
                setErrorType && setErrorType({ status: 400, message: "une erreur s'est produite" });
            } else if (response.status === 200) {
                if (user?.type === "partner") {
                    if (response) {
                        generateURLWithShareIdVideo(response.data?.documentAnalyse);
                    }
                }
                setIsScanned && setIsScanned(true);
                setDocument && setDocument(formatShareIDVideoResultToDocument(response.data.documentAnalyse));
                setIsScanning && setIsScanning(false);
            }
        } catch (error: any) {
            console.error("Une erreur s'est produite :", error);
            if (error.response.status === 404 && errorType?.status !== 400) {
                setTimeout(() => getDataFromUID(), 5000);
            }
        }
    };
    return (
        <div className="IDVerify">
            {accessToken ? (
                <ShareID
                    debug={true}
                    token={accessToken}
                    language="fr"
                    onFail={(data: any) => {
                        console.log("verification failed : ", data);
                    }}
                    onSuccess={(data: any) => {
                        getDataFromUID();
                    }}
                    onExit={(data: any) => console.log("exit", data)}
                    theme={{
                        colors: {
                            primary: {
                                active: "#1956A8",
                                hover: "#0F3465",
                                disabled: "#9FC1EF",
                            },
                        },
                    }}
                    //redirectUrl="https://www.basylic.com"
                />
            ) : (
                <Loader />
            )}
            {/*<div className="">
                <p className="mb-2 text-center">Vous n'avez pas le document d'identité sur vous ?</p>
                <Button
                    type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                    style={Button_Style.OUTLINED}
                    label="Utiliser un scan du document"
                    onClick={() => setIsShareID(false)}
                    className="m-auto"
                />
            </div>*/}
        </div>
    );
};

export default ShareIDVerification;
