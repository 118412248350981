import React, {FC, useContext, useState} from "react";

import { UserContext } from "../contexts/UserContext";
import Button from "../components/Button";
import {Button_Style, Button_Type} from "../components/Button/Button";
import { ReactComponent as IconBack } from "../assets/img/icons/icon_arrow-Right.svg";
import VideoFrame from "../components/VideoFrame";
import Checkbox from "../components/Checkbox";


type IDLockerOverViewProps = {
    showOverview: boolean;
    setShowOverview: any;
};

const IDLockerOverview: FC<IDLockerOverViewProps> = ({
      showOverview = true,
      setShowOverview,
}) => {
    const { user, userType } = useContext(UserContext);
    const [selectedNeverShowAgain, setSelectedNeverShowAgain] = useState(false);


    const handleStartIDLocker = () => {
        setShowOverview(false);
        localStorage.setItem("IDLockerOverview", JSON.stringify(!selectedNeverShowAgain));

    };

    const checkSelect = () => {
        const newSelect = !selectedNeverShowAgain;
        setSelectedNeverShowAgain(newSelect);
    };

    return (
        <>
            <div className="IDLockerOverview">
                <div className="content__body">
                    <div className="row flex-wrap">
                        <div className={`col-sm-6`}>
                            <div className="content--card-2 relative mb-2">
                                <div className="mb-2 text-secondary">
                                    <div>
                                        Sécuriser le stockage et le partage de documents sensibles est essentiel
                                        pour éviter tout risque de fraude. Avec <b>ID Locker</b>, conservez et échangez
                                        vos fichiers en toute sécurité sur une plateforme protégée
                                    </div>

                                    <ul className="mt-1">
                                        <li><b>ID Locker</b> : Stockez et partagez vos documents d’identité et
                                            justificatifs dans un espace sécurisé.
                                        </li>
                                    </ul>
                                    <div className="mt-1">
                                        <b>Cas d’usage</b>
                                        <ul>
                                            <li>Conservation sécurisée des pièces d’identité et documents
                                                administratifs.
                                            </li>
                                            <li>Partage de fichiers sensibles <b>avec une vérification du destinataire</b>
                                                pour éviter toute utilisation malveillante.
                                            </li>
                                            <li>Centralisation des documents pour simplifier les démarches en
                                                ligne.
                                            </li>
                                            <li>Accès contrôlé et sécurisé aux documents professionnels et
                                                personnels.
                                            </li>
                                        </ul>
                                    </div>
                                    Avec <b>ID Locker</b>, protégez vos fichiers, maîtrisez leur diffusion et assurez-
                                    vous qu’ils ne tombent pas entre de mauvaises mains
                                    {/*<Alert type="info" showDefaultIcon={false}>
                                        <div
                                            className="d-flex flex-column justify-content-center align-items-center">
                                            <div className="text-primary"><img src={IconSmile} alt="smile"
                                                                               width={30}/></div>
                                            <div className="text-center">
                                                Souscrivez à l’offre prénium pour en pleinement bénéficier.
                                            </div>
                                            <Button
                                                label="SOUSCRIRE"
                                                size="M"
                                                style={Button_Style.OUTLINED}
                                                onClick={() => handleStartIDLocker()}
                                            />
                                        </div>
                                    </Alert>*/}
                                </div>
                                <div className="d-flex flex-column justify-content-end align-items-center">
                                    <Checkbox
                                        label="Ne plus afficher cette page"
                                        onChange={checkSelect}
                                        checked={selectedNeverShowAgain}
                                    />
                                    <Button
                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                        label="CONSTITUER UN DOSSIER"
                                        style={Button_Style.SOLID}
                                        onClick={() => handleStartIDLocker()}
                                        icon={<IconBack
                                            className="PageHeader__icon"
                                            fill={"#9FC1EF"}
                                        />}
                                    />
                                </div>


                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="mt-3">
                                <VideoFrame
                                    url="https://www.youtube.com/watch?v=8KaGklVIhq0"
                                    //prevImg={ImagePrev}
                                >
                                    <span className="text-primary bold">ID LOCKER :</span>
                                    <span className="text-danger"> Découvrez le fonctionnement notre outil</span>
                                </VideoFrame>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default IDLockerOverview;