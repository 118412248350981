import './style.scss';
import { ReactComponent as IconBack } from "../../assets/img/icons/icon_arrow-Left.svg";

import React, { FC } from 'react';
import {useNavigate} from "react-router-dom";

type PageHeaderProps = {
  children: React.ReactNode;
};
const PageHeader: FC<PageHeaderProps> = ({ children }) => {
    const navigate = useNavigate();

    return (
        /*<div className="PageHeader">
          
          <IconBack
              className="PageHeader__icon"
              fill={"#9FC1EF"}
              onClick={() => navigate(-1)}
          />

          {children}
        </div>*/
        <div className="PageHeader">
          {children}
        </div>
    );
}

export default PageHeader;
