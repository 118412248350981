import React, {FC, useState} from "react";

import { CREDITS_PRICING } from "../constants/pricings";
import CreditsPricingCard from "../components/CreditsPricingCard";
import PageHeader from "../components/PageHeader";
import ProfilCard from "../components/ProfilCard/ProfilCard";
import logoIdTracker from "../assets/img/ID Tracker.svg";
import { useNavigate} from "react-router-dom";
import Modal from "../components/Modal";
import VideoFrame from "../components/VideoFrame";
import { ReactComponent as IconFaq } from "../assets/img/icons/icon_Faq.svg";

const iconProps = {
    fill: "#2f62ae",
    width: "20px",
};

const CreditsPricing: FC = () => {
    const navigate = useNavigate();
    const handleNext = (planIndex: number) => {
        navigate(`/particuliers/payment/credits/${planIndex}/onetime`);
    };
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div className="CreditsPricing">
            <PageHeader>
                <h4>CREDIT DE VERIFICATION</h4>
            </PageHeader>
            <div className="content__body">
                <ProfilCard
                    title="Facturation"
                    logo={logoIdTracker}>
                    <h5>TROUVER le plan pour l’achat de vos credit de verification</h5>
                    <div className="row mb-1">
                        {CREDITS_PRICING.map((plan, index) => (
                            <div className="col-sm-4">
                                <CreditsPricingCard
                                    handleNext={handleNext}
                                    plan={plan}
                                    planIndex={index}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="d-flex justify-content-center align-items-center gap-05">
                        <u onClick={() => {
                            setShowModal(true);
                        }} className="pointer">en savoir plus</u>
                        <IconFaq {...iconProps} />
                    </div>
                </ProfilCard>
            </div>
            {showModal &&
                <Modal
                    onClose={() => setShowModal(false)} isOpen={showModal} title={""} infoModal={true}
                    type={"info"}>
                    <VideoFrame
                        url="https://www.youtube.com/watch?v=XvoHvSI-Huo"
                        // prevImg={ImagePrev}
                    >
                        <span className="text-primary bold">Credit de vérifcation :</span>
                        <span className="text-danger"> Découvrez l'importance de leur présence.</span>
                    </VideoFrame>
                </Modal>
            }
        </div>
    );
};
export default CreditsPricing;
