import React, {FC, useContext, useEffect, useState} from "react";

import Button from "../components/Button";
import {ID_TRACKER_PRICING} from "../constants/pricings";
import PageHeader from "../components/PageHeader";
import PriceCard from "../components/PriceCard";
import ProfilCard from "../components/ProfilCard/ProfilCard";
import logoIdTracker from "../assets/img/ID Tracker.svg";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../contexts/UserContext";
import {getSubscription} from "../api/user";
import Modal from "../components/Modal";
import {Button_Style, Button_Type} from "../components/Button/Button";
import {mailTo} from "../utils/email_utils";

type IDTrackerPricingProps = {
    isPage?: boolean;
};

const IDTrackerPricing: FC<IDTrackerPricingProps> = ({
     isPage= true
}) => {
    const navigate = useNavigate();
    const { user} = useContext(UserContext);
    const [isMonthActive, setIsMonthActive] = useState(true);

    const [offer, setOffer] = useState<any>();

    const type = user?.type;
    const [showModal, setShowModal] = useState<boolean>(false);


    useEffect(() => {
        const getOffer = async () => {
            try {
                const response = await getSubscription(type, user?.email);
                setOffer(response.data.offrePurchase);
            } catch (error) {
                console.log(error);
            }
        };

        if (user) {
            getOffer();
        }
    }, [user, type]);

    const handleNext = (planIndex: number) => {
        if (user?.id) {
            if (planIndex === 0) {
                navigate(`/particuliers/profil`);
            } else {
                navigate(`/particuliers/payment/id-tracker/${planIndex}/${isMonthActive ? "month" : "year"}`);
            }
        } else {
            navigate(`/particuliers/login`);
        }

    };

    return (
        <div className="IDTrackerPricing">
            {isPage && (
                <PageHeader>
                    <h4>ID TRACKER</h4>
                </PageHeader>
            )}
            <div className="content__body">
                <ProfilCard
                    title="ID Tracker"
                    logo={logoIdTracker}>
                    <h6>TROUVER le plan pour activer la suveillance Dark Web</h6>
                    <div className="d-flex flex-row mb-1 flex-wrap gap-1">
                        {ID_TRACKER_PRICING.map((plan, index) => {
                            /*if ((index == 0) && !isPage) {
                                return ;
                            }*/
                            return (
                                <div className="w-30 min-w-40">
                                    <PriceCard
                                        plan={plan}
                                        title={`${plan?.title ? plan?.title : ""}`}
                                        logo={logoIdTracker}
                                        isMonthActive={isMonthActive}
                                        setIsMonthActive={setIsMonthActive}>
                                        <Button
                                            label={offer?.id == plan?.offreId ? "Votre souscription" : "Souscrire"}
                                            size="L"
                                            onClick={() => {
                                                if (offer?.id) {
                                                    if (offer?.id > 1) { // SI il a deja un abonnement actif
                                                        setShowModal(true);
                                                    } else {
                                                        return handleNext(index);
                                                    }
                                                } else {
                                                    return handleNext(index);
                                                }
                                            }}
                                            disabled={plan?.offreId == 1 || offer?.id == plan?.offreId}
                                        />
                                    </PriceCard>
                                </div>
                            );
                        })}
                    </div>
                </ProfilCard>
            </div>
            {showModal && (
                <Modal
                    onClose={() => setShowModal(false)}
                    isOpen={showModal}
                    title="Abonnement existant"
                    infoModal
                    type="info"
                >
                    <p>
                        Vous avez déjà l'abonnement <strong className="text-danger">{offer?.name}</strong> actif.
                        <br/>
                        Vous ne pouvez pas en souscrire un nouveau tant que celui-ci n'est pas expiré ou résilié.
                    </p>

                    <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
                        <Button
                            size="L"
                            onClick={() => navigate("/particuliers/profil")}
                            label="Voir mon abonnement"
                        >
                        </Button>
                        <Button
                            type={Button_Type.Error}
                            style={Button_Style.OUTLINED}
                            size="L"
                            onClick={() => mailTo("cancel", user)}
                            label={"Résilier à l'offre : " + offer?.name}
                        >

                        </Button>
                    </div>
                </Modal>
            )}

        </div>
    );
};
export default IDTrackerPricing;
