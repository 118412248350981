import { ReactNode, createContext, useContext, useEffect, useState } from "react";

import { AccompanimentType } from "../types/types";
import { UserContext } from "./UserContext";
import { getAccompaniment } from "../api/accompaniment";

const AccompaniementsContext = createContext<{
    accompaniments: AccompanimentType[];
    refreshAccompaniments: () => void;
}>({
    accompaniments: [],
    refreshAccompaniments: () => {},
});

const AccompaniementsContextProvider = ({ children }: { children: ReactNode }) => {
    const { userType } = useContext(UserContext);
    const [accompaniments, setAccompaniements] = useState<AccompanimentType[]>([]);

    const fetchAccompaniments = async () => {
        if (!userType) return;
        try {
            // const array = [];

            const folder = await getAccompaniment(userType);
            setAccompaniements(folder.accompaniments);
            return accompaniments;
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        fetchAccompaniments();
    }, [userType]);

    const refreshAccompaniments = async () => {
        await fetchAccompaniments();
    };

    return <AccompaniementsContext.Provider value={{ accompaniments, refreshAccompaniments }}>{children}</AccompaniementsContext.Provider>;
};

export { AccompaniementsContext, AccompaniementsContextProvider };
