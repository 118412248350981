import { FC, useContext, useEffect, useState } from "react";

import Accordion from "../components/Accordion";
import DocumentDetails from "../components/DocumentDetails";
import { DocumentUnion } from "../types/types";
import { DocumentsContext } from "../contexts/DocumentsContext";
import { ReactComponent as IconReturn } from "../assets/img/icons/icon_arrow-Left.svg";
import { ReactComponent as IconRight } from "../assets/img/icons/icon_arrow-Right.svg";
import NotificationsList from "../components/NotificationsList";
import PageHeader from "../components/PageHeader";
import faqs from "../assets/dataTests/FAQ.json";
import { formatTypeDocument } from "../assets/functions";
import { useNavigate } from "react-router-dom";

const Document: FC = () => {
    const [currentDocument, setCurrentDocument] = useState<DocumentUnion | undefined>(undefined);
    const [currentFaqs, setCurrentFaqs] = useState<any[]>([]);
    const navigate = useNavigate();
    const { allDocuments } = useContext(DocumentsContext);

    useEffect(() => {
        const id = getCurrentDocumentId();
        const currentDocument = allDocuments.find((item) => item.id === id);
        setCurrentDocument(currentDocument);

        if (currentDocument && currentDocument.type) {
            setCurrentFaqs(faqs?.filter((faq) => faq.type === currentDocument.type)[0].faqs);
        }
    }, [allDocuments]);

    const getCurrentDocumentId = (): number => {
        const url = window.location.href;
        const idString = url.substring(url.lastIndexOf("/") + 1);
        const idNumber = parseInt(idString, 10);
        return idNumber;
    };

    const handleReturn = () => {
        navigate(-1);
    };

    if (!currentDocument) {
        return null; // or render a loading state
    }

    return (
        <>
            <PageHeader>
                <h5>
                    Pièces d'identité{" "}
                    <IconRight
                        fill="#5E89C2"
                        width="14px"
                        height={14}
                    />{" "}
                    {formatTypeDocument(currentDocument.type)}
                </h5>
            </PageHeader>
            <div className="content__body">
                <IconReturn
                    fill="#5E89C2"
                    width="28px"
                    onClick={handleReturn}
                    className="pointer"
                />
                <div className="row">
                    <div className="col-lg-9">
                        <DocumentDetails document={currentDocument && currentDocument} />
                        <h4>FAQ</h4>
                        {currentFaqs.map((faq, index) => (
                            <Accordion
                                title={faq.question}
                                key={index}>
                                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
                            </Accordion>
                        ))}
                    </div>
                    <div className="col-lg-3">
                        <NotificationsList filter='identity-documents' limit={10}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Document;
