import React, { useContext, useEffect, useState } from "react";

import Button from "../components/Button";
import { Button_Type } from "../components/Button/Button";
import PageHeader from "../components/PageHeader";
import TablePro from "../components/TablePro";
import { UserContext } from "../contexts/UserContext";
import { getVerifyHistoric } from "../api/analysis";
import {useLocation, useNavigate} from "react-router-dom";
import {DashboardProContext} from "../contexts/DashboardProContext";
import DashboardOverview from "./DashboardOverview";

const DashboardPro: React.FC = () => {

    // Initialisation avec la valeur du localStorage (par défaut: true si non défini)
    const [showOverview, setShowOverview] = useState<boolean>(() => {
        // TODO : A décomenter pour faire marcher Video Prsentation
        const storedValue = localStorage.getItem("DashboardOverview");
        return storedValue !== null ? JSON.parse(storedValue) : true;
        // return false;
    });

    const { userType } = useContext(UserContext);
    const { currentVerifications, refreshUserDocumentsVerified } = useContext(DashboardProContext);
    const navigate = useNavigate();
    const location = useLocation(); // Hook pour récupérer l'URL actuelle

    useEffect(() => {
        refreshUserDocumentsVerified();
    }, [location.pathname, userType]);


    const handleAddClick = () => {
        navigate("/professionnels/verifier-document");
    };
    const columns = [
        {
            key: "idType",
            label: "Type de document",
        },
        {
            key: "documentNumber",
            label: "Numéro",
        },
        {
            key: "createdAt",
            label: "Date de vérification",
        },
        {
            key: "controls",
            label: "Statut",
        },
        {
            key: "validity",
            label: "ID Tracker",
        },
    ];
    return (
        <>
            <PageHeader>
                <h5>Vos vérifications</h5>
            </PageHeader>
            {showOverview ?
                (
                    <DashboardOverview
                        setShowOverview={setShowOverview}
                        showOverview={showOverview}
                    />
                )
                :
                (
                    <div className="content__body">
                        <Button
                            label="Vérifier un document"
                            type={Button_Type.SECONDARY}
                            onClick={() => handleAddClick()}
                            className="mb-1"
                        />
                        {currentVerifications && (
                            <TablePro
                                columns={columns}
                                rows={currentVerifications}
                            />
                        )}
                    </div>
                )}
        </>
    );
};

export default DashboardPro;
