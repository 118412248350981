import "./style.scss";

import Button from "../Button";
import { Button_Style } from "../Button/Button";
import { FC } from "react";
import IconError from "../../assets/img/icons/payment/error.svg";
import IconSuccess from "../../assets/img/icons/payment/success.svg";

interface ResultAfterPaymentProps {
    title: string;
    steps: any;
    message: string;
    textBtn?: string;
    paymentSucceeded: boolean;
    handlePreviousStep: () => void;
    handleNextStep: () => void;
}

const ResultAfterPayment: FC<ResultAfterPaymentProps> = ({
    title,
    message,
    paymentSucceeded,
    steps,
    textBtn,
    handlePreviousStep,
    handleNextStep,
}) => {
    return (
        <div className="ResultAfterPayment w-100 h-100 d-flex flex-column align-items-center justify-content-between">
            <div className="w-100 text-center  d-flex flex-column align-items-center gap-05">
                <img
                    src={paymentSucceeded ? IconSuccess : IconError}
                    style={{ width: "15%" }}
                    alt={`status ${paymentSucceeded ? "success" : "error"}`}
                />
                <h2 className="title">{title}</h2>
                <div
                    className="message"
                    data-testid="payment-result-message">
                    {message}
                </div>
            </div>

            <div className={`d-flex justify-content-${!paymentSucceeded ? "center" : "center"} w-100`}>
                {!steps[0].isCurrent && !paymentSucceeded && (
                    <Button
                        label={"Retour au paiement"}
                        onClick={() => handlePreviousStep()}
                        style={Button_Style.OUTLINED}
                        data-testid="back-to-payment-button"
                    />
                )}
                {paymentSucceeded && (
                    <Button
                        disabled={false}
                        label={textBtn ? textBtn : "Accéder à mon profil"}
                        onClick={() => handleNextStep()}
                        data-testid="next-page-button"
                    />
                )}
            </div>
        </div>
    );
};
export default ResultAfterPayment;
