import React, { FC, useContext, useState } from "react";

import AddFilterDocument from "../components/AddFilterDocument";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { Button_Type } from "../components/Button/Button";
import DocumentIllustration from "../components/DocumentIllustration";
import ImageFilter from "../components/ImageFilter";
import PageHeader from "../components/PageHeader";
import { UserContext } from "../contexts/UserContext";
import watermark from "../assets/img/watermark_2.png";
import watermarkPDF from "../assets/img/watermark_1.png";
//import watermarkPDF from "../assets/img/transparent_watermarked.png";

import FilterOverview from "./FilterOverview";
import { ReactComponent as IconFaq } from "../assets/img/icons/icon_Faq.svg";
import WatermarkTextSet from "../components/Watermark/WatermarkTextSet";


const iconProps = {
    fill: "#2f62ae",
    width: "20px",
};

const FilterPage: FC = () => {
    // Initialisation avec la valeur du localStorage (par défaut: true si non défini)
    const [showOverview, setShowOverview] = useState<boolean>(() => {
        // TODO : A décomenter pour faire marcher Video Prsentation
        const storedValue = localStorage.getItem("FilterOverview");
        return storedValue !== null ? JSON.parse(storedValue) : true;
       // return false;
    });
    const [image, setImage] = useState(null);
    const [watermarkText, setWatermarkText] = useState("Document protégé par ID Protect");
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [selectedFilesPDF] = useState<File[]>([]);

    const { userType } = useContext(UserContext);
    const handleChangeFiles = (files: File[]) => {
        setSelectedFiles((prevSelectedFiles) => {
            // Filter out any files that already exist in the prevSelectedFiles array
            const uniqueFiles = files.filter((file) => !prevSelectedFiles.some((prevFile) => prevFile.name === file.name));
            return [...prevSelectedFiles, ...uniqueFiles];
        });
    };


      
      // Convertit un Blob en base64
      function blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64 = (reader.result as string).split(',')[1]; // enlever le prefixe
            resolve(base64);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      }

    const handleWatermarkSubmit = async (text: string) => {
        setWatermarkText(text);
      };
    
    const redirectToExternal = () => {
        window.location.href = "https://app.idprotect.fr/particuliers/register";
    };

    const handleFileRemoval = (file: File) => {
        const updatedFiles = selectedFiles.filter((selectedFile) => selectedFile !== file);
        setSelectedFiles(updatedFiles);
    };

    return (
        <>
            <PageHeader>
                <h5>Ajouter un filigrane</h5>
            </PageHeader>
            {showOverview ?
                (
                    <FilterOverview
                        setShowOverview={setShowOverview}
                        showOverview={showOverview}
                    />
                )
                :
                (
                    <div className="VerifyPage content__body">
                        {/* <h2 className="VerifyPage__title">Ajouter un filigrane</h2> */}
                        <div className="VerifyPage__row row">
                            <div className="VerifyPage__content col-sm-6">
                                <div className="addDocumentForm card">
                                    <div className="addDocumentForm__buttons">
                                        <h3>Protégez vos documents</h3>
                                        <p className="mb-1">Apposez un filigrane ID Protect avant d'envoyer vos
                                            documents.</p>
                                        <Alert type="info">ID Protect ne conserve aucune copie du document.</Alert>
                                        <div className="max-w-xl mx-auto mt-10 p-4">
                                        <h4 className="text-2xl font-bold mb-4">Changer le texte du Filigrane</h4>
                                        <WatermarkTextSet
                                            placeholder="Entrez votre Texte..."
                                            buttonText="Appliquer"
                                            onClick={handleWatermarkSubmit}
                                        />
                                        </div>
                                    </div>
                                        <AddFilterDocument
                                            title=""
                                            onChangeFiles={handleChangeFiles}
                                            onDelete={handleFileRemoval}
                                        />
                                    {!userType && (
                                        <div className="card card__promo">
                                            <h3>Restez informé quand votre document est présenté</h3>
                                            <p className="mb-2">
                                                ID PROTECT vous informe à chaque fois que votre document est présenté
                                                chez ses partenaires : entrée
                                                en relation, ouverture de compte bancaire, demande de crédit …
                                            </p>
                                            <Button
                                                label="Créer un compte"
                                                type={Button_Type.WHITE}
                                                onClick={redirectToExternal}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="col-sm-6 p-1">
                                {(selectedFiles.length > 0 || selectedFilesPDF.length > 0) ? (
                                    <div className="row center">
                                        {selectedFiles.map((file, index) => (
                                            <div
                                                className="col-4"
                                                key={file.name}>
                                                <ImageFilter
                                                    key={watermarkText}
                                                    imageSrc={file}
                                                    watermarkText={watermarkText}
                                                    watermarkSrc={watermark} // filigrane pour les images
                                                    watermarkPDF={watermark} // filigrane pour les PDFs
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <DocumentIllustration/>
                                )}
                            </div>
                        </div>
                        {/*TODO : A décomenter pour faire marcher Video Prsentation*/}
                        <div className="mt-2 mb-1">
                            <div
                                className="d-flex justify-content-center align-items-center gap-05">
                                <u className="pointer"
                                   onClick={() => {
                                       setShowOverview(true);
                                   }}>Découvrez-vous le fonctionnement de notre filigrane</u>
                                <IconFaq {...iconProps} />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default FilterPage;