import "./style.scss";

import { Button_Style, Button_Type } from "../Button/Button";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { auth, firebase } from "../../config/firebase";

import Alert from "../Alert";
import Button from "../Button";
import { register } from "../../api/user";

const ConfirmPhone = ({
    ConfirmationResult,
    showConfirmPhone,
    phoneNumber,
    showConfirmEmail,
    RegistrationData,
    setConfirmEmailMessage,
}: any) => {
    // state variables
    const [successMessage, setSuccessMessage] = useState("");
    const [verificationCode, setVerificationCode] = useState<string[]>(Array(6).fill("")); // The verification  code to use when verifying the OTP input field
    const inputRefs = useRef<Array<HTMLInputElement | null>>(Array(6).fill(null));
    const [recaptcha, setRecaptcha] = useState<firebase.auth.ApplicationVerifier>();
    const [errorMessage, setErrorMessage] = useState("");
    const [showCaptch, setShowCaptch] = useState(false);
    let verifier: any = null;
    useEffect(() => {
        // Clear the error message when the input value changes
        setErrorMessage("");
    }, [inputRefs]);
    useEffect(() => {
        generateCaptcha();
    }, []);
    const generateCaptcha = async () => {
        try {
            if (!verifier) {
                verifier = new firebase.auth.RecaptchaVerifier("recaptcha-container-resend", {
                    "expired-callback": function () {
                        setErrorMessage("Le captcha a expiré. SVP merci de recharger la page et essayer de nouveau.");
                    },
                });
                await verifier.verify();
                setRecaptcha(verifier);
                setErrorMessage("");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const ValidateOtp = async () => {
        try {
            if (verificationCode.join().replace(/,/g, "") === null || ConfirmationResult === null) return;

            await ConfirmationResult.confirm(verificationCode.join().replace(/,/g, ""));
            const response = await register(RegistrationData);
            setConfirmEmailMessage(response.success);
            showConfirmEmail(true);
        } catch (error: any) {
            if (error.code === "auth/code-expired") {
                setErrorMessage("le code envoyé est expiré. SVP merci de le renvoyer pour essayer de nouveau.");
            } else {
                setErrorMessage("Le code est incorrect, SVP Vérifiez le code et ressayez !");
            }
        }
    };

    const ReSendOtp = async () => {
        setShowCaptch(true);
        try {
            setVerificationCode(["", "", "", "", "", ""]);
            setErrorMessage("");
            if (recaptcha) {
                const result = await auth.signInWithPhoneNumber(phoneNumber, recaptcha);
                ConfirmationResult = result;
                setSuccessMessage("Un nouveau code a été envoyé à votre numéro de téléphone");
                //  verifier.clear();
            }
        } catch (error: any) {
            setErrorMessage(error.message);
        }
    };

    const handleInputChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        if (/^\d*$/.test(newValue) && newValue.length <= 1) {
            const newVerificationCode = [...verificationCode];
            newVerificationCode[index] = newValue;
            setVerificationCode(newVerificationCode);

            // Move focus to the next input if available
            if (inputRefs.current[index + 1]) {
                inputRefs.current[index + 1]?.focus();
            }
            // Delete the value if the user presses backspace when its not the firt input
            if (newValue.length === 0 && inputRefs.current[index - 1]) {
                inputRefs.current[index - 1]?.focus();
            } else if (newValue.length === 0 && inputRefs.current[index - 1] === undefined) {
                inputRefs.current[index]?.focus();
            }
        }
    };

    return (
        <>
            <div className="ConfirmPhone">
                <div>
                    <h5>Espace Particulier</h5>
                    <h2>Confirmez votre numéro de téléphone</h2>
                    <p className="mb-1">Nous venons de vous envoyer un SMS avec votre code de vérification.</p>
                    {errorMessage && <Alert type="error">{errorMessage}</Alert>}
                    {successMessage && <Alert type="success">{successMessage}</Alert>}

                    <div className="ConfirmPhone__inputs">
                        {verificationCode.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                className="form-control"
                                maxLength={1}
                                value={digit}
                                onChange={(event) => handleInputChange(index, event)}
                                ref={(input) => (inputRefs.current[index] = input)}
                            />
                        ))}
                    </div>

                    {showCaptch && (
                        <div
                            className="recaptcha-resend"
                            id="recaptcha-container-resend"></div>
                    )}
                </div>
                <div>
                    <Button
                        label="Renvoyer le code"
                        onClick={() => ReSendOtp()}
                        type={Button_Type.LINK}
                        className="m-auto"
                    />
                    <div className="d-flex gap-1">
                        <Button
                            label="Retour"
                            type={Button_Type.PRIMARY}
                            style={Button_Style.OUTLINED}
                            onClick={() => {
                                showConfirmPhone(false);
                            }}
                            className="m-auto"
                        />
                        <Button
                            label="Valider"
                            type={Button_Type.PRIMARY}
                            onClick={() => ValidateOtp()}
                            className="m-auto"
                            disabled={verificationCode.some((digit) => digit === "")}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmPhone;
