import React, {FC, useContext, useEffect} from "react";

import PageHeader from "../components/PageHeader";
import NotificationsList from "../components/NotificationsList";
import Tabs from "../components/Tabs";
import {NotificationLabelTab} from "../types/types";
import {NotificationContext} from "../contexts/NotificationContext";
import {useLocation} from "react-router-dom";


const NotificationAll: FC = () => {
    const {setCurrentNotificationTab,} = useContext(NotificationContext);
    const location = useLocation(); // Hook pour récupérer l'URL actuelle

    useEffect(() => {
        // setCurrentNotificationTab('all');
    }, [location.pathname]);

    const tabs = [
        {
            label: NotificationLabelTab.TOUS,
            notification: false,
            content: (
                <NotificationsList showSetAllAsSeenText={true} notificationTab={"all"} filter='all'/>
            ),
        },
        {
            label: NotificationLabelTab.PIECE_ID,
            notification: false,
            content: (
                <NotificationsList showSetAllAsSeenText={true} notificationTab={"identity-documents"} filter='identity-documents'/>
            ),
        },
        {
            label: NotificationLabelTab.ID_LOCKER,
            notification: false,
            content: (
                <NotificationsList showSetAllAsSeenText={true} notificationTab={"id-locker"} filter='id-locker'/>
            ),
        },
        {
            label: NotificationLabelTab.ID_ASSISTANT,
            notification: false,
            content: (
                <NotificationsList showSetAllAsSeenText={true} notificationTab={"id-assistant"} filter='id-assistant'/>
            ),
        },

    ];

    const handleNotification = (label: string)=> {
        if (label == NotificationLabelTab.PIECE_ID) {
            setCurrentNotificationTab('identity-documents');
        }else if (label == NotificationLabelTab.ID_LOCKER) {
            setCurrentNotificationTab('id-locker');
        }else if (label == NotificationLabelTab.ID_ASSISTANT) {
            setCurrentNotificationTab('id-assistant');
        } else if (label == NotificationLabelTab.TOUS){
            setCurrentNotificationTab('all');
        }
    }

    return (
        <div className="NotificationAll">
            <PageHeader>
                <h4>TOUTES LES NOTIFICATIONS</h4>
            </PageHeader>
            <div className="content__body">
                <Tabs tabs={tabs} onChangeTab={handleNotification}/>

                <div className="col-xl-3">

                </div>
            </div>
        </div>
    );
};
export default NotificationAll;
