export type IDType = {
    number: string;
    type: string;
    creationDate: string;
    status: string;
    isBlocked: boolean;
    id: number;
    nationality: string;
};

export type NotificationType = {
    to: string;
    from: string;
    action: string;
    detail: string;
    createdAt: string;
    updatedAt: string;
    isRead?: boolean;
    id: number;
    documentId: number | null;
    ownerId: number;
    seen: boolean;
};

export type DocumentUnion = FollowedDocument | LostDocument;

export type FollowedDocument = {
    createdAt: string;
    id: number;
    isBlocked?: boolean;
    isSelectedOne: boolean;
    locked?: boolean;
    lost?: boolean;
    number: string;
    stateDoc?: string;
    status?: string;
    type: string;
    updatedAt: string;
    userId: number;
    conformite?: boolean;
    darkWeb?: boolean;

};

export type LostDocument = {
    clientNumber?: string;
    createdAt: string;
    documentIdPath?: string;
    id: number;
    isBlocked?: boolean;
    isSelected: boolean;
    locked?: boolean;
    lost?: boolean;
    number: string;
    pvPath?: string;
    reason?: string;
    stateDoc: string;
    status?: string;
    type: string;
    typeDemande: string;
    conformite?: boolean;
    darkWeb?: boolean;
};

export type DocumentBasylic = {
    id?: number;
    number?: string;
    numeroDocument: string;
    nom: string;
    prenom: string;
    dateNaissance: string;
    paysNaissance: string;
    sexe: "M" | "F";
    typeDocument: string;
    dateExpiration: string;
    dateDelivrance: string;
    nomUsage: string;
    viz: string;
    mrz: string;
    conformite: boolean;
    darkWeb: boolean;
    locked?: boolean;
    lost?: boolean;
    country_code?: string;
};
export function isDocumentBasylic(document: any): document is DocumentBasylic {
    return (document as DocumentBasylic).nom !== undefined;
}

export type DocumentPreviewNumberType = {
    numeroDocument: string;
    typeDocument: string;
    country: string;
    status: string;
    darkWeb: boolean;
};

export type AdminUser = {
    type: "admin";
    // Autres propriétés spécifiques à l'administrateur
};

export type IndividualUser = {
    type: "individual";
    // Autres propriétés spécifiques à l'utilisateur individuel
};

export type PartnerUser = {
    type: "partner";
    // Autres propriétés spécifiques à l'utilisateur partenaire
};

export type UserType = AdminUser | IndividualUser | PartnerUser;

export type User = {
    clientNumber: string;
    companyName: null;
    createdAt: string;
    credits: number;
    email: string;
    firstname: string;
    id: string;
    lastConnected: string;
    lastname: string;
    phoneNumber: string;
    sizeSpace: string;
    sizeSpaceUsed: string;
    suspended: boolean;
    type: string;
    updatedAt: string;
    picture?: any;
};

export type DocumentStatusType = {
    conformite?: boolean | undefined;
    lost?: boolean | undefined;
    locked?: boolean | undefined;
    darkWeb?: boolean | undefined;
    idTracker?: "requested" | "confirm" | "notRequested" | "reject";
};

export type ErrorType = {
    status: number;
    message: string;
};

export type FolderType = {
    id: number;
    name: string;
    visibility: boolean;
    system: boolean;
    adminAccess: boolean;
    ownerId: number;
    createdAt: string;
    updatedAt: string;
    documents: any[];
    authorizations: Array<AuthorizationType>;
    items?: Array<ProceduresType>;
};

export type AuthorizationType = {
    client: PartnerType;
    clientId?: number;
    id: number;
    isAuthorized: boolean;
};

export type FileType = {
    id?: number;
    name: string;
    path: string;
    receivedAt?: string;
    status?: string;
};
export type OrganismType = {
    name: string;
    logoPath: string;
};

export type ProceduresType = {
    id: number;
    title: string;
    description: string;
    checked: boolean;
    folderId: number;
    createdAt: Date;
    updatedAt: Date;
};

export type PartnerType = {
    client?: PartnerType;
    id: number;
    clientNumber: string;
    companyName: null | string;
    expirationDate: string;
    firstname: string;
    lastname: string;
    phoneNumber: string;
    authorizationId: number;
    picture?: string;
};

export type Options = {
    value: string;
    label: string;
};

export type AccompanimentType = {
    accompaniementContacts: any[];
    accompanimentClientDocuments: any[];
    documents_ids: number[];
    createdAt: string;
    id: number;
    ownerId: number;
    status: string;
    updatedAt: string;
    dateReceptionAR: string | null | undefined;
};


export enum NOTIFICATION_ACTIONS {
    // For Tab [Pièces d'identité] in the web app
    Document_View_Request = 'document_view_request',
    Document_View_Request_New_Status = 'document_view_request_new_status',
    Document_Lost_Declaration = 'document_lost_declaration',
    Document_Delete = 'document_delete',
    Folder_Notification = 'folder_notification',
    Document_Delete_Followed = 'document_delete_followed',
    Document_New_Followed = 'document_new_followed',
    Document_Change_Followed_State = 'document_change_followed_state',

    // For Tab [ID Locker] in the web app
    Folder_Creation = 'folder_creation',
    Folder_Delete = 'folder_delete',
    File_Added = 'file_added',
    File_Delete = 'file_delete',
    Invitation_Send = 'invitation_send',
    Permission_Deleted = 'permission_deleted',
    Permission_Changed = 'permission_changed',
    Visibility_Changed = 'visibility_changed',
    Expired_Date_Changed = 'expired_date_changed',

    // For Tab [ID Assistant] in the web app
    Accompaniment_New_Status = 'accompaniment_new_status',
    Accompaniment_New = 'accompaniment_new',
    Accompaniment_Delete = 'accompaniment_delete',
}

export enum NotificationLabelTab {
    PIECE_ID = "Pièces d'identité",
    ID_ASSISTANT = "ID Assistant",
    ID_LOCKER = "ID Locker",
    TOUS = "Tous",
}