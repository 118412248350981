import DocumentIllustration from "../components/DocumentIllustration";
import VerifyDatabaseDocument from "../components/VerifyDatabaseDocument";
import { useState } from "react";
import IDTrackerPricing from "./IDTrackerPricing";

export default function DatabaseDocuments() {
    const [type, setType] = useState<string>("");
    const [verificationDone, setVerificationDone] = useState<boolean>(false);

    const handleChangeType = (type: string) => {
        setType(type);
    };
    return (
        <div className="content__body h-100">
            <div className="row h-100 align-items-center">
                <div className={`${verificationDone ? 'col-sm-5' : 'col-sm-6'}`}>
                    <div className="card h-100">
                        <VerifyDatabaseDocument
                            type={type}
                            setType={handleChangeType}
                            setVerificationDone={setVerificationDone}
                            verificationDone={verificationDone}
                        />
                    </div>
                </div>
                <div
                    className={`${verificationDone ? 'col-sm-7' : 'col-sm-6'} d-flex justify-content-center align-items-center`}
                >
                    {!verificationDone && (
                        <DocumentIllustration type={type} />
                    )}
                    {verificationDone && (
                        <IDTrackerPricing isPage={false}/>
                    )}

                </div>
            </div>
        </div>
    );
}
