import { ReactNode, createContext, useContext, useEffect, useState } from "react";

import { UserContext } from "./UserContext";
import {getVerifyHistoric} from "../api/analysis";

const DashboardProContext = createContext<{
    currentVerifications: never[];
    refreshUserDocumentsVerified: () => void;
}>({
    currentVerifications: [],
    refreshUserDocumentsVerified: () => {},
});

const DashboardProContextProvider = ({ children }: { children: ReactNode }) => {
    const { userType } = useContext(UserContext);
    const [currentVerifications, setCurrentVerifications] = useState([]);

    const getUserVerifications = async () => {
        if (!userType) return;
        try {
            const request = await getVerifyHistoric(userType);
            setCurrentVerifications(request.data.historiques);
            return currentVerifications;
        } catch (error: any) {
            console.log(error.response);
        }
    };

    useEffect(() => {
        getUserVerifications();
    }, [userType]);

    const refreshUserDocumentsVerified = async () => {
        await getUserVerifications();
    };

    return <DashboardProContext.Provider value={{ currentVerifications, refreshUserDocumentsVerified }}>{children}</DashboardProContext.Provider>;
};

export { DashboardProContext, DashboardProContextProvider };
