import { User } from "../types/types";


const subjects: any = {
    modify: {
        text: "Modification de mon compte IDProtect",
        body: "Bonjour, Je souhaite modifier les informations de mon compte ID Protect.",
    },
    cancel: {
        text: "Résiliation de mon abonnement IDProtect",
        body: "Bonjour, Je souhaite résilier mon abonnement.",
    },
    buyCredits: {
        text: "Achat de crédits de vérification IDProtect",
        body: "Bonjour, Je souhaite acheter des crédits de vérification.",
    },
};

export const mailTo = (subject: string, user?: User | null) => {
    if (user) {
        const userInfos = `\n-----------\n ${user?.firstname} ${user?.lastname} \n ${user?.email} \n ${user?.phoneNumber}`;
        const mail = "contact@idprotect.fr";
        window.location.href = `mailto:${mail}?subject=${subjects[subject].text}&body=${
            subjects[subject].body + encodeURIComponent(userInfos)
        }`;
    }
};
