import './style.scss';

import React, { FC, useState } from 'react';

type HeaderSectionProps = {
  title: string;
  number?: number | string;
  progress?: number;
};
const HeaderSection: FC<HeaderSectionProps> = ({ title, number, progress }) => {
  return (
    <div className="HeaderSection">
      <span className='HeaderSection__title'>{title}</span>
      {progress ?
        <div className="HeaderSection__progress-outer">
            <div className="HeaderSection__progress-container">
                <div className="HeaderSection__progress-bar" style={{ width: `${progress}%` }}></div>
            </div>
        </div> : <></>
      }
      {number && <span className={`HeaderSection__number ${number.toString().includes("%") && 'HeaderSection__number-success'}`}>{number}</span>}
    </div>
  );
}

export default HeaderSection;
