import React, {FC, useContext, useState} from "react";

import { UserContext } from "../contexts/UserContext";
import Button from "../components/Button";
import {Button_Style, Button_Type} from "../components/Button/Button";
import { ReactComponent as IconBack } from "../assets/img/icons/icon_arrow-Right.svg";
import VideoFrame from "../components/VideoFrame";
import Checkbox from "../components/Checkbox";

type DocumentsOverViewProps = {
    showOverview: boolean;
    setShowOverview: any;
};


const DocumentsOverview: FC<DocumentsOverViewProps> = ({
      showOverview = true,
      setShowOverview,
}) => {
    const { userType } = useContext(UserContext);

    const [selectedNeverShowAgain, setSelectedNeverShowAgain] = useState(false);

    const handleStartDashBoard = () => {
        setShowOverview(false);
        localStorage.setItem("DocumentsOverview", JSON.stringify(!selectedNeverShowAgain));
    };

    const checkSelect = () => {
        const newSelect = !selectedNeverShowAgain;
        setSelectedNeverShowAgain(newSelect);
    };

    return (
        <>
            <div className="DocumentsOverview">
                <div className="content__body">
                    <div className="row flex-wrap">
                        <div className={`col-sm-5`}>
                            <div className="content--card-2 relative mb-2">
                                <div className="mb-2 text-secondary">
                                    Détecter rapidement une utilisation frauduleuse de ses documents est essentiel
                                    pour prévenir les usurpations d’identité. Avec <b>ID Tracker</b>,
                                    soyez alerté en temps réel en cas d’activité suspecte liée à vos documents :
                                    <ul className="mt-1">
                                        <li><b>ID Tracker</b> : Recevez une alerte dès qu’un de vos documents d’identité
                                            est utilisé, pour réagir rapidement et éviter toute fraude.
                                        </li>
                                    </ul>
                                    <div className="mt-1">
                                        <b>Cas d’usage</b>
                                        <ul>
                                            <li>Détection de tentatives d’ouverture de compte frauduleuses avec vos documents.
                                            </li>
                                            <li>Alerte en cas d’utilisation suspecte de votre identité en ligne ou en magasin.
                                            </li>
                                            <li>Réaction rapide pour limiter les conséquences d’une fraude en cours.
                                            </li>
                                            <li>Surveillance continue sur le Dark Web <b>(payant)</b>.
                                            </li>
                                        </ul>
                                    </div>
                                    Avec <b>ID Tracker</b>, gardez le contrôle sur vos documents et protégez-vous contre l’usurpation d’identité
                                </div>
                                <div className="d-flex flex-column justify-content-between align-items-center">
                                    <Checkbox
                                        label="Ne plus afficher cette page"
                                        onChange={checkSelect}
                                        checked={selectedNeverShowAgain}
                                    />
                                    <Button
                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                        label="Pièces d'identité"
                                        style={Button_Style.SOLID}
                                        onClick={() => handleStartDashBoard()}
                                        icon={<IconBack
                                            className="PageHeader__icon"
                                            fill={"#9FC1EF"}
                                        />}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="mt-3">
                                <VideoFrame
                                    url="https://www.youtube.com/watch?v=01yuOjLWPHs"
                                >
                                    <span className="text-primary bold">ID Tracker : </span>
                                    <span className="text-danger"> Découvrez le fonctionnement notre outil</span>
                                </VideoFrame>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default DocumentsOverview;