import "./assets/scss/style.scss";

import { FolderContextProvider } from "./contexts/FoldersContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserContext, UserContextProvider } from "./contexts/UserContext";
import { useContext, useEffect } from "react";

import { AccompaniementsContextProvider } from "./contexts/AccompaniementsContext";
import { BrowserRouter } from "react-router-dom";
import ConditionalRoutes from "./ConditionalRoutes";
import { DocumentsContextProvider } from "./contexts/DocumentsContext";
import { Worker } from "@react-pdf-viewer/core";
import {DashboardProContextProvider} from "./contexts/DashboardProContext";
import {NotificationContextProvider} from "./contexts/NotificationContext";

const queryClient = new QueryClient();
function App() {
    const { userType } = useContext(UserContext);

    useEffect(() => {
        document.body.classList.add(userType ? userType : "individual");
    }, [userType]);

    useEffect(() => {
        let scrollTimeout: NodeJS.Timeout;
        const handleScroll = () => {
            document.body.classList.add("active");
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(() => {
                document.body.classList.remove("active");
            }, 1000);
        };
        window.addEventListener("scroll", handleScroll, true);
        return () => {
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                <BrowserRouter>
                    <UserContextProvider>
                        <NotificationContextProvider>
                            <DashboardProContextProvider>
                                <AccompaniementsContextProvider>
                                    <DocumentsContextProvider>
                                        <FolderContextProvider>
                                            <ConditionalRoutes />
                                        </FolderContextProvider>
                                    </DocumentsContextProvider>
                                </AccompaniementsContextProvider>
                            </DashboardProContextProvider>
                        </NotificationContextProvider>
                    </UserContextProvider>
                </BrowserRouter>
            </Worker>
        </QueryClientProvider>
    );
}

export default App;
