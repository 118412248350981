import "./style.scss";

import {FC, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import Alert from "../Alert";
import Button from "../Button";
import {Button_Style, Button_Type} from "../Button/Button";
import Input from "../Input";
import {UserContext} from "../../contexts/UserContext";
import {login} from "../../api/auth/index";


const LoginForm: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [userType, setUserType] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { user } = useContext(UserContext);

    const location = useLocation();
    useEffect(() => {
        // Check if the URL contains "professionnels"
        const hasProfessionnels = location.pathname.includes("professionnels");
        setUserType(hasProfessionnels ? "professionnels" : "particuliers");
    }, [location]);

    const handleSubmit = async (e: React.FormEvent) => {
        setIsLoading(true);
        try {
            e.preventDefault();

            const request: any = await login(email, password);
            setIsLoading(false);

            if (request.data?.type === "individual") {
                navigate("/particuliers/dashboard");
            } else if (request.data?.type === "partner") {
                navigate("/professionnels/dashboard");
            }
            else {
                //console.log(request.response.data.error)
                setErrorMessage(request.response.data.error);
            }
        } catch (error: any) {
            setIsLoading(false);
            if (error.request.response?.startsWith("<")) {
                setErrorMessage("Le serveur est indisponible. Merci de réessayer plus tard.");
            } else {
                const errorText = JSON.parse(error.request.response).error;
                setErrorMessage(errorText);
            }
        }
    };

    const handleRedirect = (): any => {
        navigate(`/${userType}/register`);
    };

    const handleForgetPassword = (): any => {
        navigate("/resend");
    };

    const handleConfirmationEmail = (): any => {
        navigate("/commons/confirmation");
    };

    return (
        <>
            <div>
                <h5>Espace {userType}</h5>
                <h2>Connexion</h2>
                {errorMessage && <Alert type="error">{errorMessage}</Alert>}

                <Input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    data-testid="email-input"
                />

                <Input
                    type="password"
                    name="password"
                    placeholder="Mot de passe"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    data-testid="password-input"
                />
            </div>
            <div className="mt-auto">
                <Button
                    label="Connexion"
                    type={userType === "professionnels" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                    className="m-auto mb-1"
                    onClick={(e) => handleSubmit(e)}
                    data-testid="connexion-button"
                    isLoader={isLoading && !user}
                />
                <Button
                    label="S'inscrire"
                    type={Button_Type.LABEL}
                    className="m-auto mb-1"
                    onClick={() => handleRedirect()}
                />
                <Button
                    label="Mot de passe oublié"
                    type={Button_Type.LINK}
                    className="m-auto"
                    onClick={() => handleForgetPassword()}
                />
                <Button
                    label="Confirmation de votre adresse email?"
                    type={Button_Type.LINK}
                    className="m-auto mb-1"
                    onClick={(e) => handleConfirmationEmail()}
                />
            </div>
        </>
    );
};

export default LoginForm;
