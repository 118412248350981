import { useMutation, useQuery, useQueryClient } from "react-query";

import axios from "../../axiosConfig";

export async function createAccompaniment(data) {

    try {
        const body = new FormData();
        body.append("type",data.type);
        body.append("adresse",data.adresse);
        body.append("city",data.city);
        body.append("postalCode",data.postalCode);
        data.documents_ids.forEach((item) => {
            body.append('documents_ids', item); // `item` is a string/number
        });
        body.append("comment",data.comment);
        data.filesToUpload.forEach((item) => {
            body.append('filesToUpload', item); // `file` is a `File`
        });
        const response = await axios.post("/client/accompaniment", body, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
        return response;
    } catch (error) {
        return error;
    }
}

export function useCreateAccompaniment() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data) => {

            const body = new FormData();
			body.append("type",data.type);
			body.append("adresse",data.adresse);
			body.append("city",data.city);
			body.append("postalCode",data.postalCode);
            data.documents_ids.forEach((item) => {
                body.append('documents_ids', item); // `item` is a string/number
            });
			body.append("comment",data.comment);
            data.filesToUpload.forEach((item) => {
                body.append('filesToUpload', item); // `file` is a `File`
            });
            const response = await axios.post("/client/accompaniment", body, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries("accompaniment");
        },
    });
}

export async function getAccompaniment(type) {
    const body = new FormData();
    body.append("type", type);
    try {
        const response = await axios.post("/client/accompaniment/list", body);
        return response.data;
    } catch (error) {
        return error;
    }
}

export async function addDocumentToAccompaniment(type, files) {
    try {
        const data = new FormData();
        data.append("type", type);
        data.append("filesToUpload", new File([files], files.name));
        const response = await axios.put("/client/accompaniment/document", data);
        if (response) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
    }
}

export async function removeDocumentFromAccompaniment(type, id) {
    try {
        const response = await axios.post(`/client/accompaniment/document/${id}`, { type });
        return response;
    } catch (error) {
        return error;
    }
}

export function useDeleteAccompaniment(type, id) {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async () => {
			const response = await axios.delete(`/client/accompaniment/${id}`, { data: { type } });
			return response;
		},
		onSuccess: () => {
			queryClient.invalidateQueries("accompaniment");
		},
	});
}
