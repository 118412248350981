import { useMutation } from 'react-query';

import axios from '../../axiosConfig';

export async function getVerifationToken(type) {
		const url = '/client/getShareIdToken';
		const data = new FormData();
		data.append('type',type);
		return axios.post(url,data);
}
//sdfsdf
export async function getVerificationResult(type,uuid) {
	const url = 'client/getDataByUUID';
	const data = new FormData();
	data.append('type',type);
	data.append('uuid',uuid);

	return axios.post(url,data);
}

export async function getDocumentHighestAnalysisEver(userType, files, token) {
	const url = '/client/verifyPhoto';
	const formData = new FormData();
	formData.append('type', userType);

	// Ajout des fichiers (Recto / Verso ou unique)
	files.forEach(file => {
		formData.append('photos', file);
	});

	try {
		const response = await axios.post(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `Bearer ${token}` // Le TOKEN shareID
			}
		});
		// console.log(response.data)
		return response.data.externalApiResponse;
	} catch (error) {
		console.error("Erreur lors de l'appel à l'API: '/client/verifyPhoto'", error);
		throw error;
	}

}


export async function getDocumentAnalysisFile(type,files) {
	const url = 'client/ariadnext/analysis';
	const formData = new FormData();
	formData.append('type',type);
	formData.append('domain','PRODUCTION')
	formData.append('frenshDocument',true)
	if (files.length === 1) {
		formData.append('recto',files[0]);
	} else {
		formData.append('recto',files[0]);
		formData.append('verso',files[1]);
	}
	return axios.post(url,formData);
}

export async function generateReportWithShareIDVideo(userType, contenuReponseShareID, applicantIdShareID) {
	const url = 'client/generateReport';
	const formData = new FormData();

	formData.append('type', userType);
	// Ajout des données sous forme de JSON stringifié
	formData.append('data', JSON.stringify(contenuReponseShareID?.document));
	formData.append('uid', applicantIdShareID);

	const base64Recto = contenuReponseShareID?.doc_front ?? null;
	const base64Verso = contenuReponseShareID?.doc_front ?? null;

	// Ajout des fichiers recto et verso si disponibles
	if (base64Recto) {
		formData.append('base64_recto', base64Recto);
	}
	if (base64Verso) {
		formData.append('base64_verso', base64Verso);
	}

	try {
		const response = await axios.post(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		if (response.status === 200) {
			return response.data; // PDF généré avec succès
		}

		if (response.status === 400) {
			throw new Error("Requête invalide : informations manquantes ou invalides.");
		}

	} catch (error) {
		if (error.response) {
			if (error.response.status === 400) {
				throw new Error(error.response.data.message || "Données invalides.");
			} else if (error.response.status === 500) {
				throw new Error("Une erreur interne est survenue lors de la génération du PDF.");
			}
		}
		throw new Error("Impossible de contacter le serveur.");
	}
}

export async function generateReportWithShareIdPhoto(userType, files, contenuReponseShareID, applicantIdShareID) {
	const url = 'client/generateReport';
	const formData = new FormData();

	formData.append('type', userType);
	// Ajout des données sous forme de JSON stringifié
	formData.append('data', JSON.stringify(contenuReponseShareID));
	formData.append('uid', applicantIdShareID);

	// Ajout des fichiers recto et verso si disponibles
	if (files.length >= 1) {
		formData.append('file_recto', files[0]);
	}
	if (files.length === 2) {
		formData.append('file_verso', files[1]);
	}

	try {
		const response = await axios.post(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});

		if (response.status === 200) {
			return response.data; // PDF généré avec succès
		}

		if (response.status === 400) {
			throw new Error("Requête invalide : informations manquantes ou invalides.");
		}

	} catch (error) {
		if (error.response) {
			if (error.response.status === 400) {
				throw new Error(error.response.data.message || "Données invalides.");
			} else if (error.response.status === 500) {
				throw new Error("Une erreur interne est survenue lors de la génération du PDF.");
			}
		}
		throw new Error("Impossible de contacter le serveur.");
	}
}


export async function createFile(url) {
	const urlAPI = 'client/shareId/getFile';
	const link = urlAPI+"?path="+url
	return axios.get(link);
}

export async function getVerifyHistoric(type) {
	const url = 'client/ariadnext/historiques'; // Cette URL n'a rien avoir avec ARIADNEXT
	const formData = new FormData();
	formData.append('type',type);
	return axios.post(url,formData);
}


export function useGetDarkWebDocument() {
	return useMutation({
        mutationFn: async ({ number }) => {
            const body = new FormData();
            body.append("number", number);
            const response = await axios.post("client/searchDarkDocuments", body);
            return response.data;
        },
    });
}
