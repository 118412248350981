import { DocumentUnion, FollowedDocument, LostDocument } from "../types/types";
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";

import { NotificationType } from "../types/types";
import { UserContext } from "./UserContext";
import { getFollowedDocuments } from "../api/followed";
import {fetchNotificationsForTab, getNotifications} from "../api/notifications";
import { unseenNotifications } from "../api/notifications";
import {getLostDocuments, useLostDocuments} from "../api/lost";

const DocumentsContext = createContext<{
    followed: FollowedDocument[];
    lostDocuments: LostDocument[];
    allDocuments: DocumentUnion[];
    notifications: NotificationType[] | null;
    notificationsUnseen: number;
    updateDocument: (documentNumber: string, updatedDocument: any) => void;
    refreshDocuments: () => void;
    refreshOnlyTheDocuments: () => void;
    fetchNotificationsByActions: (tab: string, limit?: number) => void;
    refreshAllNotifications: () => void;
}>({
    followed: [],
    lostDocuments: [],
    allDocuments: [],
    notifications: [],
    notificationsUnseen: 0,
    updateDocument: () => {},
    refreshDocuments: () => {},
    refreshOnlyTheDocuments: () => {},
    fetchNotificationsByActions: (tab: string, limit?: number) => {},
    refreshAllNotifications: () => {},
});

const DocumentsContextProvider = ({ children }: { children: ReactNode }) => {
    const [followed, setFollowed] = useState<FollowedDocument[]>([]);
    const [lostDocuments, setLostDocuments] = useState<LostDocument[]>([]);
    const [notifications, setNotifications] = useState<any[]>([]);
    const [notificationsUnseen, setUnseenNotifications] = useState<number>(0);
    const { userType } = useContext(UserContext);
    const { data: documentsLost, error: errorLostDocuments } = useLostDocuments(userType);

    const fetchNotifications = async (limit?: number) => {
        try {
            const data = await getNotifications("individual", limit);
            setNotifications(data.notifs);
            setUnseenNotifications(data.unseenCount);
            return notifications;
        } catch (e) {
            console.log(e);
        }
    };

    const fetchNotificationsByActions = async (tab: string, limit?: number) => {
        try {
            const data = await fetchNotificationsForTab(tab, limit);
            setNotifications(data.notifs);
            setUnseenNotifications(data.unseenCount);
            return notifications;
        } catch (e) {
            console.log(e);
        }
    };

    const fetchFollowed = async () => {
        try {
            const request = await getFollowedDocuments("individual");
            const mapFollowedDocuments = request.map((followed: FollowedDocument): DocumentUnion => {
                let statusText;
                if (followed.locked) {
                    statusText = "Verrouillé";
                } else if (followed.lost) {
                    statusText = "Expiré";
                } else {
                    statusText = "Actif";
                }

                return {
                    ...followed,
                    status: followed.status || followed.stateDoc,
                    isSelectedOne: false,
                    isBlocked: followed.locked,
                    lost: false,
                    stateDoc: "SUIVI",
                };
            });
            setFollowed(mapFollowedDocuments);
            return followed;
        } catch (e) {
            console.log(e);
        }
    };

    const fetchLostDocuments = async () => {
       /* if (errorLostDocuments) {
            console.log(errorLostDocuments);
        }*/
        try {
            const request = await getLostDocuments(userType);

            const mapLostDocuments = request.map((lostDocument: LostDocument): DocumentUnion => {
                return {
                    ...lostDocument,
                    number: lostDocument.number,
                    type: lostDocument.type,
                    isBlocked: true,
                    lost: true,
                    isSelectedOne: false,
                    id: lostDocument.id,
                    status: lostDocument.status || lostDocument.stateDoc,
                };
            });
            setLostDocuments(mapLostDocuments);
            return lostDocuments;
        }
        catch (e) {
            console.log(e);
        }
    };

    const fetchUnseenNotifications = async () => {
        try {
            const request = await unseenNotifications(userType);
            setUnseenNotifications(request.data.notifs);
        } catch (e) {
            console.log(e);
        }
    };


    useEffect(() => {
        if (userType === "individual") {
            // Si l'utilisateur n'est pas un partenaire
            console.log("userType", userType);
            const fetchData = async () => {
                try {

                    await Promise.all([fetchFollowed(), fetchLostDocuments()]);
                } catch (e) {
                    console.log(e);
                }
            };

            fetchData();
        }
    }, [userType, documentsLost]);

    // Utilisation de useMemo pour mettre à jour allDocuments lorsque followed ou lostDocuments changent
    const allDocuments = useMemo(() => [...followed, ...lostDocuments], [followed, lostDocuments]);

    // Ajoutez la fonction refreshDocuments pour mettre à jour les documents
    const refreshDocuments = async () => {
        await Promise.all([fetchFollowed(), fetchLostDocuments()]);
    };

    // Ajoutez la fonction refreshDocuments pour mettre à jour les documents
    const refreshAllNotifications = async () => {
        await Promise.all([fetchNotifications()]);
    };


    const refreshOnlyTheDocuments = async () => {
        await Promise.all([fetchFollowed(), fetchLostDocuments()]);
    };

    const updateDocument = async (documentNumber: string, updatedDocument: any) => {
        // Vérifier si le document est dans la liste followed
        const updatedFollowed = followed.map((followedDoc) => {
            if (followedDoc.number === documentNumber) {
                return {
                    ...updatedDocument,
                    stateDoc: updatedDocument.stateDoc, // Mettez la nouvelle valeur de stateDoc ici
                };
            } else {
                return followedDoc;
            }
        });
        setFollowed(updatedFollowed);

        // Vérifier si le document est dans la liste lostDocuments
        const updatedLostDocuments = lostDocuments.map((lostDocument) => {
            if (lostDocument.number === documentNumber) {
                return {
                    ...updatedDocument,
                    stateDoc: updatedDocument.stateDoc, // Mettez la nouvelle valeur de stateDoc ici
                };
            } else {
                return lostDocument;
            }
        });
        setLostDocuments(updatedLostDocuments);
    };

    const value = {
        followed,
        lostDocuments,
        allDocuments,
        updateDocument,
        notifications,
        refreshDocuments,
        notificationsUnseen,
        refreshOnlyTheDocuments,
        fetchNotificationsByActions,
        refreshAllNotifications
    };

    return <DocumentsContext.Provider value={value}>{children}</DocumentsContext.Provider>;
};

export { DocumentsContext, DocumentsContextProvider };
