import { AccompanimentType, FileType } from "../types/types";
import { useContext, useEffect, useState } from "react";

import { AccompaniementsContext } from "../contexts/AccompaniementsContext";
import DocsPerOrganisms from "../components/DocsPerOrganisms";
import { DocumentUnion } from "../types/types";
import File from "../components/File";
import FileUploader from "../components/FileUploader";
import HeaderSection from "../components/HeaderSection";
import PageHeader from "../components/PageHeader";
import StatusAccompagnement from "../components/StatusAccompagnement";
import Table from "../components/Table";
import { UserContext } from "../contexts/UserContext";
import { addDocumentToAccompaniment } from "../api/accompaniment";
import { useLostDocumentsByAccompanimentId} from "../api/lost";
import { useParams } from "react-router-dom";

const columns = [
    { key: "nationality", label: "" },
    { key: "type", label: "Type de document" },
    { key: "number", label: "Numéro" },
    { key: "createdAt", label: "Date de l'ajout" },
    { key: "status", label: "Statut" },
    { key: "isBlocked", label: "" },
];

const IDAssistantDetail = () => {
    let { id } = useParams();

    const [selectedRows, setSelectedRows] = useState<DocumentUnion[]>([]);
    const { accompaniments } = useContext(AccompaniementsContext);
    const { userType } = useContext(UserContext);
    const [currentAccompaniment, setCurrentAccompaniment] = useState<AccompanimentType>();
    const { data: documentsLost, error: errorLostDocuments } = useLostDocumentsByAccompanimentId(userType, id);
    const [accompanimentDocuments, setAccompanimentDocuments] = useState<DocumentUnion[]>([]);

    const fileUploaderProps = {
        className: "mb-0",
        title: "Ajouter un procès verbal de perte ou de vol",
        allowedFormats: "application/pdf, image/jpeg, image/png",
        onDelete: () => console.log("delete"),
        onChange: (files: any) => handleAddDocument(files),
        isList: false,
    };

    useEffect(() => {
        if (!id || !accompaniments) return;
        // console.log(id);
        setCurrentAccompaniment(accompaniments.find((item) => item?.id?.toString() === id));
    }, [id, accompaniments]);

    useEffect(() => {
        if (!documentsLost) return;
        setAccompanimentDocuments(documentsLost);
        // console.log(documentsLost);
    }, [documentsLost, documentsLost?.documents_ids]);

    const handleSelectionChange = (rows: DocumentUnion[]) => {
        setSelectedRows(rows);
    };

    const handleAddDocument = async (files: any) => {
        try {
            const response = await addDocumentToAccompaniment(userType, files[0]);
            // console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    const handleFilePath = (file: FileType) => {
        const { path } = file;
        const splitPath = path.split("/");
        const clientNumber = splitPath[0];
        const accompanimentId = splitPath[splitPath.length - 2];
        const fileName = splitPath[splitPath.length - 1];
        ///client/getAcconpagnementFile/:clientNumber/:accompanimentId/:fileName
        return `client/getAcconpagnementFile/${clientNumber}/${accompanimentId}/${fileName}`;
    };

    return (
        <>
            <PageHeader>
                <h5>ID Assistant/{id}</h5>
            </PageHeader>
            <div className="content__body">
                <div className="row">
                    <div className="col-lg-12">
                        {currentAccompaniment?.status && <StatusAccompagnement status={currentAccompaniment?.status} />}
                        <HeaderSection
                            title="Documents perdus/volés dans cet accompagnement"
                            // number=""
                        />
                        <Table
                            isClickable={false}
                            selectable={false}
                            columns={columns}
                            rows={accompanimentDocuments}
                            onSelectionChange={handleSelectionChange}
                            selectedRows={selectedRows}
                            editable={false}
                        />

                        <DocsPerOrganisms organisms={currentAccompaniment?.accompaniementContacts} />
                        <HeaderSection
                            title="Ma déclaration de perte ou de vol"
                            // number="1"
                        />
                        {currentAccompaniment?.accompanimentClientDocuments &&
                        currentAccompaniment?.accompanimentClientDocuments.length > 0 ? (
                            <File file={currentAccompaniment?.accompanimentClientDocuments[0]} />
                        ) : (
                            <FileUploader {...fileUploaderProps} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default IDAssistantDetail;
