import './style.scss';

import { FC } from 'react';
import ReactPlayer from 'react-player/youtube'

type VideoFrameProps = {
    url?: string;
    prevImg?: any;
    children?: React.ReactNode;
};

const VideoFrame: FC<VideoFrameProps> = ({
                                             children,
                                             url,
                                             prevImg}) => {

    window.addEventListener('error', e => {
        // prompt user to confirm refresh
        if (/Loading chunk [\d]+ failed/.test(e.message)) {
            window.location.reload();
        }
    });

    return (
        <div className="VideoFrame">
            <ReactPlayer
                className="custom-card"
                url={url}
                width="97%"
                height="280px"
                controls={true}
            />
            <div className="p-1">
                {children}
            </div>
        </div>
    );
}

export default VideoFrame;