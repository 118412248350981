import "./style.scss";

import { Button_Style, Button_Type } from "../Button/Button";
import { DocumentBasylic, DocumentStatusType } from "../../types/types";
import { FC, useContext, useEffect, useState } from "react";
import { documentRequest, documentRequestResponse } from "../../api/invitation";

import Alert from "../Alert";
import Button from "../Button";
import DocumentPreview from "../DocumentPreview";
import DocumentStatus from "../DocumentStatus";
import { ReactComponent as IconEye } from "../../assets/img/icons/icon_Eye.svg";
import Loader from "../Loader";
import Skeleton from "../Skeleton";
import SponsoCredits from "../SponsoCredits";
import { UserContext } from "../../contexts/UserContext";
import { createFile } from "../../api/analysis";
import { useSearchLostDocument } from "../../api/lost";
import {searchDarkDocuments} from "../../api/darkweb";

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
type VerifyResultProps = {
    isScanning: boolean;
    documentVerified?: DocumentBasylic;
    refresh?: () => void;
    verifyReportURL?: string;
    errorMessage?: string;
    isDarkWeb?: boolean;
    setApplicant_id: (uid: string) => void;
    applicant_id?: string;
};
const VerifyResult: FC<VerifyResultProps> = ({
    isScanning,
    documentVerified,
    refresh,
    verifyReportURL,
    errorMessage,
    isDarkWeb,
    applicant_id,
    setApplicant_id
}) => {
    const { user } = useContext(UserContext);
    const [status, setStatus] = useState<DocumentStatusType>();
    const type = user?.type;
    const [requestId, setRequestId] = useState<string>("");
    const [isDocumentInBDD, setIsDocumentInBDD] = useState<boolean>(false);
    const { mutate: searchDocument } = useSearchLostDocument();
    const [isDocumentInDarkWebIDProtect, setIsDocumentInDarkWebIDProtect] = useState<boolean>(false);



    const init = async () => {
        if (!documentVerified) return;

        const isDarkWeb = await searchIntoTheDarkWeb(documentVerified.numeroDocument) ?? false;
        setIsDocumentInDarkWebIDProtect(isDarkWeb);

        if (isDarkWeb) {
            setStatus({
                ...status,
                conformite: documentVerified.conformite,
                darkWeb: isDocumentInDarkWebIDProtect,
            });
        } else {
            searchDocumentInBDD();
        }
    }

    useEffect(() => {
        if (!documentVerified) return;
        init();
    }, [documentVerified]);

    useEffect(() => {
        sendRequestIDTracker();
    }, [isDocumentInBDD]);

    useEffect(() => {
        if (requestId) {
            let interval: NodeJS.Timeout;
            if (status?.idTracker === "confirm" || status?.idTracker === "reject") {
                return;
            }

            // Si status?.idTracker est "requested", commencez l'intervalle
            if (status?.idTracker === "requested" || status?.idTracker === undefined) {
                interval = setInterval(() => {
                    getResponseIDTracker();
                }, 2000);
            }

            // Nettoyez l'intervalle si le composant est démonté ou si les conditions changent
            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        }
    }, [requestId, status?.idTracker]);

    const searchDocumentInBDD = async () => {
        if (!documentVerified) return;
        const searchParams: any = {
            type,
            docType: documentVerified.typeDocument,
            idNumber: documentVerified.numeroDocument,
        };

        searchDocument(searchParams, {
            onSuccess: (response: any) => {
                setStatus({
                    conformite: documentVerified.conformite,
                    darkWeb: isDocumentInDarkWebIDProtect,
                    lost: response.status === "lost_or_sloten",
                    locked: response.status === "locked_document" || response.status === "lost_or_sloten",
                    idTracker: !isDocumentInBDD
                        ? "notRequested"
                        : response.status === "lost_or_sloten" || response.status === "locked_document"
                        ? "reject"
                        : "requested",
                });
                if (response.status !== "not_found") {
                    setIsDocumentInBDD(true);
                }
            },
            onError: (error: any) => {
                console.error("Une erreur s'est produite :", error);
            },
        });
    };

    const sendRequestIDTracker = async () => {
        if (!documentVerified) return;
        try {
            const response = await documentRequest(documentVerified.numeroDocument, type, applicant_id);
            setRequestId(response.data.id);
            setStatus({
                ...status,
                idTracker: "requested",
            });
        } catch (error: any) {
            console.log(error.response.data);
        }
    };

    const getResponseIDTracker = async () => {
        if (!requestId) return;
        try {
            const response = await documentRequestResponse(requestId, type);
            setStatus({
                ...status,
                idTracker: response.data.request.status,
            });
        } catch (error: any) {
            console.log(error.response.data);
        }
    };

    const visualiseFile = async () => {
        if (!verifyReportURL) return;
        try {
            const createFileResponse = await createFile(verifyReportURL);
            const file = createFileResponse.data;
            openPDFInNewTab(file.data, file.size, file.name);
        } catch (error: any) {
            console.log(error.response.data);
        }
    };

    const searchIntoTheDarkWeb = async (docNumber: string)=> {
        try {
            const result = await searchDarkDocuments(docNumber, type);
            if (result) {
                console.log("DOCU SUR LE DARKWEB")
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error(error);
        }
    }

    const openPDFInNewTab = (base64Data: string, size: number, name: string) => {
        // Convert base64 to ArrayBuffer
        const binaryString = atob(base64Data);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        // Create a blob from ArrayBuffer
        const blob = new Blob([bytes.buffer], { type: "application/pdf" });

        // Create an Object URL from the blob
        const url = URL.createObjectURL(blob);

        // Open the Object URL in a new tab
        const newWindow = window.open(url, "_blank");

        // Optionally, you can set the new tab's title
        if (newWindow) {
            newWindow.document.title = name;
        }

        // Release the Object URL after use
        setTimeout(() => {
            URL.revokeObjectURL(url);
        }, 1000);
    };

    return (
        <div className="VerifyResult card">
            <h3>{isScanning ? "Analyse du document" : "Document vérifié"}</h3>
            {isScanning ? (
                <>
                    {isMobile && <Loader text="Analyse en cours. L'analyse peut prendre jusqu'à 30secondes." />}
                    <Skeleton
                        width="90%"
                        height={28}
                    />
                    <Skeleton
                        width="100%"
                        height={228}
                    />
                    <Skeleton
                        width="50%"
                        height={26}
                    />
                    <Skeleton
                        width="90%"
                        height={40}
                    />
                </>
            ) : (
                <>
                    {documentVerified && (
                        <>
                            {errorMessage && <Alert type="error">{errorMessage}</Alert>}
                            <DocumentPreview document={documentVerified} />
                            {status && (
                                <DocumentStatus
                                    status={status}
                                    documentType={documentVerified.typeDocument}
                                    isDarkWeb={isDarkWeb}
                                />
                            )}
                            {type === "individual" && <SponsoCredits />}
                            {type === "partner" && (
                                <Button
                                    label="Rapport ID Expert"
                                    type={Button_Type.SECONDARY}
                                    style={Button_Style.OUTLINED}
                                    className="m-auto mb-1"
                                    icon={<IconEye fill="red" />}
                                    onClick={() => visualiseFile()}
                                    disabled={!verifyReportURL}
                                    isLoader={!verifyReportURL}
                                />
                            )}
                            <Button
                                label="Vérifier un document"
                                type={type === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                className="m-auto"
                                onClick={() => refresh && refresh()}
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default VerifyResult;
