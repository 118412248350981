import React, { FC, useContext } from "react";

import { UserContext } from "../contexts/UserContext";
import Alert from "../components/Alert";
import BuyCreditsCard from "../components/BuyCreditsCard";
import IconSmile from "../assets/img/icons/icon_smile.png";
import Button from "../components/Button";
import { Button_Style, Button_Type } from "../components/Button/Button";
import DocumentIllustration from "../components/DocumentIllustration";
import { ReactComponent as IconPlay } from "../assets/img/icons/icon_Play.svg";
import { ReactComponent as IconUpload } from "../assets/img/icons/icon_Upload.svg";

type IDTrackerOverViewProps = {
    showOverview: boolean;
    setShowOverview: React.Dispatch<React.SetStateAction<boolean>>;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedNeverShowAgain: boolean;
    setSelectedNeverShowAgain: React.Dispatch<React.SetStateAction<boolean>>;
    handleStartVerification: any;
};

const IDTrackerOverView: FC<IDTrackerOverViewProps> = ({
    showModal,
    setShowModal,
    selectedNeverShowAgain,
    setSelectedNeverShowAgain,
    handleStartVerification
}) => {
    const { user, userType } = useContext(UserContext);
    const credits = user?.credits ?? 0;

    const handleStartVerify = () => {
        setShowModal(true);
        //setSelectedNeverShowAgain(true);
    };

    return (
        <div className="VerifyOverview h-100">
            <div className="content__body h-100">
                <div className="row flex-wrap">
                    {/* Section Gauche */}
                    <div className="col-sm-4">
                        <div className="content--card relative h-100">
                            <div className="mb-2">
                                Les crédits de vérifications sont nécessaires pour ajouter du document
                                d’identité.
                                <br />
                                1 crédit = 1 document ajouté
                            </div>

                            {credits <= 0 ? (
                                <div className="VerifyResult card">
                                    <Alert type="error">
                                        Vous n'avez pas de crédits pour vérifier un document. Veuillez acheter des crédits.
                                    </Alert>
                                    <BuyCreditsCard />
                                </div>
                            ) : (
                                <div className="h-55 d-flex flex-column justify-content-between align-items-center">
                                    <Alert type="info" showDefaultIcon={false}>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <div className="text-primary">
                                                <img src={IconSmile} alt="smile" width={30}/>
                                            </div>
                                            <div className="text-center">
                                                Vous
                                                avez <b>{credits}</b> crédit{credits > 1 ? "s" : ""} disponible{credits > 1 ? "s" : ""} pour
                                                vos vérifications.
                                            </div>
                                        </div>
                                    </Alert>

                                    {/* TODO : A commenter pour faire marcher Video Prsentation */}
                                    {!showModal && (
                                        <div className="w-100 center">
                                            <p>J’effectue l'ajout du document par :</p>
                                            <div className="d-flex gap-05 flex-wrap">
                                                <Button label="Vidéo" style={Button_Style.OUTLINED}
                                                        onClick={() => handleStartVerification(true)} icon={<IconPlay/>}
                                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}/>
                                                <Button label="Photo" style={Button_Style.OUTLINED}
                                                        onClick={() => handleStartVerification(false)}
                                                        icon={<IconUpload/>}
                                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}/>
                                            </div>
                                        </div>
                                    )}

                                    {/* TODO : A décomenter pour faire marcher Video Prsentation */}
                                    {/*<Checkbox
                                        label="Ne plus afficher cette page"
                                        onChange={() => setSelectedNeverShowAgain(!selectedNeverShowAgain)}
                                        checked={selectedNeverShowAgain}
                                    />

                                    <Button
                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                        label="Vérifier un document"
                                        style={Button_Style.SOLID}
                                        disabled={credits <= 0}
                                        onClick={handleStartVerify}
                                        icon={<IconBack className="PageHeader__icon" fill={"#9FC1EF"} />}
                                    />*/}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* TODO : A décomenter pour faire marcher Video Prsentation */}
                    <div className="col-sm-8">
                        <DocumentIllustration/>
                        {/*<VideoFrame url="https://www.youtube.com/watch?v=bYk4QPlCi5g">
                            <span className="text-primary bold">ID VERIFY :</span>
                            <span className="text-danger"> Découvrez le fonctionnement de notre outil</span>
                        </VideoFrame>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IDTrackerOverView;
