import "./style.scss";

import Button, { Button_Style, Button_Type } from "../Button/Button";
import { DocumentBasylic, ErrorType } from "../../types/types";
import React, { FC, useContext, useEffect, useState } from "react";
import {
    generateReportWithShareIdPhoto, generateReportWithShareIDVideo,
    getDocumentHighestAnalysisEver,
    getVerifationToken,
} from "../../api/analysis";

import Alert from "../Alert";
import DocumentTypeSelect from "../DocumentTypeSelect";
import DocumentUpload from "../DocumentUpload/DocumentUpload";
import FileDownloadInstructions from "../FileDownloadInstructions";
import ShareIDVerification from "../ShareIDVerification";
import { UserContext } from "../../contexts/UserContext";
import BuyCreditsCard from "../BuyCreditsCard";
import {formatShareIDPhotoResultToDocument} from "../../utils/utils";
import {decrementCredits} from "../../api/credits";

type AddDocumentFormProps = {
    onChangeType: (type: string) => void;
    type: string;
    setIsScanning: (isScanning: boolean) => void;
    allowForeignDocuments?: boolean;
    isScanning?: boolean;
    setErrorMessage?: (errorMessage: string) => void;
    setIsScanned?: (isScanned: boolean) => void;
    setDocument?: (document: DocumentBasylic) => void;
    isVerification?: boolean;
    setErrorType?: (errorType: ErrorType) => void;
    errorType?: ErrorType;
    setVerifyReportURL?: (url: string) => void;
    isDarkWeb?: boolean;
    isByVideo?: boolean;
    setApplicant_id: (uid: string) => void;
    applicant_id?: string;
};

const AddDocumentForm: FC<AddDocumentFormProps> = ({
    onChangeType,
    setIsScanning,
    type,
    allowForeignDocuments,
    setErrorMessage,
    setIsScanned,
    setDocument,
    isScanning,
    isVerification,
    setErrorType,
    errorType,
    setVerifyReportURL,
    isDarkWeb,
    isByVideo = false,
    applicant_id,
    setApplicant_id
}) => {
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [requiredFiles, setRequiredFiles] = useState<number>(1);
    const [isFrench] = useState<boolean>(true);
    const { user } = useContext(UserContext);
    const [isShareID, setIsShareID] = useState<boolean>(isByVideo);
    const [accessToken, setAccessToken] = useState<any>("");
    const userType = user?.type;
    const credits = user?.credits ?? 0;

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (!userType) return;
        const getToken = async () => { // SHARE ID TOKEN
            try {
                const token = await getVerifationToken(userType);
                setAccessToken(token.data.token);
                setApplicant_id(token.data.applicant_id ?? "");
            } catch (error: any) {
                console.error(error);
            }
        };
        getToken();
    }, [userType]);

    useEffect(() => {
        const sdkFlex: any = document.querySelector(".videoVerification .sdk-flex");
        if (!sdkFlex) return;
        sdkFlex.style.display = "none";
    }, [accessToken]);

    const handleChange = (value: string) => {
        onChangeType(value);
        setSelectedFiles([]);
        if (value === "Passeport") {
            setRequiredFiles(1);
        } else {
            setRequiredFiles(1);
        }
    };

    const generateURLWithShareIdPhoto = async (contenuReponseShareIDPhoto: any) => {
        try {
            const responseFile = await generateReportWithShareIdPhoto(userType, selectedFiles, contenuReponseShareIDPhoto!, applicant_id);
            setVerifyReportURL && setVerifyReportURL(responseFile.pdfPath);
        } catch (error: any) {
            console.error("Une erreur s'est produite :", error);
            setErrorMessage && setErrorMessage(error.message);
        }
    };

    const generateURLWithShareIdVideo = async (contenuReponseShareIDVideo: any) => {
        try {
            const responseFile = await generateReportWithShareIDVideo(userType, contenuReponseShareIDVideo!, applicant_id);
            setVerifyReportURL && setVerifyReportURL(responseFile.pdfPath);
        } catch (error: any) {
            console.error("Une erreur s'est produite :", error);
            setErrorMessage && setErrorMessage(error.message);
        }
    };

    const handleVerification = async () => {
        setIsScanning(true);
        let response; // le retour du body de shareID
        try {
            response = await getDocumentHighestAnalysisEver(userType, selectedFiles, accessToken);
            if (user?.type === "partner") {
                if (response) {
                    generateURLWithShareIdPhoto(response);
                }
            }

            if (response.ocr != null) {
                await decrementCredits(userType, 1); //On enlève 1 à son crédit
                setIsScanned && setIsScanned(true);
                setDocument && setDocument(formatShareIDPhotoResultToDocument(response));
            }
            else {
                const errorType = {
                    status: 3000,
                    message: "Le document est flou, obstrué ou mal éclairé",
                };

                setErrorType && setErrorType(errorType);
            }


        } catch (error: any) {
            handleErrorVerification(error);
            if (user?.type === "partner") {
                if (response) {
                    generateURLWithShareIdPhoto(response);
                }
            }
        } finally {
            setIsScanning(false);
        }
    };

    const handleDarkWebVerification = async () => {
        setIsScanning(true);
        let response;
        try {
            response = await getDocumentHighestAnalysisEver(userType, selectedFiles);
            if (user?.type === "partner") {
                if (response) {
                    generateURLWithShareIdPhoto(response);
                }
            }
            if (response.ocr != null) {
                await decrementCredits(userType, 1); //On enlève 1 à son crédit
                setIsScanned && setIsScanned(true);
                setDocument && setDocument(formatShareIDPhotoResultToDocument(response));
            }
            else {
                const errorType = {
                    status: 3000,
                    message: "Le document est flou, obstrué ou mal éclairé",
                };

                setErrorType && setErrorType(errorType);
            }
        } catch (error: any) {
            handleErrorVerification(error);
        } finally {
            setIsScanning(false);
        }
    };

    const handleErrorVerification = (error: any) => {
        const errorStatus = error.response?.status;
        const responseError = error.response?.data?.error;
        const error400Message =
            responseError === "Document manquantes, Veuillez présenter un autre document d'identité."
                ? "Document non reconnu."
                : "Document non conforme.";

        const errors: ErrorType[] = [
            {
                status: 500,
                message: "Erreur de serveur interne",
            },
            {
                status: 504,
                message: "L'analyse de votre document prend plus de temps que prévu. Veuillez réessayer ultérieurement.",
            },
            {
                status: 503,
                message: responseError,
            },
            {
                status: 400,
                message: error400Message,
            },
            {
                status: 401,
                message: "Une erreur est survenue. Veuillez réessayer.",
            },
        ];
        // Si l'error.status n'est pas dans la liste des erreurs, on affiche un message par défaut
        const errorType = errors.find((error) => error.status === errorStatus) || {
            status: 3000,
            message: "Une erreur est survenue. Veuillez réessayer.",
        };

        //  const errorType = errors.filter((error: any) => error.status === errorStatus)[0];

        setErrorType && setErrorType(errorType);
    };

    return (
        <div className="addDocumentForm card">
            {credits <= 0 ? (
                    <>
                        <Alert type="error">Vous n'avez pas de crédits ajouter ou vérifier. Veuillez acheter des crédits.</Alert>
                        <BuyCreditsCard />
                    </>
                ) : (
                        <>
                            {isShareID && !isDarkWeb ? (
                                <ShareIDVerification
                                    applicantId={applicant_id}
                                    accessToken={accessToken}
                                    setIsShareID={setIsShareID}
                                    setIsScanning={setIsScanning}
                                    setIsScanned={setIsScanned}
                                    setErrorType={setErrorType}
                                    setDocument={setDocument}
                                    errorType={errorType}
                                    generateURLWithShareIdVideo={generateURLWithShareIdVideo}
                                />
                            ) : (
                                <>
                                    <div>
                                        {errorType?.status !== 0 && <Alert type="error">{errorType?.message}</Alert>}
                                        <DocumentTypeSelect
                                            handleChange={handleChange}
                                            isVerification={isVerification}
                                            type={type}
                                        />
                                        <div className="addDocumentForm__buttons">
                                            <FileDownloadInstructions />
                                            {type && (
                                                <DocumentUpload
                                                    type={type}
                                                    setSelectedFiles={setSelectedFiles}
                                                    selectedFiles={selectedFiles}
                                                />
                                            )}
                                        </div>
                                        {!isFrench && !allowForeignDocuments && (
                                            <Alert type="error">
                                                Attention, les documents non français ne sont pas encore pris en charge. Veuillez nous contacter pour plus
                                                d'informations.
                                            </Alert>
                                        )}
                                    </div>
                                    <Button
                                        type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                        label={isVerification ? "Vérifier" : "Ajouter"}
                                        style={Button_Style.OUTLINED}
                                        disabled={selectedFiles.length < requiredFiles || (!isFrench && !allowForeignDocuments)}
                                        onClick={() => (isDarkWeb ? handleDarkWebVerification() : handleVerification())}
                                    />
                                </>
                            )}

                        </>
                )
            }
        </div>
    );
};

export default AddDocumentForm;
