import React, {FC, useContext, useState} from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../contexts/UserContext";
import Alert from "../components/Alert";
import BuyCreditsCard from "../components/BuyCreditsCard";
import IconSmile from "../assets/img/icons/icon_smile.png";
import Button from "../components/Button";
import {Button_Style, Button_Type} from "../components/Button/Button";
import { ReactComponent as IconBack } from "../assets/img/icons/icon_arrow-Right.svg";
import VideoFrame from "../components/VideoFrame";
import Checkbox from "../components/Checkbox";


type VerifyOverViewProps = {
    showOverview: boolean;
    setShowOverview: any;
    setShowModal: any;
};

const VerifyOverviewDarkWeb: FC<VerifyOverViewProps> = ({
    showOverview = true,
    setShowOverview,
    setShowModal
}) => {
    const navigate = useNavigate();

    const { user, userType } = useContext(UserContext);
    const credits = user?.credits ?? 0;

    const [selectedNeverShowAgain, setSelectedNeverShowAgain] = useState(false);


    const handleStartDarkWeb = () => {
        setShowOverview(false);
        localStorage.setItem("VerifyOverviewDarkWeb", JSON.stringify(!selectedNeverShowAgain));
    };

    const checkSelect = () => {
        const newSelect = !selectedNeverShowAgain;
        setSelectedNeverShowAgain(newSelect);
    };


    return (
        <>
            <div className="VerifyOverview h-100">
                <div className="content__body h-100">
                    <div className="row flex-wrap">
                        <div className={`col-sm-4`}>
                            <div className="content--card relative h-100">
                                <div className="mb-2">
                                    Les crédits de vérifications sont nécessaires pour la vérification du document
                                    d’identité.
                                    <br/>
                                    1 crédit = 1 vérification
                                </div>
                                {credits === 0 ? (
                                    <div className="VerifyResult card">
                                        <Alert type="error">Vous n'avez pas de crédits pour vérifier un document.
                                            Veuillez acheter des crédits.</Alert>
                                        <BuyCreditsCard/>
                                    </div>
                                ) : (
                                    <div className="h-70 d-flex flex-column justify-content-between align-items-center">
                                        <Alert type="info" showDefaultIcon={false}>
                                            <div
                                                className="d-flex flex-column justify-content-center align-items-center">
                                                <div className="text-primary"><img src={IconSmile} alt="smile"
                                                                                   width={30}/></div>
                                                <div className="text-center">
                                                    Vous avez <b>{credits}</b> credit-s disponibles pour vos
                                                    vérifications sur le dark Web
                                                </div>
                                                {/*<Button
                                                label="Acheter des crédits"
                                                size="M"
                                                style={Button_Style.OUTLINED}
                                                onClick={() => handlePayCredit()}
                                            />*/}
                                            </div>
                                        </Alert>
                                        <Checkbox
                                            label="Ne plus afficher cette page"
                                            onChange={checkSelect}
                                            checked={selectedNeverShowAgain}
                                        />
                                        <Button
                                            type={userType === "partner" ? Button_Type.SECONDARY : Button_Type.PRIMARY}
                                            label="Dark Web"
                                            style={Button_Style.SOLID}
                                            onClick={() => handleStartDarkWeb()}
                                            icon={<IconBack
                                                className="PageHeader__icon"
                                                fill={"#9FC1EF"}
                                            />}
                                        />
                                    </div>
                                )
                                }

                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="mt-3">
                                <VideoFrame
                                    url="https://www.youtube.com/watch?v=bYk4QPlCi5g"
                                    // prevImg={ImagePrev}
                                >
                                    <span className="text-primary bold">Dark Web :</span>
                                    <span className="text-danger"> Découvrez le fonctionnement notre Dark Web</span>
                                </VideoFrame>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};
export default VerifyOverviewDarkWeb;