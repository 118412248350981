import "../assets/scss/style.scss";

import { FC, useEffect } from "react";

import LoginForm from "../components/LoginForm";
import svgImage from "../assets/img/Illustration_documents.svg";
import { useLocation } from "react-router-dom";

const Login: FC = () => {
    const location = useLocation();
    useEffect(() => {
        // Check if the URL contains "professionnels"
        const hasProfessionnels = location.pathname.includes("professionnels");

        // Apply a class to the body element
        if (hasProfessionnels) {
            document.body.classList.add("professionnels");
        } else {
            document.body.classList.remove("professionnels");
        }
    }, [location]);
    return (
        <main className="login">
            <div className="login__container">
                <div className="login__form">
                    <LoginForm/>
                </div>
                <div className="login__img">
                    <img
                        src={svgImage}
                        className="illustration-document"
                        alt="Login"
                    />
                </div>
            </div>
        </main>
    );
};

export default Login;
